import { Box, CircularProgress, Container, Icon, IconButton, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { model } from "../../firebase/firebase-utils";
import { AuthContext } from "../../context/AuthContext";
import FormatMarkDown from "../../components/FormatMarkDown";

export default function GeminiChatPage() {
    const [message, setMessage] = useState("");
    const [prompt, setPrompt] = useState("");
    const [loading, setLoading] = useState(false);
    const [history, setHistory] = useState([]);

    const { user } = useContext(AuthContext);

    useEffect(() => {}, []);

    const handleGenerate = async () => {
        setLoading(true);
        const chat = model.startChat({
            history,
        });

        setPrompt("");

        const userChat = { role: "user", parts: [{ text: prompt }] };
        setHistory((prev) => [...prev, userChat]);

        const result = await chat.sendMessageStream(prompt);

        let text = "";
        for await (const chunk of result.stream) {
            const chunkText = chunk.text();
            text += chunkText;
            setMessage((prev) => prev + chunkText);
        }

        const modelChat = { role: "model", parts: [{ text: text }] };
        setHistory((prev) => [...prev, modelChat]);

        setMessage("");
        setLoading(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleGenerate();
        }
    };

    const displayHistory = useMemo(() => {
        const reversed = [...history].reverse();
        return reversed;
    }, [history]);

    return (
        <Container maxWidth="md">
            <Box display="flex" flexDirection={"column"} gap={2} m={1} p={3} height={`calc(100vh - 40px)`}>
                <Box display="flex" flex={1} flexDirection={"column-reverse"} sx={{ overflowY: "auto" }}>
                    {message && (
                        <Box>
                            <Typography variant="subtitle2">AI</Typography>
                            <FormatMarkDown text={message} />
                        </Box>
                    )}
                    {displayHistory.map((chat, index) => (
                        <Box
                            key={index}
                            display="flex"
                            alignItems={"center"}
                            justifyContent={chat.role === "user" ? "flex-end" : "flex-start"}
                            sx={{ border: "1px solid grey", borderRadius: "10px" }}
                            my={1}
                            mr={2}
                            p={2}
                        >
                            <Box
                                display="flex"
                                flexDirection={"column"}
                                alignItems={chat.role === "user" ? "flex-end" : "flex-start"}
                            >
                                <Typography variant="subtitle2">
                                    {chat.role === "user" ? user.displayName : "AI"}
                                </Typography>
                                <FormatMarkDown text={chat.parts[0]?.text || ""} />
                            </Box>
                        </Box>
                    ))}
                </Box>

                <Box>
                    <TextField
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        fullWidth
                        multiline
                        onKeyDown={handleKeyDown}
                        rows={5}
                        InputProps={{
                            endAdornment: (
                                <Box height={"50px"}>
                                    <IconButton onClick={handleGenerate} disabled={loading} size="large">
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <Icon variant="contained" fontSize="large">
                                                send
                                            </Icon>
                                        )}
                                    </IconButton>
                                </Box>
                            ),
                        }}
                    />
                </Box>
            </Box>
        </Container>
    );
}
