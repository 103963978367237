import { useEffect, useState } from "react";
import { Paper, Typography } from "@mui/material";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import convertToTreeTableFormat from "../../functions/convertTreeTableFormat";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import SearchIcon from "@mui/icons-material/Search";
import formatNumber from "./../../utils-functions/formatNumber";
import { blue, green, red } from "@mui/material/colors";

const AffiliateTable = ({ affiliates, setLoading }) => {
    const [affiliateData, setAffiliateData] = useState();
    const [globalFilter, setGlobalFilter] = useState("");

    useEffect(() => {
        setLoading(true);
        const result = convertToTreeTableFormat(affiliates);
        setAffiliateData(result);
        setLoading(false);
    }, [affiliates]);

    const formatDate = (rowData) => {
        if (rowData.data && rowData.data.date) {
            const dateObject = new Date(rowData.data.date);
            return dateObject.toDateString();
        } else {
            return "";
        }
    };

    const formatEndDate = (rowData) => {
        if (rowData.data && rowData.data.trialEnd) {
            const dateObject = new Date(rowData.data.trialEnd);
            return dateObject.toDateString();
        } else {
            return "";
        }
    };

    const formatNum = (rowData) => {
        if (rowData.data && rowData.data.price) {
            return formatNumber(rowData.data.price);
        } else {
            return "";
        }
    };

    const formatStatus = (rowData) => {
        const checkColor = (status) => {
            switch (status) {
                case "active":
                    return green[500];
                case "canceled":
                    return red[500];
                case "trialing":
                    return blue[500];
                default:
                    return "black";
            }
        };
        if (rowData.data && rowData.data.subscriptionStatus) {
            return (
                <Typography sx={{ color: checkColor(rowData.data.subscriptionStatus) }}>
                    {rowData.data.subscriptionStatus}
                </Typography>
            );
        } else {
            return "";
        }
    };

    const getHeader = () => {
        return (
            <div className="flex justify-content-end">
                <div className="p-input-icon-left">
                    <SearchIcon />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
                </div>
            </div>
        );
    };

    let header = getHeader();

    const columns = [
        { field: "displayName", header: "Name", expander: true },
        { field: "email", header: "Email" },
        { field: "phone", header: "Phone" },
        { field: "date", header: "Join Date", body: formatDate },
        { field: "subscriptionStatus", header: "Status", body: formatStatus },
        { field: "trialEnd", header: "Trial End", body: formatEndDate },
        { field: "price", header: "Price", body: formatNum },
        { field: "currency", header: "Currency" },
        { field: "paymentInterval", header: "Interval" }
    ];

    const fetchChildren = async (id) => {
        console.log("fetchChildren id: ", id);
        setLoading(true);
        const affiliateRef = collection(db, "users");
        const q = query(affiliateRef, where("referral", "==", id), orderBy("date", "desc"));

        try {
            const affiliateSnapshot = await getDocs(q);

            let affiliates = [];

            affiliateSnapshot.docs.forEach((aff) => {
                affiliates.push({
                    ...aff.data(),
                    date: new Date(aff.data().date.seconds * 1000)
                });
            });
            console.log("fetchChildren affiliates= ", affiliates);
            setLoading(false);

            return affiliates;
        } catch (err) {
            console.log(err.message);
            setLoading(false);
            return;
        }
    };

    const onNodeExpand = async (e) => {
        const nodeId = e.node.key; // Assuming that 'key' is the unique identifier for each node

        // Fetch the new children based on nodeId.
        const newAffiliates = await fetchChildren(nodeId);

        // Convert the newly fetched affiliates to the appropriate format.
        const formattedNewAffiliates = convertToTreeTableFormat(newAffiliates);

        // Find and update the node within affiliateData
        const updateNodeInChildren = (children) => {
            for (let i = 0; i < children.length; i++) {
                if (children[i].key === nodeId) {
                    children[i].children = formattedNewAffiliates;
                    return true;
                }
                if (children[i].children) {
                    if (updateNodeInChildren(children[i].children)) {
                        return true;
                    }
                }
            }
            return false;
        };

        if (updateNodeInChildren(affiliateData)) {
            setAffiliateData([...affiliateData]);
        }
    };

    return (
        <Paper>
            {affiliateData && (
                <TreeTable
                    value={affiliateData}
                    onExpand={onNodeExpand}
                    paginator
                    rows={100}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    sortMode="multiple"
                    scrollable
                    scrollHeight="40vh"
                    header={header}
                    globalFilter={globalFilter}
                    size={"small"}
                >
                    {columns.map((col, i) => (
                        <Column
                            key={i}
                            field={col.field}
                            body={col.body}
                            expander={col.expander}
                            sortable
                            globalFilter={globalFilter}
                            filterMode={"lenient"}
                            header={col.header}
                            style={{ width: "250px" }}
                        />
                    ))}
                </TreeTable>
            )}
        </Paper>
    );
};

export default AffiliateTable;
