import { addDoc, collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import { notification } from "../../utils-functions/notification";

export default async function shareFlowDesign(user, flow, friend) {
    try {
        const collectionRef = collection(db, "users", friend.id, "whatsappFlows");
        const newFlow = {
            ...flow,
            clientId: removeSpecialChars(friend.id),
            keywords: [],
            date: new Date(),
            sharedBy: user.id,
            status: "shared",
            userId: friend.id,
        };

        delete newFlow.id;
        const response = await addDoc(collectionRef, newFlow);
        console.log("Flow shared successfully ", response.id);

        // Sharing Follow up Sequences of the flow
        // Load the user blocks
        const blocksRef = collection(db, "users", user.id, "whatsappFlows", flow.id, "blocks");
        const blocksSnapshot = await getDocs(blocksRef);
        let blocks = mapSnapshot(blocksSnapshot);
        if (!arrayIsEmpty(blocks)) {
            blocks = blocks.map((block) => {
                return { ...block, flowId: response.id, createdBy: friend.id };
            });

            // Get the friend's blocks collection
            const blocksCollectionRef = collection(
                db,
                "users",
                friend.id,
                "whatsappFlows",
                response.id,
                "blocks"
            );
            await Promise.all(
                blocks.map((block) => {
                    const docRef = doc(blocksCollectionRef, block.id);
                    setDoc(docRef, block, { merge: true });
                })
            );
            console.log("Blocks shared successfully");
        }

        // Get user's blocksRelation
        const blocksRelationRef = collection(
            db,
            "users",
            user.id,
            "whatsappFlows",
            flow.id,
            "blocksRelation"
        );
        const blocksRelationSnapshot = await getDocs(blocksRelationRef);
        let blocksRelation = mapSnapshot(blocksRelationSnapshot);
        if (!arrayIsEmpty(blocksRelation)) {
            blocksRelation = blocksRelation.map((relation) => {
                return { ...relation, flowId: response.id };
            });

            // Get the friend's blocksRelation collection
            const blocksRelationCollectionRef = collection(
                db,
                "users",
                friend.id,
                "whatsappFlows",
                response.id,
                "blocksRelation"
            );
            await Promise.all(
                blocksRelation.map((relation) => {
                    const docRef = doc(blocksRelationCollectionRef, relation.id);
                    setDoc(docRef, relation, { merge: true });
                })
            );
            console.log("BlocksRelation shared successfully");
        }

        // share all catalogs
        const catalogsRef = collection(db, "catalogs");
        const recordsRef = collection(db, "records");

        const qCatalogs = query(catalogsRef, where("flowId", "==", flow.id));
        const catalogsSnapshot = await getDocs(qCatalogs);
        const catalogs = mapSnapshot(catalogsSnapshot);
        await Promise.all(
            catalogs.map(async (catalog) => {
                const catalogResponse = await addDoc(catalogsRef, {
                    ...catalog,
                    flowId: response.id,
                    admins: [friend.id],
                });

                const q = query(recordsRef, where("catalogId", "==", catalog.id));
                const recordsSnapshot = await getDocs(q);
                let records = mapSnapshot(recordsSnapshot);
                // share all indexed records
                await Promise.all(
                    records.map(async (record) => {
                        // remove status and embedding from record
                        const { status, embedding, ...rest } = record;
                        const recordResponse = await addDoc(recordsRef, {
                            ...rest,
                            flowId: response.id,
                            admins: [friend.id],
                            catalogId: catalogResponse.id,
                        });
                        console.log("Record shared successfully ", recordResponse.id);
                    })
                );
            })
        );

        notification("Success", "Flow shared successfully", "success");
    } catch (error) {
        console.log(error);
    }
}
