import axios from "axios";
import { notification } from "../../utils-functions/notification";

export async function getLeadForms(pageId, accessToken) {
    try {
        const url = `https://graph.facebook.com/v19.0/${pageId}/leadgen_forms?access_token=${accessToken}`;
        const response = await axios(url);
        console.log(response.data.data);
        return response.data.data;
    } catch (err) {
        console.log(err);
        notification("Opps", err.message, "danger");
    }
}
