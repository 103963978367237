import { doc, getDoc, getDocs, where } from "@firebase/firestore";
import { Box, Container, Grid, List, ListItem, ListItemButton, ListItemText, Paper } from "@mui/material";
import { collectionGroup, onSnapshot, query } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { ChipC, GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import FormSubmissionsPage from "./FormSubmissionsPage";

export default function AffiliateSubmissionsPage() {
    const [submissions, setSubmissions] = useState([]);
    const [forms, setForms] = useState([]);
    const [currentFormId, setCurrentFormId] = useState();
    const [currentForm, setCurrentForm] = useState();

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const getData = async () => {
            const collectionRef = collectionGroup(db, "submissions");
            const q = query(collectionRef, where("referralId", "==", user.id));
            const snapshot = await getDocs(q);
            const submissions = mapSnapshot(snapshot);
            setSubmissions(submissions);
            console.log(submissions);

            const getFormIds = async () => {
                const formIds = [];
                submissions.forEach((submission) => {
                    if (!formIds.includes(submission.formId)) {
                        formIds.push(submission.formId);
                    }
                });
                return formIds;
            };

            let formIds = await getFormIds();
            formIds = formIds.filter((id) => id !== undefined && id !== null);
            console.log(formIds);

            const forms = await Promise.all(
                formIds.map(async (formId) => {
                    const docRef = doc(db, "forms", formId);
                    const docSnap = await getDoc(docRef);
                    const form = mapDocSnapshot(docSnap);
                    const affiliateSubmissions = submissions.filter((submission) => submission.formId === formId);
                    const affiliateSubmissionsCount = affiliateSubmissions.length;
                    form.affiliateSubmissionsCount = affiliateSubmissionsCount;
                    return form;
                })
            );
            console.log(forms);
            setForms(forms);
        };

        getData();
    }, [user]);

    useEffect(() => {
        if (!currentFormId) return;
        const docRef = doc(db, "forms", currentFormId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            const form = { ...doc.data(), id: doc.id };
            setCurrentForm(form);
        });
        return unsubscribe;
    }, [currentFormId]);

    const handleClickForm = (form) => {
        setCurrentFormId(form.id);
    };

    return (
        <Container disableGutters maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Form Submissions</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
            <Grid container display="flex" spacing={1}>
                {/* Column 1 */}
                <Grid item xs={12} md={12}>
                    <Grid container display="flex" spacing={1}>
                        <GridFlexBox xs={12} md={6}>
                            <Paper sx={{ width: "100%", height: "20vh", overflowY: "auto" }} variant="outlined">
                                <List sx={{ width: "100%", padding: "0" }}>
                                    {forms?.map((form) => (
                                        <React.Fragment key={form.id}>
                                            <ListItem
                                                disablePadding
                                                secondaryAction={
                                                    <Box display={"flex"}>
                                                        <ChipC
                                                            color={form.affiliateSubmissionsCount ? "blue" : "grey"}
                                                            onClick={() => {
                                                                setCurrentFormId(form.id);
                                                            }}
                                                        >
                                                            {form.affiliateSubmissionsCount || 0}
                                                        </ChipC>
                                                    </Box>
                                                }
                                            >
                                                <ListItemButton
                                                    dense
                                                    onClick={() => handleClickForm(form)}
                                                    selected={currentFormId === form.id}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <Name bold={currentFormId === form.id}>{form.name}</Name>
                                                        }
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Paper>
                        </GridFlexBox>
                    </Grid>
                </Grid>
                {/* Column 2 */}
                <Grid item xs={12} md={12}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ width: "100%", overflowY: "auto" }} variant="outlined">
                                <FormSubmissionsPage form={currentForm} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
