import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import Calendar from "@fullcalendar/react"; // => request placed at the top
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import { useCallback, useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import { useTheme } from "@emotion/react";
import Typography from "@mui/material/Typography";
import { updateEvent, useGetEvents } from "../../api/calendar";
import { isAfter, isBetween } from "../../utils/format-time";
import { useBoolean } from "../hooks/use-boolean";
import { useResponsive } from "../hooks/use-responsive";

import { Box, Tooltip } from "@mui/material";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { AuthContext } from "../../../../context/AuthContext";
import { db } from "../../../../firebase/firebase-utils";
import convertDate from "../../../../functions/common-functions/convertDate";
import CalendarFiltersResult from "../calendar-filters-result";
import CalendarForm from "../calendar-form";
import CalendarToolbar from "../calendar-toolbar";
import CalendarUnavailable from "../calendar-unavailable";
import { useCalendar, useEvent } from "../hooks";
import { StyledCalendar } from "../styles";
import { CALENDAR_COLOR_OPTIONS } from "../../_mock/_calendar";
import dayjs from "dayjs";
import formatTime from "../../../../functions/common-functions/formatTime";

// ----------------------------------------------------------------------

const defaultFilters = {
    colors: [],
    startDate: null,
    endDate: null,
};

// ----------------------------------------------------------------------

export default function CalendarView() {
    const theme = useTheme();

    const smUp = useResponsive("up", "sm");

    const openFilters = useBoolean();

    const [filters, setFilters] = useState(defaultFilters);

    const { events, eventsLoading } = useGetEvents();

    const dateError = isAfter(filters.startDate, filters.endDate);

    const {
        calendarRef,
        //
        view,
        date,
        //
        onDatePrev,
        onDateNext,
        onDateToday,
        onDropEvent,
        onChangeView,
        onSelectRange,
        onClickEvent,
        onResizeEvent,
        onInitialView,
        //
        openForm,
        onOpenForm,
        onCloseForm,
        //
        selectEventId,
        selectedRange,
        //
        onClickEventInFilters,
    } = useCalendar();

    const [openUnDialog, setOpenUnDialog] = useState(false);
    const { user } = useContext(AuthContext);
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);

    const currentEvent = useEvent(events, selectEventId, selectedRange, openForm);

    useEffect(() => {
        onInitialView();
    }, [onInitialView]);

    useEffect(() => {
        setLoading(true);
        let unsubscribeAppointments = () => {};

        const docRef = doc(db, "calendarOffDays", user.email);
        let startTime;
        let endTime;
        const unsubscribeCalendarOffDays = onSnapshot(docRef, (docSnap) => {
            let offDaysEvents = [];

            if (docSnap.exists()) {
                const data = docSnap.data();
                offDaysEvents = processCalendarOffDays(data);
            } else {
                console.log("No such document!");
            }

            const q = query(collection(db, "appointments"), where("clientId", "==", user.clientId));

            unsubscribeAppointments = onSnapshot(q, (querySnapshot) => {
                let appointmentsEvents = querySnapshot.docs.map((doc) => {
                    const data = doc.data();

                    startTime = dayjs(data.startDate.toDate()).format("h:mm A");
                    endTime = dayjs(data.startDate.toDate()).format("h:mm A");

                    return {
                        id: doc.id,
                        title: data.desc || "No Description",
                        start: convertDate(data.startDate),
                        end: convertDate(data.endDate),
                        extendedProps: {
                            custName: data.custName,
                            custContact: data.custContact,
                            createdBy: data.createdBy,
                            startTime,
                            endTime,
                            // Include any other relevant fields here
                        },
                        color: "green",
                    };
                });

                // Update state with both off days and appointments
                setCalendarEvents((prevEvents) => {
                    const newEvents = [...offDaysEvents, ...appointmentsEvents];

                    // Only update if there's a change to avoid unnecessary re-renders
                    if (JSON.stringify(prevEvents) !== JSON.stringify(newEvents)) {
                        return newEvents;
                    }
                    return prevEvents;
                });

                setLoading(false);
            });
        });

        // Return a cleanup function that unsubscribes from the outer listener
        return () => {
            unsubscribeCalendarOffDays();
            unsubscribeAppointments();
        };
    }, [user.email, user.clientId]);

    const handleNewEvent = (newEventId) => {
        onOpenForm(); // Open the form
        onClickEvent({ event: { id: newEventId } }); // Simulate clicking the new event
    };

    const processCalendarOffDays = (data) => {
        const events = [];

        // Process off days as recurring weekly events
        data.offDays.forEach((day) => {
            events.push({
                title: "Off Day",
                daysOfWeek: [dayToNumber(day)], // convert day string to day number
                color: "gray",
                display: "background",
            });
        });

        // Process unavailable time ranges
        //    if (view !== "dayGridMonth" && data.avaiTimeFrom && data.avaiTimeTill) {
        //        events.push({
        //            title: "Available Time",
        //            startTime: data.avaiTimeFrom, // should be '09:00'
        //            endTime: data.avaiTimeTill, // should be '19:00'

        //            daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // Every day of the week
        //        });
        //    }

        // Process off dates
        data.offDates.forEach((date) => {
            events.push({
                title: "Off Date",
                start: date,
                allDay: true,
                color: "gray",
                display: "background",
            });
        });

        return events;
    };

    const dayToNumber = (day) => {
        const map = {
            Sunday: 0,
            Monday: 1,
            Tuesday: 2,
            Wednesday: 3,
            Thursday: 4,
            Friday: 5,
            Saturday: 6,
        };
        return map[day];
    };

    const handleFilters = useCallback((name, value) => {
        setFilters((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    const handleResetFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const canReset = !!filters.colors.length || (!!filters.startDate && !!filters.endDate);

    const dataFiltered = applyFilter({
        inputData: events,
        filters,
        dateError,
    });

    const renderResults = (
        <CalendarFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            canReset={canReset}
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            sx={{ mb: { xs: 3, md: 5 } }}
        />
    );

    const renderEventContent = (eventInfo) => {
        const { event } = eventInfo;
        console.log(event);
        console.log(event.start);
        console.log(event.end);
        const hasCustomerInfo = event.extendedProps.custName && event.extendedProps.custContact;

        return (
            <>
                <strong>{event.timeText}</strong>
                <div>{event.title}</div>
                {hasCustomerInfo && (
                    <>
                        <div>Customer: {event.extendedProps.custName}</div>
                        <div>Contact: {event.extendedProps.custContact}</div>
                        <div>
                            {formatTime(event.start)} - {formatTime(event.end)}
                        </div>
                    </>
                )}
            </>
        );
    };

    const handleUnavailable = () => {
        setOpenUnDialog(true);
    };

    const closeUnDialog = () => {
        setOpenUnDialog(false);
    };

    return (
        <>
            <Container maxWidth={"xl"} disableGutters={smUp ? false : true}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between" // Ensures items align to the start
                    px={smUp ? 0 : 1} // Add padding to the left and right
                    sx={{
                        mb: { xs: 3, md: 5 },
                        width: "100%", // Ensures the Stack takes full width
                    }}
                >
                    <Typography variant="h4">Calendar</Typography>
                    <Box display="flex" alignItems={"center"} gap={1}>
                        <Tooltip title="Click to add appointment.">
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={onOpenForm}
                                size="small"
                            >
                                New
                            </Button>
                        </Tooltip>
                        <Tooltip title="Click to set specific days and times when no appointments can be booked.">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleUnavailable}
                                size="small"
                            >
                                Settings
                            </Button>
                        </Tooltip>
                    </Box>
                </Stack>

                {canReset && renderResults}

                <Card>
                    <StyledCalendar>
                        <CalendarToolbar
                            date={date}
                            view={view}
                            loading={loading}
                            onNextDate={onDateNext}
                            onPrevDate={onDatePrev}
                            onToday={onDateToday}
                            onChangeView={onChangeView}
                            onOpenFilters={openFilters.onTrue}
                        />
                        <Calendar
                            weekends
                            editable
                            droppable
                            selectable
                            rerenderDelay={10}
                            allDayMaintainDuration
                            eventResizableFromStart
                            ref={calendarRef}
                            initialDate={date}
                            initialView={view}
                            dayMaxEventRows={3}
                            eventDisplay="block"
                            events={[...dataFiltered, ...calendarEvents]}
                            eventContent={renderEventContent}
                            headerToolbar={false}
                            select={onSelectRange}
                            eventClick={onClickEvent}
                            height={smUp ? 720 : "auto"}
                            eventDrop={(arg) => {
                                onDropEvent(arg, updateEvent);
                            }}
                            eventResize={(arg) => {
                                onResizeEvent(arg, updateEvent);
                            }}
                            plugins={[
                                listPlugin,
                                dayGridPlugin,
                                timelinePlugin,
                                timeGridPlugin,
                                interactionPlugin,
                            ]}
                        />
                    </StyledCalendar>
                </Card>
            </Container>

            <Dialog
                fullWidth
                maxWidth="xs"
                open={openForm}
                onClose={onCloseForm}
                transitionDuration={{
                    enter: theme.transitions.duration.shortest,
                    exit: theme.transitions.duration.shortest - 80,
                }}
            >
                <DialogTitle sx={{ minHeight: 76 }}>
                    {/* {openForm && <> {currentEvent?.id ? "Edit Event" : "Add Event"}</>}
                     */}
                    Manage Event
                </DialogTitle>

                <CalendarForm
                    currentEventId={selectEventId}
                    colorOptions={CALENDAR_COLOR_OPTIONS}
                    onClose={onCloseForm}
                />
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="xs"
                open={openUnDialog}
                onClose={closeUnDialog}
                transitionDuration={{
                    enter: theme.transitions.duration.shortest,
                    exit: theme.transitions.duration.shortest - 80,
                }}
            >
                <DialogTitle sx={{ minHeight: 76 }}>
                    {/* {openForm && <> {currentEvent?.id ? "Edit Event" : "Add Event"}</>}
                     */}
                    Manage unavailables slot
                </DialogTitle>

                <CalendarUnavailable onClose={closeUnDialog} setRefresh={setRefresh} refresh={refresh} />
            </Dialog>
            {/* 
          <CalendarFilters
              open={openFilters.value}
              onClose={openFilters.onFalse}
              //
              filters={filters}
              onFilters={handleFilters}
              //
              canReset={canReset}
              onResetFilters={handleResetFilters}
              //
              dateError={dateError}
              //
              events={events}
              colorOptions={CALENDAR_COLOR_OPTIONS}
              onClickEvent={onClickEventInFilters}
          /> */}
        </>
    );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, filters, dateError }) {
    const { colors, startDate, endDate } = filters;

    const stabilizedThis = inputData.map((el, index) => [el, index]);

    inputData = stabilizedThis.map((el) => el[0]);

    if (colors.length) {
        inputData = inputData.filter((event) => colors.includes(event.color));
    }

    if (!dateError) {
        if (startDate && endDate) {
            inputData = inputData.filter((event) => isBetween(event.start, startDate, endDate));
        }
    }

    return inputData;
}
