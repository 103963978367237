import { Box, Button, Container } from "@mui/material";
import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function TestPage() {
    const handleTest = async () => {
        const collectionRef = collectionGroup(db, "payments");
        const q = query(collectionRef, where("id", "==", "pi_3PZpyRLWoLTwDp2I0uMM7Uj7"));
        const querySnapshot = await getDocs(q);
        const data = mapSnapshot(querySnapshot);
        console.log(data);
    };

    return (
        <Container>
            <Box m={1}>TestPage</Box>
            <Box>
                <Button variant="contained" onClick={handleTest}>
                    Test
                </Button>
            </Box>
        </Container>
    );
}
