import { collection, doc, getDocs, query, updateDoc, where } from "@firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export async function saveTelegramChatId(chatId, currentUser) {
    try {
        const collectionRef = collection(db, "users");
        const q = query(collectionRef, where("email", "==", currentUser.email));
        const querySnapshot = await getDocs(q);
        const user = querySnapshot.docs[0].data();
        const docRef = doc(db, "users", user.id);
        await updateDoc(docRef, { chatId: chatId });
        console.log("ChatId added to Firestore for " + currentUser.displayName);
    } catch (err) {
        console.log(err);
    }
}
