import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SearchIcon from "@mui/icons-material/Search";
import IosShareIcon from "@mui/icons-material/IosShare";
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Icon,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Switch,
    TextField,
} from "@mui/material";
import { useMemo, useState, useContext } from "react";
import { ChipC, GridContainer, GridFlexBox, Name, Title } from "../themes/themes";
import SearchBar from "./SearchBar";
import { LEADS_HEADER } from "../settings/settings";
import exportToExcel from "../functions/common-functions/exportToExcel";
import FilterDialogBox from "./FilterDialogBox";
import SortDialogBox from "./SortDialogBox";
import convertDate from "../functions/common-functions/convertDate";
import formatDate from "../functions/common-functions/formatDate";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../functions/common-functions/mapSnapshot";
import { LeadsContext } from "../context/LeadsContext"; //added by wayne 17-1-24 : usecontext to pass search value back to getLeadsWithSearch.js

export default function LeadsBoxHeader({
    handleBack,
    page,
    totalPages,
    handleForward,
    displayLeads,
    leads,
    setPage,
    title,
    folder,
    setMaxNumber,
    maxNumber,
    searchAssign,
    searchLabel,
    setSearchAssign,
    setSearchLabel,
    showActions,
    setShowActions,
    search,
    setSearch,
    sortByAssigned,
    setSortByAssigned,
    setSearch2,
    user,
    sortingOptions,
    setSortingOptions,
    setStartDate,
    setEndDate,
}) {
    const { filterValue, setFilterValue } = useContext(LeadsContext); //added by wayne 17-1-24 : usecontext to pass search value back to getLeadsWithSearch.js
    const [openSearch, setOpenSearch] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClickIncrease = () => {
        setMaxNumber(maxNumber + 250);
    };

    //added by wayne 2 nov 2023
    //function to load all
    //set to 1 to act as a status control to load all in getLeads.js
    const handleLoadAll = () => {
        setMaxNumber(1);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setSearch(value);
        // setFilterValue(value);//added by wayne 17-1-24 : usecontext to pass search value back to getLeadsWithSearch.js
    };

    const handleClearSearch = () => {
        setSearch("");
        // setFilterValue("");//added by wayne 17-1-24 : usecontext to pass search value back to getLeadsWithSearch.js
    };

    const handleDownloadAll = async () => {
        console.log("Export all leads: ", leads);
        const exportedLeads = await Promise.all(
            leads.map(async (lead) => {
                const { created_time, name, phone, email } = lead;
                const labels = lead.labels?.map((label) => `[${label.label}]`)?.join(" ");
                const date = formatDate(convertDate(created_time));

                if (leads.length > 1000) {
                    return { date, name, phone, email, labels };
                } else {
                    const collectionRef = collection(db, "leads", lead.id, "actions");
                    const snapshot = await getDocs(collectionRef);
                    const actions = mapSnapshot(snapshot);
                    const actionsString = actions
                        .map(
                            (action) =>
                                `${formatDate(convertDate(action.created))} ${action.userName}: ${action.action} ${
                                    action.remarks || ""
                                }`
                        )
                        .join("\n");

                    return { date, name, phone, email, labels, actionsString };
                }
            })
        );
        exportToExcel(exportedLeads, ["date", "name", "phone", "email", "labels", "actionsString"]);
    };

    const leadsLoaded = useMemo(() => {
        return leads?.length;
    }, [leads]);

    const handleClickPage = (e) => {
        setAnchorEl(e.currentTarget);
    };

    return (
        <Box m={1}>
            <GridContainer>
                <GridFlexBox xs={5} md={5} fs>
                    <Title cp onClick={() => setPage(1)}>
                        {title}
                    </Title>
                </GridFlexBox>
                <GridFlexBox xs={7} md={7} fe>
                    <IconButton onClick={() => setPage(1)}>
                        <HomeOutlinedIcon />
                    </IconButton>
                    <IconButton onClick={handleBack} disabled={page === 1}>
                        <Icon>arrow_back</Icon>
                    </IconButton>
                    <Name onClick={handleClickPage} cp>
                        Page {page} of {totalPages}
                    </Name>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    setPage(index + 1); // Pass the selected page number to the handler
                                    setAnchorEl(null); // Close the menu
                                }}
                            >
                                Page {index + 1}
                            </MenuItem>
                        ))}
                    </Menu>
                    <IconButton onClick={handleForward} disabled={page === totalPages}>
                        <Icon>arrow_forward</Icon>
                    </IconButton>
                </GridFlexBox>
                <GridFlexBox xs={12} md={12} gap={1} fs>
                    <GridContainer>
                        <GridFlexBox xs={12} fs>
                            <Name pr1>Leads displayed: {displayLeads?.length}</Name>
                            <Name pr1>|</Name>
                            <Name pr1>Leads loaded: {leadsLoaded}</Name>
                            <Button size="small" onClick={handleClickIncrease} color="button">
                                Load More
                            </Button>
                            {/*
                            added by wayne 2 nov 2023
                            to add in load all function
                            */}
                            <Button size="small" onClick={handleLoadAll} color="button">
                                Load All
                            </Button>
                        </GridFlexBox>
                        <GridFlexBox xs={12} fs>
                            {/* Filter button component */}
                            <FilterDialogBox
                                LEADS_HEADER={LEADS_HEADER}
                                setSearch2={setSearch2}
                                user={user}
                                leads={leads}
                            />

                            {/* Sort button component */}
                            <SortDialogBox
                                LEADS_HEADER={LEADS_HEADER}
                                sortingOptions={sortingOptions}
                                setSortingOptions={setSortingOptions}
                            />

                            {/* Export all loaded leads button */}
                            <Button
                                variant="text"
                                startIcon={<IosShareIcon />}
                                color="success"
                                size="small"
                                id="exportAll-button"
                                onClick={handleDownloadAll}
                            >
                                Export All
                            </Button>
                        </GridFlexBox>
                    </GridContainer>
                </GridFlexBox>
                <GridFlexBox fs xs={8} md={8}>
                    <TextField
                        value={search}
                        onChange={handleSearch}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        label="Seach name or phone"
                        InputProps={{
                            endAdornment: search && (
                                <InputAdornment position="end" onClick={handleClearSearch}>
                                    <Icon style={{ cursor: "pointer" }}>clear</Icon>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                </GridFlexBox>
                <GridFlexBox fe xs md>
                    <IconButton onClick={() => setShowActions((state) => !state)} style={{ padding: 0 }}>
                        <ChipC color={showActions ? "blue" : "grey"}>Actions</ChipC>
                    </IconButton>
                </GridFlexBox>
                <GridFlexBox fs>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked
                                    color="button"
                                    checked={sortByAssigned}
                                    onChange={() => setSortByAssigned((prev) => !prev)}
                                />
                            }
                            label="Sort by assigned dates"
                        />
                    </FormGroup>
                </GridFlexBox>
            </GridContainer>
            <SearchBar
                searchAssign={searchAssign}
                openSearch={openSearch}
                searchLabel={searchLabel}
                setSearchAssign={setSearchAssign}
                setSearchLabel={setSearchLabel}
            />
        </Box>
    );
}
