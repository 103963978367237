import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Card, CardContent, Grid, Icon, LinearProgress, Typography } from "@mui/material";
import { GridContainer, GridFlexBox } from "../../themes/themes";
import { uploadFile } from "./uploadFile";
import isFileImage from "./isFileImage";

export default function FileCard({ i, file, handleDelete, startUpload, returnFile }) {
    const [progress, setProgress] = useState(0);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (startUpload) {
            upload();
        }
    }, [startUpload]);

    const upload = async () => {
        if (!file) {
            return;
        }

        try {
            const folderRef = `files/${user.email}`;
            const fileRef = folderRef + "/" + file.name;

            const downloadURL = await uploadFile(file, fileRef, setProgress);         

            console.log(file);
            const reference = {
                size: file.size,
                date: new Date(),
                uploadedBy: user.id,
                name: file.name,   
                folderRef: folderRef,
                type: file.type,
                contentType: file.type,
                fullPath: fileRef,  
                downloadURL: downloadURL
            };

            returnFile(reference, i, downloadURL);
        } catch (err) {
            console.log("FileCard:", err);
        }
    };

    return (
        <Card style={{ width: "100%", marginTop: "3px", padding: 3 }} key={i}>
            <CardContent style={{ padding: 3 }}>
                <GridContainer>
                    <GridFlexBox w="10%" fs>
                        {isFileImage(file) ? (
                            <img width={"100%"} src={URL.createObjectURL(file)} alt="" />
                        ) : (
                            <Icon fontSize="large" color="primary">
                                article
                            </Icon>
                        )}
                    </GridFlexBox>
                    <GridFlexBox w="80%" fs>
                        <Typography variant="body2" style={{ marginLeft: "5px" }}>
                            <a href={file.downloadURL} target="blank">
                                {file.name}
                            </a>
                        </Typography>
                    </GridFlexBox>
                    <GridFlexBox w="10%" fe>
                        <Icon
                            fontSize="small"
                            style={{
                                color: "darkred",
                                cursor: "pointer",
                                display: startUpload ? "none" : "block"
                            }}
                            onClick={() => handleDelete(i)}
                        >
                            delete
                        </Icon>
                    </GridFlexBox>
                    <Grid item xs={12}>
                        <LinearProgress variant="determinate" value={progress} />
                    </Grid>
                </GridContainer>
            </CardContent>
        </Card>
    );
}
