import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";

export default async function loadDoc(collection, docId) {
    try {
        const docRef = doc(db, collection, docId);
        const snapshot = await getDoc(docRef);
        const data = mapDocSnapshot(snapshot);
        return data;
    } catch (error) {
        console.log(error);
    }
}
