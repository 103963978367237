import DescriptionIcon from "@mui/icons-material/Description";
import { Box, CardMedia, Grid, Icon, SvgIcon, Typography } from "@mui/material";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { Loading } from "../../themes/themes";
import getThumbnail from "../../components/UploadFile/getThumbnail";
import isFileImage from "../../components/UploadFile/isFileImage";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import Badge from "@mui/material/Badge";
import getListingThumbnail from "./getListingThumbnail";

export default function ListingFilesGallery({ handleClick, currentListing }) {
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const confirmation = useConfirmation();

    const fetchImages = async () => {
        try {
            setLoading(true);
            const collectionRef = collection(db, "listings", currentListing.id, "files");
            const q = query(collectionRef, orderBy("date", "desc"));
            const snapshot = await getDocs(q);
            const fileDetails = mapSnapshot(snapshot);
            // Sort files by updated date, latest first
            checkThumbnails(fileDetails, setFiles);
        } catch (err) {      
           
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImages();
    }, [user.id]);

    const isImage = (file) => {
        if (file.type.includes("image")) return true;
        return false;
    };

    const handDeleteAndRefresh = async (file) => {
        const result = await confirmation("Delete Listing", "Are you sure you want to delete this listing?");
        if (result) {
            //this part to accept file obj to be deleted, then get files and remove the to be deleted file from files and setFiles to update the image displayed.
            const updatedFiles = files.filter((item) => item !== file);
            await handleClick(file);
            setFiles(updatedFiles);
        }
    };

    return (
        <Grid container spacing={2}>
            <Loading loading={loading} />
            {files.map((file, index) => {
                return (
                    <Grid item xs={6} sm={6} md={4} lg={3} key={index}>
                        <Box
                            sx={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                textAlign: "center"
                            }}
                        >
                            {isImage(file) ? (
                                <Badge
                                    badgeContent="x"
                                    sx={{
                                        "& .MuiBadge-badge": {
                                            fontSize: "18px",
                                            height: "28px",
                                            width: "28px",
                                            borderRadius: "50%",
                                            backgroundColor: "red", // Set the background color to red
                                            color: "white" // Optional: Change the text color if needed
                                        }
                                    }}
                                    onClick={() => handDeleteAndRefresh(file)}
                                >
                                    <CardMedia
                                        component="img"
                                        image={file.thumbnailURL || file.downloadURL}
                                        alt="file"
                                        sx={{ width: "100%" }}
                                    />
                                </Badge>
                            ) : (
                                <Icon sx={{ fontSize: "45px" }}>article</Icon>
                            )}
                            <Typography variant="body2" noWrap>
                                {file.name}
                            </Typography>
                        </Box>
                    </Grid>
                );
            })}
        </Grid>
    );
}

async function checkThumbnails(files, setFiles) {
    try{
    if (files) {
        const newFiles = [...files];

        await Promise.all(
            newFiles.map(async (file, j) => {
                if (isFileImage(file)) {
                    const thumbnailURL = await getListingThumbnail(file);
                    if (thumbnailURL) {
                        newFiles[j] = { ...newFiles[j], thumbnailURL };
                    }else{
                        console.log('no thumbnail available.');
                    }
                    return thumbnailURL;
                }
                return Promise.resolve();
            })
        );
        setFiles(newFiles);
    } else {
        setFiles();
    }
}catch(err){
    console.log("checkThumbnails err:",err);
}
}
