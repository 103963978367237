import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../../firebase/firebase-utils";
import { doc, updateDoc } from "firebase/firestore";

export default async function getListingThumbnail(file,currentListingId) {
    if (file.thumbnailURL) return file.thumbnailURL;

    const fullPath = convertToThumbnailName(file.fullPath);
    const storageRef = ref(storage, fullPath);

    try {
        const url = await getDownloadURL(storageRef);
        if (currentListingId) {
            const docRef = doc(db, "listings", currentListingId, "files", file.id);
            updateDoc(docRef, { thumbnailURL: url });
        }
        return url;
    } catch (error) {
        console.log("Normal on first thumbnail creation: ",error);
        return null;
    }
}

function convertToThumbnailName(fullPath) {
    var lastSlash = fullPath.lastIndexOf("/");
    var pathWithoutName = fullPath.substring(0, lastSlash + 1); // extract the path without the name

    var name = fullPath.substring(lastSlash + 1); // extract the name from the full path

    var lastDot = name.lastIndexOf(".");
    var nameWithoutExtension = name.substring(0, lastDot); // remove the extension

    var thumbnailName = nameWithoutExtension + "_1080x1080.jpeg"; // append '_1080x1080.jpeg'

    return pathWithoutName + "/thumbnails/" + thumbnailName; // return new full path
}
