import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function deleteLabel(user, label) {
    try {
        const docRef = doc(db, "users", user.id, "labels", label.id);
        await deleteDoc(docRef);
        console.log("Deleted label: " + label.name);
    } catch (error) {
        console.log(error);
    }
}
