import { Box, Chip, Icon, IconButton, Typography } from "@mui/material";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import RecordDialog from "./RecordDialog";

export default function RecordComponent({ record }) {
    const [openEdit, setOpenEdit] = useState(false);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        // Update files
        if (record.files) {
            setFiles(record.files);
        }
        //
    }, [record]);

    const confirmation = useConfirmation();

    const handleAddFiles = (files) => {
        setFiles((prev) => [...prev, ...files]);
    };

    const handleEditRecord = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const formValues = {
            input: data.get("input"),
            link: data.get("link"),
        };

        const { input, link } = formValues;

        const { id } = record;
        const docRef = doc(db, "records", id);

        if (!arrayIsEmpty(files)) {
            const newInput = `${input}\n${link}`;
            await updateDoc(docRef, { link, input: newInput, date: new Date(), files });
        } else {
            await updateDoc(docRef, { input, date: new Date() });
        }

        toast.success("Record updated successfully");
        setOpenEdit(false);
    };

    const handleDeleteRecord = async (record) => {
        const response = await confirmation("Delete Record", "Are you sure you want to delete this record?");
        if (!response) return;

        const docRef = doc(db, "records", record.id);
        await deleteDoc(docRef);
        toast.success("Record deleted successfully");
    };

    const handleDeleteFile = (index) => {
        setFiles((prev) => prev.filter((_, i) => i !== index));
    };

    return (
        record && (
            <Box
                p={1}
                sx={{ borderBottom: "1px solid grey" }}
                onClick={() => console.log(record)}
                display="flex"
                gap={1}
            >
                <Box display="flex" flexDirection={"column"} flex={1} gap={1}>
                    <Typography variant="caption">
                        {`Date added: ${formatDate(convertDate(record.date), true)}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                        }}
                    >
                        {record.input}
                    </Typography>
                    <Typography variant="subtitle2">
                        {`Indexed Status: ${record?.status?.["firestore-vector-search"]?.state || "Not started"}`}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection={"column"}>
                    {!arrayIsEmpty(record.files) && (
                        <Chip
                            label={`${record.files.length} Files`}
                            color="primary"
                            onClick={() => setOpenEdit(true)}
                        />
                    )}
                </Box>
                <Box display="flex" flexDirection={"column"}>
                    <IconButton size="small" onClick={() => setOpenEdit(true)}>
                        <Icon fontSize="small">edit</Icon>
                    </IconButton>
                    <IconButton size="small" color="error" onClick={() => handleDeleteRecord(record)}>
                        <Icon fontSize="small">delete</Icon>
                    </IconButton>
                </Box>
                <RecordDialog
                    files={files}
                    handleAddFiles={handleAddFiles}
                    handleAddFilesRecord={handleEditRecord}
                    handleDeleteFile={handleDeleteFile}
                    openAddFiles={openEdit}
                    setOpenAddFiles={setOpenEdit}
                    record={record}
                />
            </Box>
        )
    );
}
