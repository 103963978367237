function extractRecordUrl(text) {
    const urlPattern = /https?:\/\/djcsystem\.com\/records\/[a-zA-Z0-9]+/;
    const match = text.match(urlPattern);

    if (match && match[0]) {
        return match[0];
    } else {
        return null;
    }
}

function removeRecordUrl(text) {
    const urlPattern = /https?:\/\/djcsystem\.com\/records\/[a-zA-Z0-9]+/g;
    return text.replace(urlPattern, "").trim();
}

export { extractRecordUrl, removeRecordUrl };
