import { useContext } from "react";
import { useInput } from "../context-utils/InputContext";
import useList from "../context-utils/ListContext";
import { NotificationContext } from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import addFriend from "../functions/addFriend";
import loadFriends from "../functions/loadFriends";

export default function useChooseFriend() {
    const { friends, setFriends } = useContext(StoreContext);
    const { user } = useContext(AuthContext);
    const { openNotification } = useContext(NotificationContext);

    const getFriends = async () => {
        if (!friends) {
            let friends = await loadFriends(user);
            setFriends(friends);
            return chooseFriend(friends);
        } else {
            return chooseFriend(friends);
        }
    };

    const list = useList();
    const input = useInput();

    async function chooseFriend(friends) {
        const response = await list(friends, "Choose friends", "displayName", "face", true, true, "email");
        if (!response) return;
        if (response === "addAccount") {
            const email = await input("Add friend", "Please enter your friend email", "Email", "");
            if (email) {
                const friend = await addFriend(user, email, openNotification);
                if (friend) {
                    setFriends([...friends, friend]);
                    return friend;
                }
            }
        } else {
            return response;
        }
    }

    return getFriends;
}
