import { GoogleGenerativeAI } from "@google/generative-ai";

const GOOGLE_AI_API_KEY = process.env.REACT_APP_GOOGLE_AI_API_KEY;

export default async function gemini(prompt, setMessages, messages, model, system) {
    console.log("Model:", model);

    const genAI = new GoogleGenerativeAI(GOOGLE_AI_API_KEY);
    const aiModel = genAI.getGenerativeModel({ model: model });

    if (!system) {
        system = `You are a helpful assistant.Take your time to understand user requirement and reply accordingly. accuracy is the most important key point in your response.`;
    }

    messages = messages.map((msg) => ({ role: msg.role === "user" ? "user" : "model", parts: msg.content || "" }));
    console.log(messages);
    // Start a chat session
    const chat = aiModel.startChat({
        history: messages
    });

    const index = messages.length + 1;
    setMessages((prev) => [...prev, { role: "user", parts: prompt }]);
    const result = await chat.sendMessageStream(prompt);
    let text = "";
    for await (const chunk of result.stream) {
        const chunkText = chunk.text();
        text += chunkText;
        // Remove excessive newline characters
        let cleanedText = text.replace(/\n{2,}/g, "\n");
        console.log(cleanedText);
        cleanedText.replace(/ {2}\n/g, "\n");
        setMessages((prev) => {
            const newChatHistory = [...prev];
            newChatHistory[index] = { role: "assistant", content: cleanedText };
            return newChatHistory;
        });
    }
}
