import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import deleteFbAdmin from "../../functions/deleteFbAdmin";
import { useGetFriends } from "../../context/StoreContext";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { InputContext } from "../../context-utils/InputContext";
import { ListContext } from "../../context-utils/ListContext";
import { NotificationContext } from "../../context-utils/NotificationContext";
import assignFbAdmins from "../../functions/assignFbAdmins";
import addFriend from "../../functions/addFriend";
import { useState, useEffect } from "react";

const facebookadmin = ({ fbpage }) => {
    const { openGlobalList } = useContext(ListContext);
    const { openInputDialog } = useContext(InputContext);
    const { openNotification } = useContext(NotificationContext);
    const { user } = useContext(AuthContext);
    //modified by wayne 9-11-2023
    //default is to false,modified to be reading setting value from fb settings.desktopView

    const [friends, setFriends] = useState([]);

    const handleDelete = async (page, item) => {
        // check whether it is the only one admin, if yes do not delete
        // if (page.admins.length === 1) {
        //     openNotification("You cannot delete the only admin", "error");
        //     return;
        // }
        console.log("delete from :", page.id, item);
        await deleteFbAdmin(page.id, item);
    };

    const getFriends = useGetFriends();

    const handleAddFbAdmin = async (page) => {
        console.log("Add admin");

        console.log("friends:", friends);
        await chooseAdmin(page, friends);
    };

    async function chooseAdmin(page, friends) {
        //added by wayne 7-11-2023
        //to add in add admin function
        const response = await openGlobalList(friends, "Add Admin", "displayName", "face", true, true);
        if (!response) return;
        if (response === "addAccount") {
            const email = await openInputDialog("Add friend", "Please enter your friend email", "Email", "");
            if (email) {
                const friend = await addFriend(user, email, openNotification);
                if (friend) {
                    console.log("chooseAdmin1:", page.id, friend.email);
                    await assignFbAdmins(page.id, friend.email);
                }
            }
        } else {
            console.log("page:", page);
            console.log("chooseAdmin2:", page.id, response.email);
            await assignFbAdmins(page.id, response.email);
        }
        getFriendList();
    }

    const getFriendList = async () => {
        const friendslist = await getFriends(user);
        setFriends(friendslist);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getFriendList();
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Stack direction={{ xs: "column", md: "row" }} spacing={1}>
                <div>
                    <div style={{ marginBottom: "5px", marginLeft: "5px" }}>
                        <Typography variant="h6">Admins</Typography>
                        <Chip
                            icon={<AddIcon />}
                            label="Add Admin"
                            color="primary"
                            style={{ marginRight: "5px", marginTop: "8px" }}
                            onClick={() => handleAddFbAdmin(fbpage)}
                        />

                        {fbpage.admins?.map((item, adminIndex) => {
                            const matchingFriend = friends.find((friend) => friend.email === item);

                            return (
                                <Chip
                                    key={adminIndex}
                                    label={matchingFriend ? matchingFriend.displayName : item}
                                    onDelete={() => handleDelete(fbpage, item)}
                                    style={{ marginRight: "5px", marginTop: "8px" }}
                                />
                            );
                        })}
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default facebookadmin;
