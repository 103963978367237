import {setDoc, doc,serverTimestamp  } from 'firebase/firestore';
import { db } from "../../firebase/firebase-utils";

export const updateListing = async (currentListing) => {
    console.log("currentListing.id:",currentListing.id);
    const listingsCollectionRef = doc(db, 'listings', currentListing.id); 

    try {
        await setDoc(listingsCollectionRef, currentListing, { merge: true });
        await setDoc(listingsCollectionRef, {
            ...currentListing,
            updateDateTime: serverTimestamp()
        }, { merge: true });
        console.log('Listing Data saved to Firebase successfully!');
     
    } catch (error) {
        console.error('Error saving data to Firebase:', error.message);
    }
    
};