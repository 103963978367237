import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";

export default function loadChatHistoryIds(user, setChatHistoryIds) {
    try {
        const collectionRef = collection(db, "users", user.id, "chatGptHistoryIds");
        const q = query(collectionRef, orderBy("date", "desc"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const chatHistoryIds = mapSnapshot(snapshot);
            setChatHistoryIds(chatHistoryIds);
        });
        return unsubscribe;
    } catch (error) {
        console.log(error);
    }
}
