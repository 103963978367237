import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import "animate.css";

// List of types:
// success
// danger
// info
// default
// warning

const animationsEntrance = ["animate__fadeIn"];

const animationsExit = ["animate__fadeOut"];

export function notification(title, message, type, duration = 2000) {
    const entrance = animationsEntrance[Math.floor(Math.random() * animationsEntrance.length)];
    const exit = animationsExit[Math.floor(Math.random() * animationsExit.length)];
    Store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", `${entrance}`],
        animationOut: ["animate__animated", `${exit}`],
        dismiss: {
            duration: duration,
            onScreen: false,
            showIcon: true
        },
        isMobile: true,
        pauseOnHover: true
    });
}
