import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function addFollowUp(user, followUpData) {
    try {
        const collecionRef = collection(db, "users", user.id, "followUps");
        const { id } = await addDoc(collecionRef, followUpData);
        console.log("Follow Up added into Firestore. Id: " + id);
        return id;
    } catch (error) {
        console.log(error);
    }
}
