import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function addForm(form) {
    try {
        const collectionRef = collection(db, "forms");
        const docRef = await addDoc(collectionRef, form);
        console.log("Form created with ID: ", docRef.id);
        return docRef.id;
    } catch (err) {
        console.log(err);
    }
}
