import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

export default function loadRooms(setRooms, setLoading) {
    try {
        setLoading(true);
        const ref = collection(db, "messages");

        return onSnapshot(ref, (snapshot) => {
            const rooms = mapSnapshot(snapshot);
            console.log(rooms);
            setRooms(rooms);
            setLoading(false);
        });
    } catch (error) {
        console.log(error);
        setLoading(false);
    }
}
