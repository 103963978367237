import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export async function deleteLeads(leadIds) {
    try {
        leadIds.forEach(async (leadId) => {
            const docRef = doc(db, "leads", leadId);

            await deleteDoc(docRef);
            console.log("Deleted lead ");
        });
    } catch (error) {
        console.log(error);
    }
}
