import { useState, useEffect } from "react";

function useScrollBottom(ref) {
    const [bottom, setBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const { current } = ref;
            if (current) {
                const { scrollHeight, scrollTop, clientHeight } = current;
                setBottom(scrollTop + clientHeight >= scrollHeight);
            }
        };

        if (ref.current) {
            ref.current.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener("scroll", handleScroll);
            }
        };
    }, [ref]);

    return bottom;
}

export default useScrollBottom;
