import axios from "axios";
let API_ADDRESS = "";
if (location.hostname === "localhost") {
    API_ADDRESS = "http://localhost:8080/";
} else {
    API_ADDRESS = process.env.REACT_APP_WHATSAPP_API_ADDRESS;
}

export default async function getState(clientId, setStatus, secret, setSize) {
    console.log(clientId);

    try {
        let data = JSON.stringify({
            clientId: clientId,
            from: clientId,
            secret,
        });

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_ADDRESS}check-state`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        let config2 = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${API_ADDRESS}get-directory-size`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        try {
            const response = await axios.request(config);
            if (response.data.status === true) {
                setStatus("ready");
            } else {
                setStatus("disconnected");
            }
        } catch (error) {
            console.log(error);
            setStatus("disconnected");
        }

        try {
            const response2 = await axios.request(config2);
            console.log(response2);
            setSize(response2.data.size);
        } catch (error) {
            console.log(error);
        }
    } catch (err) {
        console.log(err);
        setStatus("error");
        return "error";
    }
}
