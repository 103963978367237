import { collection, deleteDoc, doc, getDocs, query, where, writeBatch } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";

export default async function   deleteFolder(folder, user) {
    try {
        const batch = writeBatch(db);
        const folderName = user.id + ":" + folder.id;

        const collectionRef = collection(db, "leads");
        const q = query(collectionRef, where("folders", "array-contains", folderName));
        const leads = await getDocs(q);
        leads.forEach((lead) => {
            let folders = lead.folders || [];

            const index = folders.findIndex((f) => f === folderName);
            folders.splice(index, 1);

            const docRef = doc(db, "leads", lead.id);
            batch.update(docRef, { folders });
        });

        await batch.commit();
        console.log("Updated all leads to remove folder");

        const folderRef = doc(db, "users", user.id, "folders", folder.id);
        await deleteDoc(folderRef);
        console.log("Folder deleted");
    } catch (error) {
        console.log(error);
    }
}
