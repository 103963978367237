export default function formatDate(d, time = false, fullYear = true) {
    let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    // Adjust for zero-padded formatting
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    // Show only the last two digits of the year if fullYear is false
    if (!fullYear) {
        year = year.toString().substr(-2);
    }

    // Format date
    const formattedDate = [year, month, day].join("-");

    if (time) {
        // Get hours, minutes, and AM/PM
        let hours = d.getHours(),
            minutes = d.getMinutes(),
            ampm = hours >= 12 ? "PM" : "AM";

        // Convert hours to 12-hour format
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        // Zero-pad minutes
        minutes = minutes < 10 ? "0" + minutes : minutes;

        // Format time
        const formattedTime = `${hours}:${minutes} ${ampm}`;

        return `${formattedDate} ${formattedTime}`;
    } else {
        return formattedDate;
    }
}
