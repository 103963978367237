import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function deleteAssignedNumber(number) {
    try {
        const docRef = doc(db, "whatsappFlowAssignedNumbers", number.id);
        await deleteDoc(docRef);
    } catch (error) {
        console.log(error);
    }
}
