import { addDoc, collection } from "@firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export async function sendTelegramMessage(chatId, message) {
    const collectionRef = collection(db, "telegram");
    try {
        const writeResult = await addDoc(collectionRef, {
            chatId: chatId,
            message: message
        });
        console.log(writeResult.id + " added into Firebase Telegram");
    } catch (err) {
        console.log(err);
    }
}
