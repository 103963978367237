import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { collection, addDoc, doc, updateDoc, deleteDoc,getDocs } from "firebase/firestore";
import { db, storage } from "../../../firebase/firebase-utils"
// import { fetcher, endpoints } from 'src/utils/axios';

// ----------------------------------------------------------------------

const URL = "/api/calendar";

const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

// export function useGetEvents() {
//   const { data, isLoading, error, isValidating } = useSWR(URL, ()=>{}, options);

//   const memoizedValue = useMemo(() => {
//     const events = data?.events.map((event) => ({
//       ...event,
//       textColor: event.color,
//     }));

//     return {
//       events: events || [],
//       eventsLoading: isLoading,
//       eventsError: error,
//       eventsValidating: isValidating,
//       eventsEmpty: !isLoading && !data?.events.length,
//     };
//   }, [data?.events, error, isLoading, isValidating]);

//   return memoizedValue;
// }

async function fetchEvents() {
    const querySnapshot = await getDocs(collection(db, "appointments"));
    const events = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    return events;
}

export function useGetEvents() {
    // SWR hook to fetch events
    const {
        data: events,
        error,
        isValidating,
    } = useSWR("appointments", fetchEvents, {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    const isLoading = !events && !error;

    // Memoize the returned value to ensure consistency across re-renders
    const memoizedValue = useMemo(
        () => ({
            events: events || [],
            eventsLoading: isLoading,
            eventsError: error,
            eventsValidating: isValidating,
            eventsEmpty: !isLoading && !(events && events.length),
        }),
        [events, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// ----------------------------------------------------------------------

// export async function createEvent(eventData) {
//   /**
//    * Work on server
//    */
//   // const data = { eventData };
//   // await axios.post(URL, data);

//   /**
//    * Work in local
//    */
//   mutate(
//     URL,
//     (currentData) => {
//       const events = [...currentData.events, eventData];

//       return {
//         ...currentData,
//         events,
//       };
//     },
//     false
//   );
// }

// // ----------------------------------------------------------------------

// export async function updateEvent(eventData) {
//   /**
//    * Work on server
//    */
//   // const data = { eventData };
//   // await axios.put(endpoints.calendar, data);

//   /**
//    * Work in local
//    */
//   mutate(
//     URL,
//     (currentData) => {
//       const events = currentData.events.map((event) =>
//         event.id === eventData.id ? { ...event, ...eventData } : event
//       );

//       return {
//         ...currentData,
//         events,
//       };
//     },
//     false
//   );
// }

// // ----------------------------------------------------------------------

// export async function deleteEvent(eventId) {
//   /**
//    * Work on server
//    */
//   // const data = { eventId };
//   // await axios.patch(endpoints.calendar, data);

//   /**
//    * Work in local
//    */
//   mutate(
//     URL,
//     (currentData) => {
//       const events = currentData.events.filter((event) => event.id !== eventId);

//       return {
//         ...currentData,
//         events,
//       };
//     },
//     false
//   );
// }
export async function createEvent(eventData) {
    try {
        await addDoc(collection(db, "appointments"), {
            ...eventData,
           
        });
        // Here, you would trigger a state update or refetching logic specific to your component's needs
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export async function updateEvent(eventId, eventData) {
    try {
        const eventRef = doc(db, "appointments", eventId);
        await updateDoc(eventRef, {
            ...eventData,
           
            updatedDate:new Date(),
        });
        // Trigger a state update or refetching logic here
    } catch (e) {
        console.error("Error updating document: ", e);
    }
}

export async function deleteEvent(eventId) {
    try {
        await deleteDoc(doc(db, "appointments", eventId));
        // Trigger a state update or refetching logic here
    } catch (e) {
        console.error("Error removing document: ", e);
    }
}