import { arrayRemove, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function unassignAdmins(leads, unassignFrom) {
    //created by wayne 8-11-2023
    //this function is to unassignAdmins from leads.admins
    try {
        leads.forEach(async (lead) => {
            if (lead.admins) {
                const leaddoc = doc(db, "leads", lead.id);
                await updateDoc(leaddoc, {admins: arrayRemove(unassignFrom)}, { merge: true });                
                
            } else {
                return null;
            }
        });
    } catch (error) {
        console.log(error);
    }
}
