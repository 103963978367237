import { doc, getDoc, writeBatch } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
import { sendTelegramMessage } from "./sendTelegramMessage";
import convertDate from "./common-functions/convertDate";

export default async function assignLeads(user, assignTo, selectData, openNotification, setLeads = () => {}) {
    if (!selectData.length) {
        openNotification("No leads selected", "error");
        return;
    }

    // Temporary storage for changes to be applied in bulk after the loop
    let bulkUpdates = [];
    console.log(selectData)

    try {
        const batch = writeBatch(db);
        selectData.forEach((lead) => {
            let warriors = [assignTo.id];

            const assignment = {
                assign: { email: assignTo.id, name: assignTo.displayName },
                assignBy: user.id,
                date: new Date(),
            };
            const assignments = [assignment];

            const leadId = lead.id + ":" + assignTo.id;
            const sourceLeadId = lead.id;
            const type = "ASSIGNED_LEAD";

            // minus 1 second to order it correctly
            const created = convertDate(lead.created) || new Date();
            created.setSeconds(created.getSeconds() - 1);
            const newLead = {
                ...lead,
                created,
                id: leadId,
                assignedDate: new Date(),
                sourceLeadId,
                warriors,
                assignments,
                type,
                assignedLeads: [],
            };
            newLead.labels = [];

            const docRef = doc(db, "leads", leadId);
            batch.set(docRef, newLead, { merge: true });

            //Source Assignments
            let sourceAssignments = lead.assignments ? lead.assignments : [];
            sourceAssignments.push(assignment);
            const sourceDocRef = doc(db, "leads", sourceLeadId);
            batch.set(sourceDocRef, { assignments: sourceAssignments }, { merge: true });

            // Add to bulk updates
            bulkUpdates.push({ original: lead, newLead });
        });

        console.log(bulkUpdates)
        await batch.commit();

        // Apply all updates in bulk
        setLeads((prev) => {
            const newLeads = [...prev];

            bulkUpdates.forEach(({ original, newLead }) => {
                // Find the index for each original lead in the updated array to ensure accuracy
                const index = newLeads.findIndex((lead) => lead.id === original.id);
                if (index !== -1) {
                    const f = prev.find((lead) => lead.id === newLead.id);
                    console.log(f)
                    if (f === -1) {
                        // Replace original lead with updated original and newLead
                        newLeads.splice(index, 1, original, newLead);
                    }
                }
            });

            return newLeads;
        });

        console.log("Assignments completed");
        openNotification("Assignments done", "success");

        // Send Telegram message
        const friendRef = doc(db, "users", assignTo.id);
        const friendSnap = await getDoc(friendRef);
        const friend = mapDocSnapshot(friendSnap);
        const { chatId } = friend;
        if (chatId) {
            let telegramMessage = `You have been assigned ${selectData.length} leads by ${user.displayName}\n`;
            let displayData = selectData;
            if (displayData.length > 5) {
                displayData = displayData.slice(0, 5);
            }
            displayData.forEach((lead, i) => {
                telegramMessage += `\n--- ${i + 1} ---\n<b>Name: ${lead.name}</b> \nPhone: ${lead.phone}\nEmail: ${
                    lead.email
                } \nSource: ${lead.source} \nCampaign: ${lead.campaignName} \n`;
            });

            await sendTelegramMessage(chatId, telegramMessage);
            console.log("Telegram message sent to " + friend.displayName);
        }
    } catch (error) {
        console.log(error);
        openNotification(error.message, "error");
    }
}
