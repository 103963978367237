export function getWhatsappNumber(phone) {
    if (!phone) return "";

    var number = phone.toString().match(/\d/g);
    number = number.join("");

    if (number[0] === "0") {
        number = "6" + number;
    }

    if (number[0] === "1") {
        number = "60" + number;
    }

    console.log(number);
    return number;
}
