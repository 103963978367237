import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export const addListingId = async (userId, newListing) => {
    const listingsCollectionRef = collection(db, "listings");

    try {
        // Assign specific fields to the new document
        const data = {
            userId: userId,
            propertyName: newListing,
            active: false,
            createdDate: new Date(),
        };

        const response = await addDoc(listingsCollectionRef, data);

        console.log("New listing created in Firestore successfully! Id: ", response.id);
        return response.id;
    } catch (error) {
        console.error("Error creating new listing in Firestore:", error.message);
        return null;
    }
};
