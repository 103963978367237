import SendIcon from "@mui/icons-material/Send";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { CodeBlock } from "../../components/CodeBlock";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import chatGPT from "../../pages/Playground/chatGPT"
import deleteChatGptHistory from "../../pages/Playground/deleteChatGptHistory";
import loadChatGptHistory from "../../pages/Playground/loadChatGptHistory";
import loadChatHistoryIds from "../../pages/Playground/loadChatHistoryIds";
import saveChatHistory from "../../pages/Playground/saveChatHistory";
import chatGptFunctions from "../../pages/Playground/chatGptFunctions";
import chatGptFunctionCalling from "../../pages/AdminPage/chatGptFunctionCalling";

const PlaygroundPage = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [model, setModel] = useState("gpt-4-1106-preview");
    const [chatGptHistoryIds, setChatGptHistoryIds] = useState([]);
    const [chatGptHistoryId, setChatGptHistoryId] = useState();
    const [isScrolling, setIsScrolling] = useState(true);
    const [functionCalling, setFunctionCalling] = useState(false);

    const { user } = useContext(AuthContext);
console.log('22')
    const ref = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            setIsScrolling(false);
        };
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    useEffect(() => {
        if (isScrolling) {
            ref.current.scrollTo({
                top: ref.current.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [messages]);

    useEffect(() => {
        const unsubscribe = loadChatHistoryIds(user, setChatGptHistoryIds);
        return unsubscribe;
    }, []);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setIsScrolling(true);
        if (!input) return;
        const newMessages = [...messages, { content: input, role: "user" }];
        setMessages(newMessages);
        setInput("");
        let response = await new Promise((resolve, reject) => {
            try {
                if (!functionCalling) {
                    resolve(chatGPT(input, setMessages, newMessages, model));
                } else {
                    resolve(chatGptFunctionCalling());
                    // resolve(chatGptFunctions(input, setMessages, newMessages, model));
                }
            } catch (err) {
                reject(err.message);
            }
        });

        if (response) {
            const data = await saveChatHistory(response, chatGptHistoryId, user);
            setChatGptHistoryId(data);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const handleLoadHistory = async (item) => {
        setIsScrolling(true);
        console.log(item);
        setChatGptHistoryId(item);
        const messages = await loadChatGptHistory(item, user);
        console.log(messages);
        setMessages(messages);
    };

    const confirmation = useConfirmation();
    const handleDelete = async (item) => {
        const response = await confirmation("Delete", "Are you sure?");
        if (response) {
            await deleteChatGptHistory(item, user);
        }
    };

    const handleClickNewChat = () => {
        setMessages([]);
        setChatGptHistoryId(null);
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox fs fw gap="8px">
                    <Title>Playground</Title>
                    <Select
                        size="small"
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        sx={{ width: "200px" }}
                    >
                        <MenuItem value={"gpt-3.5-turbo"}>gpt-3.5-turbo</MenuItem>
                        <MenuItem value={"gpt-4"}>gpt-4</MenuItem>
                        <MenuItem value={"gpt-4-1106-preview"}>gpt-4-1106-preview</MenuItem>
                    </Select>
                    <Select
                        size="small"
                        value={functionCalling}
                        onChange={(e) => setFunctionCalling(e.target.value)}
                        sx={{ width: "200px" }}
                    >
                        <MenuItem value={true}>Functions Calling</MenuItem>
                        <MenuItem value={false}>Normal</MenuItem>
                    </Select>
                </GridFlexBox>
                <GridDivider />
                <Grid container display={"flex"} flexDirection={"row"} spacing={1}>
                    <Grid item xs={12} md={3}>
                        <Box
                            sx={{
                                height: ["20vh", "50vh"],
                                border: "1px solid lightgrey",
                                width: "100%",
                                overflowY: "scroll",
                                overflowX: "hidden"
                            }}
                        >
                            <Box m={"8px"} width={"100%"}>
                                <GridContainer>
                                    <GridFlexBox fs sb>
                                        <Name ct>Chat History</Name>
                                        <Button
                                            variant="outlined"
                                            sx={{ marginRight: "16px" }}
                                            onClick={handleClickNewChat}
                                        >
                                            New Chat
                                        </Button>
                                    </GridFlexBox>
                                    <GridDivider />
                                    {chatGptHistoryIds.map((history, i) => (
                                        <React.Fragment key={i}>
                                            <GridFlexBox fs cp onClick={() => handleLoadHistory(history)} xs={10}>
                                                <Name ct nowrap>
                                                    {history.name}
                                                </Name>
                                            </GridFlexBox>
                                            <GridFlexBox xs={1}>
                                                <IconButton size="small" onClick={() => handleDelete(history)}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </GridFlexBox>
                                            <GridFlexBox xs={1}></GridFlexBox>
                                        </React.Fragment>
                                    ))}
                                </GridContainer>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box
                            sx={{
                                height: ["45vh", "60vh"],
                                border: "1px solid lightgrey",
                                width: "100%",
                                overflowY: "scroll"
                            }}
                            ref={ref}
                        >
                            {messages.map((message, i) => {
                                if (message.role === "function" || message.content === null) return;
                                return (
                                    <Box key={i} sx={{ width: "100%", p: "8px" }} justifyContent={"flex-end"}>
                                        <Typography>{message.role === "user" ? user.displayName : "DJC"}</Typography>
                                        <ReactMarkdown
                                            components={{
                                                code({ node, inline, className, children, ...props }) {
                                                    const match = /language-(\w+)/.exec(className || "");
                                                    return !inline && match ? (
                                                        <CodeBlock
                                                            language={match[1]}
                                                            value={String(children).replace(/\n$/, "")}
                                                            {...props}
                                                        />
                                                    ) : (
                                                        <code className={className} {...props}>
                                                            {children}
                                                        </code>
                                                    );
                                                }
                                            }}
                                            sx={{ whiteSpace: "pre-wrap" }}
                                        >
                                            {message.content}
                                        </ReactMarkdown>
                                        <Divider />
                                    </Box>
                                );
                            })}
                        </Box>
                        <Grid item width={"100%"} display={"flex"} alignItems={"center"}>
                            <form style={{ width: "100%", display: "flex" }} onSubmit={handleSubmit}>
                                <Box mt="8px" flex={11}>
                                    <TextField
                                        fullWidth
                                        placeholder="Type your prompt here"
                                        variant="outlined"
                                        value={input}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        multiline
                                        rows={3}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && !e.shiftKey) {
                                                e.preventDefault();
                                                handleSubmit();
                                            }
                                        }}
                                    />
                                </Box>
                                <Box flex={1} pl={"10px"} pt={"10px"} display={"flex"}>
                                    <IconButton type="submit">
                                        <SendIcon fontSize="large" />
                                    </IconButton>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </GridContainer>
        </Container>
    );
};

export default PlaygroundPage;
