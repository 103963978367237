export default function convertToTreeTableFormat(dataArr) {
    return dataArr.map(item => {
        return {
            key: item.id,  // Use the 'id' as the unique key
            data: {
                ...item
            },
            children: [{ dummy: true, key: item.id+"1", data: {displayName: "No data"} }]  // Initialize a dummy 'children' array
        };
    });
}