import { Box, Button, Container, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import axios from "axios";
import {
    arrayUnion,
    collection,
    doc,
    getDoc,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { Name } from "../../themes/themes";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { notification } from "../../utils-functions/notification";
import { checkSubscription } from "./checkSubscription";
import Facebookadmin from "./facebookadmin";
import { getFBPages } from "./getFBPages";
import { getLongLivedUserToken } from "./getLongLivedUserToken";
import { getUser } from "./getUser";
import { savePages } from "./savePages";
import { subscribeToPage } from "./subscribeToPage";

const permission_scope = [
    "email",
    "pages_show_list",
    "ads_management",
    "leads_retrieval",
    "pages_messaging",
    "pages_read_engagement",
    "pages_manage_metadata",
    "pages_manage_ads",
    "business_management",
    // "whatsapp_business_management",
    // "whatsapp_business_messaging",
    // "instagram_basic",
    // "instagram_manage_messages",
];

export default function FacebookConnectPage() {
    const [loading, setLoading] = useState(false);
    const [fbPages, setFbPages] = useState([]);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!user) return;

        const collectionRef = collection(db, "pages");
        const q = query(collectionRef, where("admins", "array-contains", user.email), orderBy("date", "asc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const pages = mapSnapshot(querySnapshot);
            console.log(pages);
            setFbPages(pages);
        });

        return unsubscribe;
    }, [user]);

    const handleClickPage = async (page) => {
        try {
            console.log(user);
            await subscribeToPage(page, user);
        } catch (err) {
            notification("Opps", err.message, "danger");
            console.log(err);
        }
    };

    const scope = permission_scope.join(",");

    const handleClick = () => {
        window.FB.login(
            (response) => {
                // After the user logs in, this function gets the response.
                // You can handle the async operations here.
                handleLoginResponse2(response)
                    .then(() => {
                        console.log("Facebook login and async operations are complete.");
                        console.log("Response: ", response);
                    })
                    .catch((error) => {
                        console.error("An error occurred:", error);
                    });
            },
            {
                scope: scope,
            }
        );
    };

    const handleLoginResponse2 = async (response) => {
        console.log(response);
        try {
            setLoading(true);
            console.log("Short Lived Token: ", response.authResponse.accessToken);

            const longToken = await getLongLivedUserToken(response.authResponse.accessToken);
            console.log("longToken: ", longToken);

            const fbUser = await getUser(longToken);
            console.log("FB User: ", fbUser);

            const pages = await getFBPages(longToken, fbUser);
            console.log("Pages: ", pages);

            savePages(user, fbUser, pages);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            notification("Opps", err.message, "danger");
        }
    };

    const pagesToDisplay = useMemo(() => {
        return fbPages;
    }, [fbPages]);

    return (
        <Container disableGutters maxWidth="md">
            <Box p={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Paper sx={{ padding: "8px" }}>
                            <Typography variant="h5">Facebook Connect</Typography>
                            <Typography variant="subtitle1">
                                Subscribe to Facebook Leads Generation to get real time leads update from
                                Facebook
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{ padding: "8px" }}>
                            <Button onClick={handleClick} variant="contained">
                                Connect Facebook
                            </Button>
                        </Paper>
                        {loading && <LinearProgress />}
                    </Grid>

                    <Grid item xs={12}>
                        {pagesToDisplay && (
                            <Paper sx={{ padding: "8px" }}>
                                <Typography variant="h5">Pages</Typography>
                                {pagesToDisplay.map((page) => (
                                    <FacebookPageComponent key={page.id} page={page} />
                                ))}
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );

    function FacebookPageComponent({ page }) {
        const [subscribedFields, setSubscribedFields] = useState(false);
        const [instagramAcc, setInstagramAcc] = useState(null);

        useEffect(() => {
            const check = async () => {
                const subscribedFields = await checkSubscription(page.id, page.accessToken);
                const instagramAcc = await getConnectedInstagram(page.id, page.accessToken);
                if (instagramAcc) setInstagramAcc(instagramAcc);
                setSubscribedFields(subscribedFields);
            };
            check();
        }, [page]);

        useEffect(() => {
            if (!instagramAcc) return;

            const save = async () => {
                const docRef = doc(db, "pages", instagramAcc.id);
                const snapshot = await getDoc(docRef);
                if (snapshot.exists()) {
                    const existingAccessToken = snapshot.data().accessToken;
                    const existingPageId = snapshot.data().pageId || "";
                    const existingAdmins = snapshot.data().admins || [];
                    if (
                        existingAccessToken !== page.accessToken ||
                        existingPageId !== page.id ||
                        !existingAdmins.includes(user.id)
                    ) {
                        await updateDoc(docRef, {
                            accessToken: page.accessToken,
                            admins: arrayUnion(user.id),
                        });
                        console.log("Instagram account updated ", instagramAcc.id);
                    }
                } else {
                    await setDoc(
                        docRef,
                        {
                            ...instagramAcc,
                            date: new Date(),
                            name: instagramAcc.username,
                            object: "instagram",
                            admins: arrayUnion(user.id),
                            accessToken: page.accessToken,
                        },
                        { merge: true }
                    );
                    console.log("Instagram account saved ", instagramAcc.id);
                }
            };
            save();
        }, [instagramAcc]);

        return (
            <Paper sx={{ padding: "8px", marginBottom: "10px" }}>
                <Grid container display="flex">
                    <Grid item xs={6}>
                        <Box m={1} display="flex" gap="8px" flexDirection={"column"}>
                            <Button variant="outlined" color="primary" onClick={() => console.log(page)}>
                                {page.name}
                            </Button>
                            {page.object !== "instagram" ? (
                                <>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => {
                                            handleClickPage(page);
                                        }}
                                    >
                                        Subscribe
                                    </Button>
                                    <Box>
                                        <Name>Subscribed Fields:</Name>
                                        <Name color={subscribedFields ? green[500] : red[500]}>
                                            {subscribedFields ? subscribedFields : "none"}
                                        </Name>
                                        <Name>Connected Instagram Account:</Name>
                                        <Name color={instagramAcc ? green[500] : red[500]}>
                                            {instagramAcc ? <Box>{instagramAcc.username}</Box> : "None"}
                                        </Name>
                                    </Box>
                                </>
                            ) : (
                                <Box>
                                    <Name>Instagram Account</Name>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Facebookadmin fbpage={page} />
                    </Grid>
                </Grid>
            </Paper>
        );
    }
}

const getConnectedInstagram = async (pageId, accessToken) => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://graph.facebook.com/v19.0/${pageId}?fields=instagram_business_account&access_token=${accessToken}`,
    };

    try {
        const response = await axios.request(config);
        const instagramAccId = response.data.instagram_business_account?.id || null;
        if (instagramAccId) {
            const instagramUsername = await getInstagramUsername(instagramAccId, accessToken);
            console.log(instagramUsername);
            return { username: instagramUsername, id: instagramAccId, pageId: pageId };
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
    }
};

const getInstagramUsername = async (instagramAccId, accessToken) => {
    let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://graph.facebook.com/v19.0/${instagramAccId}?fields=username&access_token=${accessToken}`,
    };

    const response = await axios.request(config);
    if (response.data.username) {
        return response.data.username;
    } else {
        return null;
    }
};
