import React, { useContext, useState } from "react";
import { Container } from "@mui/material";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";
import { Grid, Paper, Typography, Chip } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import DoneIcon from "@mui/icons-material/Done";
import TelegramIcon from "@mui/icons-material/Telegram";
import FullDialog from "../../components/FullDialog";
import RegisterTelegramStepper from "./register-telegram-stepper";

export default function TelegramConnectPage() {
    const currentUser = useContext(AuthContext).user;

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Connect Telegram</Title>
                </GridFlexBox>
                <GridDivider />
                <Grid item xs={12}>
                    <Paper sx={{ padding: "8px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5">Telegram Connect</Typography>
                                <Typography variant="subtitle1">
                                    Connect to Telegram to get real-time messages for incoming leads
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent={"flex-start"}>
                                <Chip
                                    label={!currentUser.chatId ? "Connect to Telegram" : "Already connected"}
                                    clickable
                                    color="primary"
                                    onDelete={() => {}}
                                    deleteIcon={currentUser.chatId ? <DoneIcon /> : <TelegramIcon />}
                                    variant="outlined"
                                    onClick={handleClickOpen}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </GridContainer>

            <FullDialog
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                setOpen={setOpen}
                title={"Subscribe to Telegram"}
                Component={<RegisterTelegramStepper handleClose={handleClose} />}
            />
        </Container>
    );
}
