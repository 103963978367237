import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "./../../utils-functions/mapSnapshot";

export default function loadFlows(user, setFlows, setLoading) {
    try {
        const collectionRef = collection(db, "users", user.id, "whatsappFlows");
        const q = query(collectionRef, orderBy("date", "desc"));
        setLoading(true);
        return onSnapshot(q, (querySnapshot) => {
            const flows = mapSnapshot(querySnapshot);
            setFlows(flows);
            setLoading(false);
        });
    } catch (error) {
        console.log(error);
    }
}
