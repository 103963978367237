import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import { db } from "../firebase/firebase-utils";
import mapDocSnapshot from "../utils-functions/mapDocSnapshot";
// import { notification } from "../utils-functions/notification";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [authUser, setAuthUser] = useState();
    const [signInAsUser, setSignInAsUser] = useState();

    useEffect(() => {
        // Local storage persistence for user object
        const userLocal = JSON.parse(localStorage.getItem("user"));
        if (userLocal) {
            setUser(userLocal);
        }

        const auth = getAuth();
        let unsubscribe = () => {};
        onAuthStateChanged(auth, async (authUser) => {
            if (authUser) {
                const userRef = doc(db, "users", authUser.email);
                unsubscribe = onSnapshot(userRef, (userSnap) => {
                    if (userSnap.exists()) {
                        const user = { ...userSnap.data(), id: userSnap.id, uid: authUser.uid };
                        setUser(user);
                    } else {
                        clearLocalUser(setUser);
                        return;
                    }
                });
            } else {
                clearLocalUser(setUser);
            }
        });
        return () => unsubscribe();
    }, [authUser]);

    useEffect(() => {
        if (signInAsUser) {
            console.log("Sign in as user", signInAsUser.email);
            const docRef = doc(db, "users", signInAsUser.email);
            const unsubscribe = onSnapshot(docRef, (snapshot) => {
                const user = mapDocSnapshot(snapshot);
                if (user) {
                    setUser(user);
                }
            });
            return unsubscribe;
        }
    }, [signInAsUser]);

    return (
        <AuthContext.Provider
            value={{
                user,
                authUser,
                signInAsUser,
                //
                setUser,
                setAuthUser,
                setSignInAsUser,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function clearLocalUser(setUser) {
    setUser();
    localStorage.removeItem("user");
    localStorage.removeItem("authUser");
    console.log("Not signed in");
}
