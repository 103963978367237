import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function addBookedSale(user, bookedSale) {
    try {
        const collectionRef = collection(db, "users", user.id, "bookedSales");
        const { id } = await addDoc(collectionRef, bookedSale);
        console.log("Booked sale added into Firestore", id);
        return id;
    } catch (error) {
        console.log(error);
    }
}
