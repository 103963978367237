export default async function sendMessageToNames(
    names,
    message,
    from,
    setNames,
    delayTime = 1000,
    isTerminated,
    files,
    apiAddress,
    secret,
    pollObj,
    user
) {
    try {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        console.log(from);
        const statusArray = [];
        for (const [i, item] of names.entries()) {
            if (isTerminated.current) {
                break;
            }
            let newMessage = message;
            console.log("test:", newMessage);

            for (const key of Object.keys(item)) {
                const regex = new RegExp(`{${key}}`, "g");
                newMessage = newMessage.replace(regex, item[key]);
            }

            console.log("Updated message:", newMessage);
            let response;

            if (!files.length && !item.file) {
                response = await sendMessage(item.phone, newMessage, from, apiAddress, secret, user);
            } else if (item.file) {
                response = await sendAttachments(
                    item.phone,
                    item.file,
                    from,
                    newMessage,
                    apiAddress,
                    secret,
                    2
                );
            } else {
                response = await sendAttachments(item.phone, files, from, newMessage, apiAddress, secret);
            }

            // Added poll handling
            if (pollObj?.pollOptions?.length) {
                const pollResponse = await sendPoll(item.phone, from, apiAddress, secret, pollObj);
                console.log("pollResponse:", pollResponse);

                if (!pollResponse.status) {
                    response = pollResponse;
                }
            }

            console.log(response.status);
            let status = "failed";

            if (response.status) {
                status = "sent";
            } else if (response.message === "The number is not registered") {
                status = "bad number";
            }

            statusArray.push(status);
            setNames((prevState) => {
                const newNames = [...prevState];
                newNames[i].status = status;
                return newNames;
            });

            if (i < names.length - 1) {
                await delay(delayTime);
            }
        }

        return Promise.resolve(statusArray);
    } catch (err) {
        console.log(err);
    }
}

const sendPoll = async (number, from, apiAddress, secret, pollObj) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    console.log("pollObj:", pollObj);

    const requestBody = JSON.stringify({
        number: formatPhoneNumber(number),
        from: from,
        secret,
        pollName: pollObj.pollName,
        pollOptions: pollObj.pollOptions,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${apiAddress}send-poll-message`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
};

async function sendMessage(number, message, from, apiAddress, secret, user, options = {}) {
    //
    const myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    const metaData = { userId: user.id || "", userName: user.displayName || "" };

    const requestBody = JSON.stringify({
        number: formatPhoneNumber(number),
        message: message,
        from: from,
        secret,
        metaData,
        ...options,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
    };

    try {
        //
        const response = await fetch(`${apiAddress}send-message`, requestOptions);

        const result = await response.json();

        return result;
        //
    } catch (error) {
        //
        console.log("error", error);

        return { status: false };
    }
}

const sendAttachments = async (number, files, from, caption, apiAddress, secret, sender, type = 1) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let requestBody;

    if (type === 2) {
        requestBody = JSON.stringify({
            number: formatPhoneNumber(number),
            caption: caption,
            from: from,
            downloadURL: files,
            secret,
            sender,
        });
    } else {
        requestBody = JSON.stringify({
            number: formatPhoneNumber(number),
            caption: caption,
            from: from,
            downloadURL: files[0].downloadURL,
            filename: files[0].name,
            mimetype: files[0].type,
            fileSize: files[0].size,
            secret,
            sender,
        });
    }

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${apiAddress}send-image-url-message`, requestOptions);
        const result = await response.json();
        return result;
    } catch (error) {
        console.log("error", error);
        return { status: false };
    }
};
// not in use
// async function sendForwardedMessage(
//     number,
//     msgObj,
//     from,
//     apiAddress,
//     secret,
//     user,
//     clientNumber,
//     myNumber,
//     options = {}
// ) {
//     //
//     const myHeaders = new Headers();

//     myHeaders.append("Content-Type", "application/json");

//     const metaData = { userId: user.id || "", userName: user.displayName || "" };
//     // message = "test";
//     console.log("here :", msgObj);

//     const requestBody = JSON.stringify({
//         number: formatPhoneNumber(number),
//         msgObj,
//         from: from,
//         secret,
//         metaData,
//         clientNumber,
//         myNumber,
//         ...options,
//     });

//     console.log("requestBody:", requestBody);

//     const requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: requestBody,
//         redirect: "follow",
//     };

//     try {
//         //
//         const response = await fetch(`${apiAddress}send-forwarded-message`, requestOptions);

//         const result = await response.json();

//         return result;
//         //
//     } catch (error) {
//         //
//         console.log("error", error);

//         return { status: false };
//     }
// }

export { sendMessage, sendAttachments };

function formatPhoneNumber(phone) {
    // Group number
    if (phone.includes("@g.us")) {
        return phone;
    }

    let cleanedPhone = phone.replace(/[^0-9]/g, "");

    if (cleanedPhone[0] === "0") {
        cleanedPhone = "6" + cleanedPhone;
    }
    return cleanedPhone;
}
