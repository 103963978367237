import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

const getTransfers = async (user) => {
    if (!user.connectedStripeId) return 0;
    const collectionRef = collection(db, "stripeTransfers");
    const q = query(collectionRef, where("destination", "==", user.connectedStripeId));
    const snapshot = await getDocs(q);
    const payments = snapshot.docs.map((doc) => {
        let netTransfer = doc.data().amount - doc.data().amount_reversed;
        return {
            ...doc.data(),
            netTransfer,
            id: doc.id
        };
    });
    const totalPayments = payments.reduce((acc, payment) => {
        return acc + payment.netTransfer / 100;
    }, 0);
    return totalPayments;
};

export default getTransfers;
