import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/firebase-utils";

export default function callTestFunction() {
    const addMessage = httpsCallable(functions, "addMessage");
    addMessage({ text: "Fuck you on the way to blah" }).then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;
        const sanitizedMessage = data.text;
        console.log(sanitizedMessage);
    });
}
