import React from "react";
import { Container } from "@mui/material";
import { GridContainer, GridDivider, GridFlexBox, Title } from "../../themes/themes";

export default function NewLeadsTable() {
    return (
        <Container maxWidth="none" disableGutters>
            <GridContainer>
                <GridFlexBox>
                    <Title>New Leads Page</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
        </Container>
    );
}
