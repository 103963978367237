import streamData from "./streamData";

export default async function chatGPT(prompt, setMessages, messages, model, system) {
    console.log("chatGPT - Model:", model);

    if (!system) {
        system = `You are a helpful assistant.Take your time to understand user requirement and reply accordingly. accuracy is the most important key point in your response.`;
    }

    let msgs = [
        {
            role: "system",
            content: system,
        },
        ...messages.map((msg) => ({ role: msg.role, content: msg.content })),
        { role: "user", content: prompt },
    ];

    console.log("Messages to send:", msgs);

    try {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
            },
            body: JSON.stringify({
                model: model,
                messages: msgs,
                temperature: 0.5,
                stream: true,
                n: 1,
            }),
        };

        const response = await fetch("https://api.openai.com/v1/chat/completions", requestOptions);

        const data = response.body;
        if (!data) {
            return;
        }
        console.log("data:", data);
        const result = await streamData(data, setMessages, messages);
        return result;
  
       
    } catch (error) {
        console.error("Error:", error);
        return null;
    }
}
