import { doc, updateDoc } from "firebase/firestore";
import { arrayIsEmpty } from "../utils-functions/arrayIsEmpty";
import { db } from "../firebase/firebase-utils";

export default async function removeLabelFromLead(lead, user, labelName) {
    try {
        const newLabels = lead.labels ? [...lead.labels] : [];
        if (!arrayIsEmpty(newLabels)) {
            const index = newLabels.findIndex((l) => l.label === labelName && l.userEmail === user.id);
            if (index !== -1) {
                newLabels.splice(index, 1);
            } else {
                return lead;
            }
        } else {
            return lead;
        }
        const docRef = doc(db, "leads", lead.id);
        await updateDoc(docRef, { labels: newLabels });
        const newLead = { ...lead, labels: newLabels };
        console.log("Updated labels in lead ", lead.id);
        return newLead;
    } catch (error) {
        console.log(error);
    }
}
