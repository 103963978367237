export default function extractNumbersOnly(input) {
    // Check if the input is a number
    if (typeof input === "number") {
        return input.toString();
    }

    // If the input is a string, extract numbers only
    if (typeof input === "string") {
        return input.replace(/\D/g, "");
    }

    // If input is neither string nor number, handle as needed
    return "Invalid input"; // or throw an error as per your requirement
}
