import React, { useEffect, useMemo, useState } from "react";
import { loadFolders } from "../functions/loadFolders";
import loadFriends from "../functions/loadFriends";
import loadLabels from "../functions/loadLabels";

export const StoreContext = React.createContext();

export const StoreProvider = ({ children }) => {
    const [store, setStore] = useState({});
    const [friends, setFriends] = useState();
    const [labels, setLabels] = useState();
    const [folders, setFolders] = useState();
    const [followUps, setFollowUps] = useState();
    const [appointments, setAppointments] = useState();
    const [bookedSales, setBookedSales] = useState();
    const [leadDashdata, setLeadDashdata] = useState();
    const [callerDashdata, setCallerDashdata] = useState();
    const [unsubscribeStore, setUnsubscribeStore] = useState();
    const [quickAccesses, setQuickAccesses] = useState([]);
    const [settings, setSettings] = useState(() => {
        const savedSettings = localStorage.getItem("settings");
        if (savedSettings !== "undefined" || savedSettings) {
            return JSON.parse(savedSettings); // Provide a default value if nothing is stored
        } else {
            return {};
        }
    });

    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);

        return () => handleUnload();
    }, []);

    useEffect(() => {
        localStorage.setItem("settings", JSON.stringify(settings));
    }, [settings]);

    const handleUnload = () => {
        if (unsubscribeStore) {
            unsubscribeStore.forEach((fn) => fn?.());
        }
    };

    const addUnsubscribeStore = (fn) => {
        if (unsubscribeStore) {
            let array = [...unsubscribeStore];
            array.push(fn);
            setUnsubscribeStore(array);
        } else {
            setUnsubscribeStore([fn]);
        }
    };

    const clearStore = () => {
        setLabels();
        setFolders();
        setStore();
        setFollowUps();
        setAppointments();
        setBookedSales();
        if (unsubscribeStore) {
            unsubscribeStore.forEach((fn) => fn());
        }
    };

    const getFriends = async (user) => {
        const friends = await loadFriends(user);
        return friends;
    };

    const getFolders = async (user) => {
        console.log(user);
        const folders = await loadFolders(user);
        return folders;
    };

    const getLabels = async (user, type) => {
        const labels = await loadLabels(user, type);
        return labels;
    };

    const values = useMemo(() => {
        return {
            store,
            setStore,
            labels,
            setLabels,
            folders,
            setFolders,
            unsubscribeStore,
            setUnsubscribeStore,
            clearStore,
            followUps,
            setFollowUps,
            appointments,
            setAppointments,
            addUnsubscribeStore,
            friends,
            setFriends,
            getFriends,
            getFolders,
            getLabels,
            bookedSales,
            setBookedSales,
            leadDashdata,
            setLeadDashdata,
            callerDashdata,
            setCallerDashdata,
            quickAccesses,
            setQuickAccesses,
            //
            settings,
            setSettings,
        };
    }, [
        store,
        labels,
        folders,
        unsubscribeStore,
        followUps,
        appointments,
        friends,
        bookedSales,
        leadDashdata,
        callerDashdata,
        quickAccesses,
        settings,
    ]);

    return <StoreContext.Provider value={values}>{children}</StoreContext.Provider>;
};

export const useGetFriends = () => {
    const { getFriends } = React.useContext(StoreContext);
    return getFriends;
};

export function useGetFolders() {
    const { getFolders } = React.useContext(StoreContext);
    return getFolders;
}

export const useGetLabels = () => {
    const { getLabels } = React.useContext(StoreContext);
    return getLabels;
};
