import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function undoDeleteAction(action, lead) {
    try {
        const actionRef = doc(db, "leads", lead.id, "actions", action.id);
        await setDoc(actionRef, action);
        console.log("Undelete action");
    } catch (error) {
        console.log(error);
    }
}
