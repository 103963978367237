import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { Fragment, useState } from "react";
import UploadFilesButton from "../UploadFile/UploadFilesButton";

export default function AddItemsMenu({
    attachment = null,
    setAttachment = () => {},
    setOpenQuickReply = () => {},
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleFile = (files) => {
        setAnchorEl(null);
        console.log(files);
        // if (!files[0].type.includes("image")) {
        //     toast.error("This is not an image file");
        //     return;
        // }
        setAttachment(files[0]);
    };

    const handleQuickReplyClick = (event) => {
        setAnchorEl(null);
        setOpenQuickReply(event); // Ensure event is passed correctly
    };

    return (
        <Fragment>
            <IconButton onClick={handleMenuClick}>
                <AddIcon fontSize="large" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
            >
                <MenuItem>
                    <ListItemIcon>
                        <AttachFileIcon />
                    </ListItemIcon>
                    <ListItemText>
                        <UploadFilesButton
                            handleFile={handleFile}
                            label="Add File"
                            variant="none"
                            hover={false}
                            onClose={() => setAnchorEl(null)}
                        />
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleQuickReplyClick}>
                    <ListItemIcon>
                        <QuickreplyIcon />
                    </ListItemIcon>
                    <ListItemText>QUICK REPLY</ListItemText>
                </MenuItem>
                {/* Add more menu items here */}
            </Menu>
        </Fragment>
    );
}
