import { Dialog, DialogTitle, ListItem, ListItemButton } from "@mui/material";
import {
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow
} from "@mui/material/colors";
import { GridContainer, GridFlexBox } from "../themes/themes";
import { useMemo } from "react";

const colors = [
    blue[500],
    red[500],
    green[500],
    pink[500],
    purple[500],
    orange[500],
    yellow[500],
    deepPurple[500],
    indigo[500],
    teal[500],
    lightBlue[500],
    cyan[500],
    lightGreen[500],
    lime[500],
    amber[500],
    deepOrange[500],
    brown[500],
    grey[500],
    blueGrey[500]
];

export default function ColorPicker({ onClose, open }) {
    const openDialog = useMemo(() => {
        if (open) {
            return true;
        } else return false;
    }, [open]);

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={openDialog}>
            <DialogTitle>Pick color</DialogTitle>
            <GridContainer>
                {colors.map((color) => (
                    <GridFlexBox key={color} bgcolor={color} width={"150px"}>
                        <ListItem disableGutters>
                            <ListItemButton onClick={() => handleListItemClick(color)}></ListItemButton>
                        </ListItem>
                    </GridFlexBox>
                ))}
                <GridFlexBox width={"150px"}>
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => handleListItemClick("")}>Remove color</ListItemButton>
                    </ListItem>
                </GridFlexBox>
            </GridContainer>
        </Dialog>
    );
}
