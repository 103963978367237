import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function addUserProps(user, lead, props) {
    try {
        const docRef = doc(db, "users", user.id, "leadsProps", lead.id);
        await setDoc(docRef, props, { merge: true });
        console.log("Document written with ID: ", docRef.id);
    } catch (error) {
        console.log(error);
    }
}
