import { Box, Button, Icon } from "@mui/material";
import Container from "@mui/material/Container";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { db } from "../../firebase/firebase-utils";
import convertDate from "../../utils-functions/convertDate";
import formatDate from "../../utils-functions/formatDate";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import generateSecret from "../WhatsappWebPage/generateSecret";
import checkClients from "./checkClients";
import getState from "./getState";

function CounterButton() {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const collectionRef = collection(db, "whatsappClients");
            const snapshot = await getDocs(collectionRef);
            const whatsappClients = mapSnapshot(snapshot);
            setClients(whatsappClients);
        };
        getData();
    }, []);

    const checkInstance = (row) => async () => {
        console.log(row);
        const secretCounter = row.secretCounter || 1;
        const secret = generateSecret(row.id, secretCounter);
        const { status, size } = await getState(row.clientId, secret);
        console.log(status, size);
    };

    const checkAllClients = (row) => async () => {
        const secretCounter = row.secretCounter || 1;
        const secret = generateSecret(row.id, secretCounter);

        const response = await checkClients(row.clientId, secret);
        console.log(response);
    };

    const handleClick = async () => {
        clients.forEach(async (client) => {
            const docRef = doc(db, "whatsappClients", client.id);
            const { previousInstanceName } = client;
            await updateDoc(docRef, { status: "disconnected" });
            console.log("done");
        });
    };

    const columns = useMemo(
        () => [
            {
                field: "id",
                headerName: "id",
                width: 200,
            },
            {
                field: "date",
                headerName: "Date",
                width: 150,
                renderCell: (params) => <Box>{formatDate(convertDate(params.value), true)}</Box>,
            },
            {
                field: "clientId",
                headerName: "clientId",
                width: 200,
            },
            {
                field: "instanceName",
                headerName: "Instance Name",
                width: 200,
            },
            {
                field: "status",
                headerName: "Status",
                width: 200,
            },
            {
                field: "actions",
                type: "actions",
                width: 80,
                getActions: (params) => [
                    <GridActionsCellItem
                        key={1}
                        icon={<Icon>edit</Icon>}
                        label="Delete"
                        onClick={checkInstance(params.row)}
                    />,
                    <GridActionsCellItem
                        key={1}
                        icon={<Icon>check</Icon>}
                        label="Delete"
                        onClick={checkAllClients(params.row)}
                    />,
                ],
            },
        ],
        []
    );

    return (
        <Container
            sx={{
                display: "flex",
                height: "90vh",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <DataGrid rows={clients} columns={columns} pageSize={5} checkboxSelection />
            <Button onClick={handleClick}>Test</Button>
        </Container>
    );
}

export default CounterButton;
