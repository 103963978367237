import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function getCustomizeLeadHeader(
    user
) {
    // setLoading(true);
    try {      
        const collectionRef = collection(db, "users");
        const q = query(collectionRef, where("id", "==", user.email));

        const querySnapshot = await getDocs(q);

        const userDoc = querySnapshot.docs[0];

        if (userDoc) {
            const customizeLeadHeader = userDoc.get("settings.customizeLeadHeader");

            if (customizeLeadHeader !== undefined && customizeLeadHeader !== null) {
                return customizeLeadHeader;
            } else {
                console.log("customizeLeadHeader field is missing or null/undefined in the document.");
                return null;
            }
        } else {
            console.log("User " + user + " document not found.");
            return null;
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}
