import { doc, setDoc } from "firebase/firestore";
import loadDoc from "./loadDoc";
import { db } from "../firebase/firebase-utils";

export default async function addFriend(user, email, openNotification) {
    const id = email.trim().toLowerCase();
    try {
        let friend = await loadDoc("users", id);
        const object = { email: friend.email, displayName: friend.displayName, id: friend.id };
        if (friend) {
            const collectionRef = doc(db, "users", user.id, "friends", friend.id);
            setDoc(collectionRef, object);
            console.log("Added new friend: ", friend.displayName);
            openNotification("Added new friend " + friend.displayName, "success");
            return friend;
        } else {
            console.log("Friend not found");
            openNotification("Friend not found", "error");
            return null;
        }
    } catch (error) {
        console.log(error);
        openNotification(error.message, "error");
    }
}
