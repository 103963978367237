import { doc, arrayUnion,updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";


//created by wayne 12-11-2023
//this function is to add admin to db pages.id.admins
export default async function assignFbAdmins(id, admin) {
    try {
        console.log('assignFbAdmins:',id, admin);
        const docRef = doc(db, "pages", id);
        await updateDoc(docRef, { admins: arrayUnion(admin) }, { merge: true });

    } catch (error) {
        console.log(error);

    }
}
