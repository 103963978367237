import React, { useEffect, useState } from "react";
import { Button, Container, Typography } from "@mui/material";

// Get REACT_APP_FBAPPID and REACT_APP_APPSECRET from .env file
const REACT_APP_FBAPPID = process.env.REACT_APP_FBAPPID;
const REACT_APP_APPSECRET = process.env.REACT_APP_APPSECRET;

const permission_scope = [
    "email",
    "pages_show_list",
    "ads_management",
    "pages_messaging",
    "leads_retrieval",
    "whatsapp_business_management",
    "pages_read_engagement",
    "pages_manage_metadata",
    "pages_manage_ads",
    "whatsapp_business_messaging",
    "instagram_basic"
].join(",");

export default function ConnectInstagramPage() {
    return (
        <div>
            <LoginPage />
        </div>
    );
}

const LoginPage = () => {
    // State to hold the returned parameters
    const [errorInfo, setErrorInfo] = useState({
        error: "",
        errorCode: "",
        errorDescription: "",
        errorReason: ""
    });

    const handleLogin = (platform) => {
        // Dynamically get the current URL
        // const currentUrl = `${window.location.protocol}//${window.location.host}`;
        const currentUrl = "https://djcsystem.com/connect-instagram-page";

        // Construct the OAuth URL for Instagram/Facebook with the current URL as the redirect_uri
        const authUrl = `https://www.${platform}.com/dialog/oauth?client_id=${REACT_APP_FBAPPID}&redirect_uri=${currentUrl}&scope=${permission_scope}&response_type=token`;

        // Redirect to the OAuth URL
        window.location.href = authUrl;
    };

    useEffect(() => {
        const parseUrlParameters = () => {
            const params = new URLSearchParams(window.location.hash.substring(1)); // Removing '#' from hash
            const error = params.get("error");
            const errorCode = params.get("error_code");
            const errorDescription = params.get("error_description")?.replace(/\+/g, " "); // Replace '+' with space
            const errorReason = params.get("error_reason");

            if (error) {
                setErrorInfo({
                    error,
                    errorCode,
                    errorDescription,
                    errorReason
                });
            }
        };

        parseUrlParameters();
    }, []);

    return (
        <Container maxWidth="sm" style={{ textAlign: "center", marginTop: "50px" }}>
            <Typography variant="h4" gutterBottom>
                Login
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleLogin("instagram")}
                style={{ marginRight: "10px" }}
            >
                Login with Instagram
            </Button>
            <Button variant="contained" color="secondary" onClick={() => handleLogin("facebook")}>
                Login with Facebook
            </Button>
            <Typography variant="h4" gutterBottom>
                Login Status
            </Typography>
            {errorInfo.error && (
                <div>
                    <Typography variant="subtitle1">Error: {errorInfo.error}</Typography>
                    <Typography variant="subtitle1">Error Code: {errorInfo.errorCode}</Typography>
                    <Typography variant="subtitle1">Description: {errorInfo.errorDescription}</Typography>
                    <Typography variant="subtitle1">Reason: {errorInfo.errorReason}</Typography>
                </div>
            )}
        </Container>
    );
};
