import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer, GridToolbar } from "@mui/x-data-grid";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { collection, query, where, onSnapshot, deleteDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils"; // Assuming this path is correct
import convertDate from "../../functions/common-functions/convertDate";
import formatDate from "../../functions/common-functions/formatDate";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirmation } from "../../context-utils/ConfirmationContext";

const LoadScheduleComponent = ({ blockId, flowId, user, blockName }) => {
    const [openScheduleList, setOpenScheduleList] = useState(false);
    const [scheduledTasks, setScheduledTasks] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const confirmation = useConfirmation();
    useEffect(() => {
        if (!blockId || !flowId || !user.clientId) return;
        const q = query(
            collection(db, "scheduledTasks"),
            where("blockId", "==", blockId),
            where("flowId", "==", flowId),
            where("params.clientId", "==", user.clientId)
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let records = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                to: doc.data().params?.to,
                status: doc.data().status,
                scheduledTime: formatDate(convertDate(doc.data().scheduledTime), true),
                message: doc.data().message,
                date: doc.data().date // Assuming this is a Firestore Timestamp or similar sortable format
            }));

            // Sort the records by date in descending order (newer dates first)
            records.sort((a, b) => b.date.toMillis() - a.date.toMillis());

            setScheduledTasks(records);
        });

        return () => unsubscribe();
    }, [blockId, flowId, user.clientId]);

    const columns = [
        // { field: "id", headerName: "ID", width: 200, hide: true }, // Change here to hide the ID column
        { field: "to", headerName: "Send To", width: 150 },
        { field: "scheduledTime", headerName: "Scheduled Time", width: 150 },
        { field: "status", headerName: "Status", width: 150 },
        { field: "message", headerName: "Message", width: 350 }
    ];

    function CustomToolbar({ onDelete, onAssign, disabled }) {
        return (
            <GridToolbarContainer>
                <GridToolbar />
                <Box display="flex" alignItems={"center"} pt="4px">
                    <Button
                        startIcon={<DeleteIcon />}
                        color="error"
                        onClick={onDelete}
                        disabled={disabled}
                        size="small"
                    >
                        Delete
                    </Button>
                    {/* <Button
                        startIcon={<AssignmentIndOutlinedIcon />}
                        color="primary"
                        onClick={onAssign}
                        disabled={disabled}
                        size="small"
                    >
                        Assign
                    </Button> */}
                </Box>
            </GridToolbarContainer>
        );
    }

    const deleteScheduledTasks = async () => {
        const response = await confirmation(
            "Delete scheduledTasks",
            "Are you sure you want to delete these scheduled task(s)?"
        );
        if (!response) return;

        console.log(rowSelectionModel);
        const collectionRef = collection(db, "scheduledTasks");
        rowSelectionModel.forEach(async (id) => {
            await deleteDoc(doc(collectionRef, id));
        });
    };

    return (
        <>
            <Button size="small" variant="outlined" onClick={() => setOpenScheduleList(true)}>
                Load Schedule List
            </Button>
            <Dialog
                open={openScheduleList}
                onClose={() => setOpenScheduleList(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="xl"
            >
                <DialogTitle id="alert-dialog-title">SCHEDULE LIST for block name {blockName}</DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 400, width: "100%" }}>
                        <DataGrid
                            rows={scheduledTasks}
                            columns={columns}
                            checkboxSelection
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            getRowId={(row) => row.id}
                            sx={{
                                "& .MuiDataGrid-row": {
                                    color: "black", // Ensure text color is black
                                    "&.status-completed": {
                                        backgroundColor: "#c8e6c9" // Light green for completed
                                    },
                                    "&.status-scheduled": {
                                        backgroundColor: "#fff9c4" // Light yellow for scheduled
                                    },
                                    "&.status-error": {
                                        backgroundColor: "#ffcdd2" // Light red for error
                                    }
                                }
                            }}
                            getRowClassName={(params) => `status-${params.row.status.toLowerCase()}`}
                            slots={{
                                toolbar: () => (
                                    <CustomToolbar
                                        onDelete={deleteScheduledTasks}
                                        disabled={rowSelectionModel.length === 0}
                                    />
                                )
                            }}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenScheduleList(false)} autoFocus>
                        BACK
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default LoadScheduleComponent;
