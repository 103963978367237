import { Box, Button, Container, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import ImageComponent from "../../components/Image/ImageComponent";
import UploadFilesButton from "../../components/UploadFile/UploadFilesButton";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import removeSpecialChars from "../../functions/common-functions/removeSpecialChars";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "./../../themes/themes";
import ConnectWhatsappComponent from "./ConnectWhatsappComponent";

export default function WebHookGenerator() {
    const [from, setFrom] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [number, setNumber] = React.useState("{{contact.phone}}");
    const [json, setJson] = React.useState("");
    const [sendMessageApi, setSendMessageApi] = useState("https://api.djc.ai/send-message");
    const [files, setFiles] = useState([]);
    const [downloadURL, setDownloadURL] = useState();
    const [secret, setSecret] = useState();

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const clientId = removeSpecialChars(user.id);
        setFrom(removeSpecialChars(clientId));
        const jsonObject = { message, from, number };
        const json = JSON.stringify(jsonObject, null, 2);
        setJson(json);
    }, [user]);

    useEffect(() => {
        const jsonObject = {
            message,
            caption: arrayIsEmpty(files) ? "" : message,
            from,
            number,
            downloadURL,
            secret
        };
        const json = JSON.stringify(jsonObject, null, 2); // beautify the json

        setJson(json, null, 2);
    }, [message, from, number, downloadURL, secret]);

    useEffect(() => {
        if (arrayIsEmpty(files)) {
            setSendMessageApi("https://api.djc.ai/send-message");
            setDownloadURL();
        } else {
            setSendMessageApi("https://api.djc.ai/send-image-url-message");
            setDownloadURL(files[0].downloadURL);
        }
    }, [files]);

    const textAreaRef = useRef(null);
    const inputRef = useRef();

    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(json);
        e.target.focus();
        toast.success("Copied to clipboard");
    };

    const handleClick = () => {
        const cursorPosition = inputRef.current.selectionStart;
        const newMessage = message.slice(0, cursorPosition) + "{{contact.name}}" + message.slice(cursorPosition);
        setMessage(newMessage);
    };

    const handleFile = (files) => {
        setFiles([files[0]]);
    };

    const handleDeleteFile = (i) => {
        setFiles([]);
    };

    const confirmation = useConfirmation();

    const regenerateSecret = async () => {
        const response = await confirmation(
            "Regenerate secret?",
            "Are you sure you want to regenerate the secret? You have to update all your previous webhooks with the new secret."
        );
        if (response) {
            const docRef = doc(db, "whatsappClients", removeSpecialChars(user.id));
            const snapshot = await getDoc(docRef);
            if (snapshot.exists()) {
                const data = snapshot.data();
                console.log(data);
                const newCode = data.secretCounter ? data.secretCounter + 1 : 1;
                await updateDoc(docRef, { secretCounter: newCode });
                console.log("newCode", newCode);
            } else {
                toast.error("Whatsapp Client not found");
            }
        }
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Webhook Generator</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
            <Grid container display="flex" spacing={1}>
                <Grid item display="flex" xs={12} md={6}>
                    <Grid container display="flex" alignContent={"flex-start"} spacing="8px">
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper sx={{ padding: "8px" }}>
                                <Grid container display="flex" spacing={1}>
                                    <Grid item display="flex" xs={12} justifyContent={"flex-end"}>
                                        <Button size="small" onClick={handleClick}>{`{{contact.name}}`}</Button>
                                    </Grid>
                                    <Grid item display="flex" xs={2}>
                                        <Name>message</Name>
                                    </Grid>
                                    <Grid item display="flex" xs={10}>
                                        <TextField
                                            size="small"
                                            placeholder="Your message"
                                            value={message}
                                            multiline
                                            rows="10"
                                            fullWidth
                                            inputRef={inputRef}
                                            onChange={(e) => {
                                                setMessage(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item display="flex" xs={12}>
                                        {files.map((file, i) => (
                                            <React.Fragment key={file.id}>
                                                <ImageComponent image={file} handleDelete={() => handleDeleteFile(i)} />
                                            </React.Fragment>
                                        ))}
                                    </Grid>

                                    <Grid item display="flex" xs={2}>
                                        <Name>file</Name>
                                    </Grid>
                                    <Grid item display="flex" xs={10}>
                                        <UploadFilesButton handleFile={handleFile} label="Add File / Image" />
                                    </Grid>
                                    <Grid item display="flex" xs={2}>
                                        <Name>from</Name>
                                    </Grid>
                                    <Grid item display="flex" xs={10}>
                                        <TextField size="small" placeholder="Phone number" value={from} fullWidth />
                                    </Grid>
                                    <Grid display="flex" item xs={2}>
                                        <Name>number</Name>
                                    </Grid>
                                    <Grid item display="flex" xs={10}>
                                        <TextField
                                            size="small"
                                            placeholder="Phone number"
                                            value={number}
                                            fullWidth
                                            onChange={(e) => {
                                                setNumber(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid display="flex" item xs={2}>
                                        <Name>secret</Name>
                                    </Grid>
                                    <Grid item display="flex" xs={10}>
                                        <TextField size="small" placeholder="Secret" value={secret} fullWidth />
                                    </Grid>
                                    <Grid item display="flex" xs={12} justifyContent={"flex-end"}>
                                        <Button variant="contained" size="small" onClick={regenerateSecret}>
                                            Regenerate secret
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item display="flex" flexDirection={"column"} xs={12}>
                            <Paper sx={{ padding: "8px" }}>
                                <ConnectWhatsappComponent whatsappUser={user} setSecret={setSecret} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item display="flex" xs={12} md={6}>
                    <Grid container display="flex" alignContent={"flex-start"} spacing={1}>
                        <Grid item display={"flex"} flexDirection={"column"} xs={12}>
                            <Paper sx={{ padding: "8px" }}>
                                <Box display="flex" justifyContent="flex-start" gap={"8px"}>
                                    <Name>HEADERS: </Name>
                                    <Typography sx={{ border: "1px solid grey", padding: "4px" }}>
                                        Content-Type
                                    </Typography>
                                    <Typography sx={{ border: "1px solid grey", padding: "4px" }}>
                                        application/json
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="flex-start" gap={"8px"}>
                                    <Name>URL: </Name>
                                    <Typography sx={{ border: "1px solid grey", padding: "4px", marginTop: "8px" }}>
                                        {sendMessageApi}
                                    </Typography>
                                </Box>
                            </Paper>
                        </Grid>
                        <Grid item display={"flex"} flexDirection={"column"} xs={12}>
                            <Paper sx={{ padding: "8px", overflowY: "scroll" }}>
                                <Typography>RAW BODY: </Typography>
                                <Typography component="pre" ref={textAreaRef}>
                                    {json}
                                </Typography>
                                <Divider sx={{ margin: "8px 0 8px 0" }} />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={copyToClipboard}
                                    size="small"
                                    mt={"8px"}
                                >
                                    Copy to Clipboard
                                </Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
