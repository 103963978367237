import { Box, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { checkSubscription } from "./checkSubscription";
import { getLeadFormData } from "./getLeadFormData";
import { getLeadForms } from "./getLeadForms";
import { getPages } from "./getPages";
import { saveLeads } from "./saveLeads";
import SimpleDialog from "./simple-dialog";
import { Loading } from "../../themes/themes";

export default function LeadGenForm() {
    const [open, setOpen] = React.useState(false);
    const [targetObject, setTargetObject] = useState({});
    const [state, setState] = useState({ page: {}, leadForm: {} });
    const [data, setData] = useState([]);
    const [leadForms, setLeadForms] = useState();
    const [pageAccessToken, setPageAccessToken] = useState();
    const [leads, setLeads] = useState();
    const [subscription, setSubscription] = useState();
    const [loading, setLoading] = useState();
    const [unmappedLeads, setUnmappedLeads] = useState();
    const { user } = useContext(AuthContext);

    useEffect(() => {
        console.log(state);
    }, [state]);

    const handleClickOpen = async (targetObject) => {
        setTargetObject({
            object: targetObject.object,
            displayName: targetObject.displayName
        });
        if (targetObject.object === "page") {
            setLoading(true);
            const pages = await getPages(user.email);
            setLoading(false);
            setData(pages);
            setOpen(true);
        }
        if (targetObject.object === "leadForm") {
            setData(leadForms);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = async (targetObject, object) => {
        setOpen(false);
        setState({
            ...state,
            [targetObject.object]: {
                id: object.id,
                displayName: object[targetObject.displayName]
            }
        });
        if (targetObject.object === "page") {
            const leadForms = await getLeadForms(object.id, object.accessToken);
            setLeadForms(leadForms);
            setPageAccessToken(object.accessToken);
            // setState({ ...state, leadForm: {} });
            const subscription = await checkSubscription(object.id, object.accessToken);
            if (subscription) {
                setSubscription(subscription[0]);
            }
        }
        if (targetObject.object === "leadForm") {
            setLoading(true);
            const { leads, unmappedLeads } = await getLeadFormData(object.id, pageAccessToken);
            setLoading(false);
            setLeads(leads);
            setUnmappedLeads(unmappedLeads);
        }
    };

    const handleSaveLeads = async () => {
        setLoading(true);
        await saveLeads(unmappedLeads, state.page, state.leadForm, user);
        setLoading(false);
    };

    return (
        <div>
            <Box m={3} mt={1}>
                <Grid container justify="center">
                    <Grid container justify="center">
                        <Loading loading={loading} />
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Box p={1}>
                            <Button variant="outlined" color="primary" fullWidth>
                                User
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={8} md={8}>
                        <Box p={1}>
                            <Typography variant="body1">{user.displayName}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Box p={1} onClick={() => handleClickOpen({ object: "page", displayName: "name" })}>
                            <Button variant="outlined" color="primary" fullWidth>
                                Page
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={8} md={8}>
                        <Box p={1}>
                            <Typography variant="body1">{state && state.page.displayName}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Box p={1} onClick={() => handleClickOpen({ object: "leadForm", displayName: "name" })}>
                            <Button variant="outlined" color="primary" fullWidth disabled={loading}>
                                Lead Form
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={8} md={8}>
                        <Box p={1}>
                            <Typography variant="body1">{state && state.leadForm.displayName}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <Box p={1}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={handleSaveLeads}
                                disabled={loading}
                            >
                                Save Leads
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={8} md={8}>
                        <Box p={1}>
                            <Typography variant="body1">
                                Subscription:{" "}
                                {subscription ? `${subscription.name} ${subscription.subscribed_fields?.[0]}` : "No"}
                            </Typography>
                        </Box>
                    </Grid>
                    <Paper sx={{ padding: "8px", height: "70vh", width: "60vw", overflowY: "auto" }}>
                        <Grid container item xs={12} md={6} spacing={1}>
                            <Grid item xs={12} align="center">
                                <Typography variant="h5">Leads</Typography>
                            </Grid>
                            {leads &&
                                leads.map((lead, i) => (
                                    <React.Fragment key={`${lead.name}${i}`}>
                                        <Grid item xs={5}>
                                            <Box>
                                                <Typography variant="body2" noWrap>
                                                    {lead.name}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Box>
                                                <Typography variant="body2" noWrap align="right">
                                                    {lead.phone}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                        </Grid>
                    </Paper>
                </Grid>
            </Box>
            <SimpleDialog
                selectedValue=""
                open={open}
                onClose={handleClose}
                onClick={handleClick}
                targetObject={targetObject}
                objects={data}
            />
        </div>
    );
}
