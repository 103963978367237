import SendIcon from "@mui/icons-material/Send";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    Icon,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { CodeBlock } from "../../components/CodeBlock";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import chatGPT from "./chatGPT";
import chatGptFunctionsV2 from "./chatGptFunctions";
import claude from "./claude";
import deleteChatGptHistory from "./deleteChatGptHistory";
import gemini from "./gemini";
import loadChatGptHistory from "./loadChatGptHistory";
import loadChatHistoryIds from "./loadChatHistoryIds";
import saveChatHistory from "./saveChatHistory";
import { db } from "../../firebase/firebase-utils";
import { collection, getDocs, query, where } from "firebase/firestore";

const PlaygroundPage = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [model, setModel] = useState("gpt-3.5-turbo");
    const [chatGptHistoryIds, setChatGptHistoryIds] = useState([]);
    const [chatGptHistoryId, setChatGptHistoryId] = useState();
    const [isScrolling, setIsScrolling] = useState(true);
    const [functionCalling, setFunctionCalling] = useState(false);
    const [loading, setLoading] = useState(false);

    const { user } = useContext(AuthContext);

    const ref = useRef(null);

    useEffect(() => {
        const handleClick = () => {
            setIsScrolling(false); // Set to false on mouse click
        };

        // Add event listener for click
        window.addEventListener("click", handleClick);

        return () => {
            // Remove event listener when the component unmounts
            window.removeEventListener("click", handleClick);
        };
    }, []); // Empty dependency array means this effect runs only once on mount

    useEffect(() => {
        if (isScrolling) {
            ref.current.scrollTo({
                top: ref.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [messages]);

    useEffect(() => {
        const unsubscribe = loadChatHistoryIds(user, setChatGptHistoryIds);
        return unsubscribe;
    }, []);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setIsScrolling(true);
        if (!input) return;
        const newMessages = [...messages, { content: input, role: "user" }];
        setMessages(newMessages);
        setInput("");
        setLoading(true);
        let response = await new Promise((resolve, reject) => {
            try {
                if (!functionCalling) {
                    switch (model) {
                        case "gpt-3.5-turbo":
                        case "gpt-4":
                        case "gpt-4-turbo-preview":
                            resolve(chatGPT(input, setMessages, newMessages, model));
                            break;
                        case "claude-3-opus-20240229":
                            resolve(claude(input, setMessages, messages, model, "", user));
                            break;
                        case "gemini-pro":
                            resolve(gemini(input, setMessages, messages, model));
                    }
                    // resolve(langChain(input, setMessages, newMessages, model));
                } else {
                    resolve(chatGptFunctionsV2(input, setMessages, newMessages, model, user.email));
                }
            } catch (err) {
                reject(err.message);
                setLoading(false);
            }
        });
        setLoading(false);

        if (response) {
            const data = await saveChatHistory(response, chatGptHistoryId, user, model);
            setChatGptHistoryId(data);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setInput(value);
    };

    const handleLoadHistory = async (item) => {
        setIsScrolling(true);
        console.log(item);
        setChatGptHistoryId(item);
        const messages = await loadChatGptHistory(item, user);
        console.log(messages);
        setMessages(messages);
    };

    const confirmation = useConfirmation();
    const handleDelete = async (item) => {
        const response = await confirmation("Delete", "Are you sure?");
        if (response) {
            await deleteChatGptHistory(item, user);
        }
    };

    const handleClickNewChat = () => {
        setMessages([]);
        setChatGptHistoryId(null);
    };

    const handleScroll = () => {};

    const handleClick = async () => {
        //this part is to search thru all blockRelations document to search for targeNodeId and sourceNodeId which having the same value.
        try {
            console.log("start");

            const usersSnapshot = await getDocs(collection(db, "users"));

            for (const userDoc of usersSnapshot.docs) {
                const whatsappFlowsSnapshot = await getDocs(
                    collection(db, "users", userDoc.id, "whatsappFlows")
                );

                for (const whatsappFlowDoc of whatsappFlowsSnapshot.docs) {
                    const blocksRelationSnapshot = await getDocs(
                        collection(
                            db,
                            "users",
                            userDoc.id,
                            "whatsappFlows",
                            whatsappFlowDoc.id,
                            "blocksRelation"
                        )
                    );

                    for (const blockDoc of blocksRelationSnapshot.docs) {
                        const blockData = blockDoc.data();

                        if (blockData.sourceNodeId === blockData.targetNodeId) {
                            console.log(
                                `User ID: ${userDoc.id}, Whatsapp Flow ID: ${whatsappFlowDoc.id}, Block Relation ID: ${blockDoc.id}`
                            );
                        }
                    }
                }
            }

            console.log("end");
        } catch (err) {
            console.log("Error fetching documents: ", err);
        }
    };

    const run = async () => {
        const Ref = collection(db, "scheduledTasks");
        const q = query(
            Ref,
            where("blockId", "==", "xw2gSCbYdQvbTluwchpp"),
            where("status", "==", "scheduled")
        ); 
        const querySnapshot = await getDocs(q);
       const sortedDocs = querySnapshot.docs
           .map((doc) => ({ id: doc.id, ...doc.data() }))
           .sort((a, b) => a.chatRoomId.localeCompare(b.chatRoomId));

       sortedDocs.forEach((doc) => {
           console.log(`${doc.id} => ${doc.chatRoomId}`);
       });
        console.log("done");
    };

    
    return (
        <Container maxWidth="none" sx={{ marginTop: "8px" }}>
            <GridContainer>
                <GridFlexBox fs fw gap="8px">
                    {/* <Button onClick={run}>test</Button> */}
                    <Title>Playground</Title>
                    <Select
                        size="small"
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        sx={{ width: "200px" }}
                    >
                        <MenuItem value={"gpt-3.5-turbo"}>gpt-3.5-turbo</MenuItem>
                        <MenuItem value={"gpt-4"}>gpt-4</MenuItem>
                        <MenuItem value={"gpt-4-turbo-preview"}>gpt-4-turbo-preview</MenuItem>
                        <MenuItem value={"claude-3-opus-20240229"}>claude-3-opus-20240229</MenuItem>
                        <MenuItem value={"gemini-pro"}>gemini-pro</MenuItem>
                    </Select>
                    <Select
                        size="small"
                        value={functionCalling}
                        onChange={(e) => setFunctionCalling(e.target.value)}
                        sx={{ width: "200px" }}
                    >
                        <MenuItem value={true}>Functions Calling</MenuItem>
                        <MenuItem value={false}>Normal</MenuItem>
                    </Select>
                </GridFlexBox>
                <GridDivider />
                <Grid container display={"flex"} flexDirection={"row"} spacing={1}>
                    <Grid item xs={12} md={3}>
                        <Box display="flex" flexDirection={"column"}>
                            <Box
                                sx={{
                                    height: ["20vh", "75vh"],
                                    border: "1px solid lightgrey",
                                    width: "100%",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                }}
                            >
                                <Box m={"8px"} width={"100%"}>
                                    <GridContainer>
                                        <GridFlexBox fs sb>
                                            <Name ct>Chat History</Name>
                                            <Button
                                                variant="outlined"
                                                sx={{ marginRight: "16px" }}
                                                onClick={handleClickNewChat}
                                            >
                                                New Chat
                                            </Button>
                                        </GridFlexBox>
                                        <GridDivider />
                                        {chatGptHistoryIds.map((history, i) => (
                                            <React.Fragment key={i}>
                                                <GridFlexBox
                                                    fs
                                                    cp
                                                    onClick={() => handleLoadHistory(history)}
                                                    xs={10}
                                                >
                                                    <Name ct nowrap>
                                                        {history.name}
                                                    </Name>
                                                </GridFlexBox>
                                                <GridFlexBox xs={1}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleDelete(history)}
                                                    >
                                                        <Icon>delete</Icon>
                                                    </IconButton>
                                                </GridFlexBox>
                                                <GridFlexBox xs={1}></GridFlexBox>
                                            </React.Fragment>
                                        ))}
                                    </GridContainer>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Box
                            sx={{
                                height: ["45vh", "60vh"],
                                border: "1px solid lightgrey",
                                width: "100%",
                                overflowY: "scroll",
                            }}
                            onScroll={handleScroll}
                            ref={ref}
                        >
                            {messages.map((message, i) => {
                                if (message.role === "function" || message.content === null) return;
                                return (
                                    <Box key={i} sx={{ width: "100%", p: "8px" }} justifyContent={"flex-end"}>
                                        <Typography>
                                            {message.role === "user" ? user.displayName : "DJC"}
                                        </Typography>
                                        <ReactMarkdown
                                            components={{
                                                code({ node, inline, className, children, ...props }) {
                                                    const match = /language-(\w+)/.exec(className || "");
                                                    return !inline && match ? (
                                                        <CodeBlock
                                                            language={match[1]}
                                                            value={String(children).replace(/\n$/, "")}
                                                            {...props}
                                                        />
                                                    ) : (
                                                        <code className={className} {...props}>
                                                            {children}
                                                        </code>
                                                    );
                                                },
                                            }}
                                            sx={{ whiteSpace: "pre-wrap" }}
                                        >
                                            {message.content}
                                        </ReactMarkdown>
                                        <Divider />
                                    </Box>
                                );
                            })}
                        </Box>
                        <Grid item width={"100%"} display={"flex"} alignItems={"center"}>
                            <form style={{ width: "100%", display: "flex" }} onSubmit={handleSubmit}>
                                <Box mt="8px" flex={11}>
                                    <TextField
                                        fullWidth
                                        placeholder="Type your prompt here"
                                        variant="outlined"
                                        value={input}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        disabled={loading}
                                        multiline
                                        rows={3}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && !e.shiftKey) {
                                                e.preventDefault();
                                                handleSubmit();
                                            }
                                        }}
                                    />
                                </Box>
                                <Box flex={1} pl={"10px"} pt={"10px"} display={"flex"}>
                                    <IconButton type="submit" onClick={handleSubmit} disabled={loading}>
                                        {loading ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            <SendIcon fontSize="large" />
                                        )}
                                    </IconButton>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </GridContainer>
        </Container>
    );
};

export default PlaygroundPage;
