import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

//added by wayne 8-11-2023
//this function is to add admin to db leads.admins
export default async function assignAdmins(user, assignTo, selectData, openNotification) {
    try {
        selectData.forEach(async (lead) => {
            const leaddoc = doc(db, "leads", lead.id);
            await updateDoc(leaddoc, { admins: arrayUnion(assignTo.id) });
        });
    } catch (error) {
        console.log(error);
        openNotification(error.message, "error");
    }
}
