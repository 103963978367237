import { collection, getDocs, query, where } from "firebase/firestore";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { db } from "../../firebase/firebase-utils";
import { CustomerInfo, PaymentInfo, SubscriptionInfo, UserType } from "./SalesOverviewPage";
import convertDate from "../../utils-functions/convertDate";

const DefaultCommissionStructure = {
    "1": 0.2,
    "2": 0.1,
    "3": 0.05,
};

const processData = async (result: any, user: UserType) => {
    const baseUser = result[user?.id];
    const baseLevel = baseUser?.level || 0;

    //
    const processItem = async (key: string) => {
        const obj = {
            ...result[key].data,
            id: key,
            email: result[key].data?.email || "",
            level: result[key].level - baseLevel,
            totalDirectDownlines: result[key].downlines?.length || 0,
        };

        const email = obj.email || "";
        const customerRef = collection(db, "customers");

        try {
            if (email) {
                const customerQuery = query(customerRef, where("email", "==", email));
                const customerSnapshot = await getDocs(customerQuery);
                const customers = mapSnapshot<CustomerInfo>(customerSnapshot);
                const customer = customers[0] || {};

                if (customer.id) {
                    // get subscriptions
                    const subscriptionRef = collection(db, "customers", customer.id, "subscriptions");
                    const subscriptionSnap = await getDocs(subscriptionRef);
                    const subscriptions = mapSnapshot<SubscriptionInfo>(subscriptionSnap);
                    const subscriptionData = subscriptions.map((subscription) => {
                        // only for active subscriptions
                        if (subscription.status !== "active") return null;
                        return {
                            id: subscription.id,
                            created: convertDate(subscription.created),
                            status: subscription.status,
                            current_period_start: convertDate(subscription.current_period_start),
                            current_period_end: convertDate(subscription.current_period_end),
                            amount: subscription.items[0].plan.amount / 100,
                            currency: subscription.items[0].plan.currency,
                            interval: subscription.items[0].plan.interval,
                        };
                    });

                    // get payments
                    const paymentRef = collection(db, "customers", customer.id, "payments");
                    const paymentSnap = await getDocs(paymentRef);
                    const payments = mapSnapshot<PaymentInfo>(paymentSnap);

                    let paymentData = payments.map((payment) => {
                        // only for subscriptions payments
                        if (payment.prices) {
                            return {
                                id: payment.id,
                                amount: payment.amount / 100,
                                amount_received: payment.amount_received / 100,
                                amount_refunded: (payment.amount_refunded || 0) / 100,
                                created: convertDate(payment.created),
                                currency: payment.currency,
                            };
                        }
                    });

                    paymentData = paymentData.filter((payment) => payment?.created);

                    const totalPayment = paymentData.reduce(
                        (acc, payment) =>
                            acc + (payment?.amount_received || 0) - (payment?.amount_refunded || 0),
                        0
                    );

                    // calculate average payment
                    const averagePayment = totalPayment / paymentData.length;

                    // get monthly and yearly subscriptions
                    const monthlySubscriptions = subscriptionData.filter(
                        (subscription) => subscription?.interval === "month"
                    );
                    const yearlySubscriptions = subscriptionData.filter(
                        (subscription) => subscription?.interval === "year"
                    );

                    // get sum of monthly and yearly subscriptions
                    const totalMonthlySubscriptions = monthlySubscriptions.reduce(
                        (acc, subscription) => acc + (subscription?.amount || 0),
                        0
                    );
                    const totalYearlySubscriptions = yearlySubscriptions.reduce(
                        (acc, subscription) => acc + (subscription?.amount || 0),
                        0
                    );
                    let mrr = totalMonthlySubscriptions + totalYearlySubscriptions / 12;
                    if (mrr > averagePayment) mrr = averagePayment;

                    const commissionStructure = obj.commissionStructure || DefaultCommissionStructure;
                    const commissionPercentage = commissionStructure[obj.level.toString()] || 0;
                    const totalCommission = totalPayment * commissionPercentage;

                    const mrc = mrr * commissionPercentage;

                    obj.totalPayment = totalPayment;
                    obj.totalCommission = totalCommission;
                    obj.mrr = mrr;
                    obj.mrc = mrc;

                    paymentData.sort((a, b) => ((b?.created as any) || 0) - ((a?.created as any) || 0));

                    // Add subscriptions and payments to obj
                    obj.subscriptions = subscriptionData;
                    obj.payments = paymentData;
                }
            }
        } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
        }

        return obj;
    };

    const keys = Object.keys(result);
    const processedData = await Promise.all(keys.map(processItem));

    return processedData;
};

export default processData;
