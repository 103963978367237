import { arrayRemove, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function deleteFbAdmin(id, removeAdmin) {
    //created by wayne 12-11-2023
    //to remove admin from pages.id.admins
    try {
        const docRef = doc(db, "pages", id);

        // Update the array field using arrayRemove
        await updateDoc(docRef, {
            admins: arrayRemove(removeAdmin, { merge: true })
        });
    } catch (error) {
        console.log(error);
    }
}
