import { Box, Icon, IconButton, Input, InputAdornment } from "@mui/material";
import React from "react";
import useColors from "../../hooks/useColors";

export default function SearchAddInput({ handleAdd, search, setSearch, placeholder }) {
    const colors = useColors();

    return (
        <Box p={1} sx={{ borderBottom: "1px solid grey" }} width={"100%"}>
            <Input
                disableUnderline
                fullWidth
                placeholder={placeholder || "Search / Add ..."}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                sx={{
                    background: colors.searchBox,
                    borderRadius: "6px",
                    height: "35px",
                    padding: "0 8px 0 8px",
                }}
                startAdornment={
                    <InputAdornment position="start">
                        <Icon>search</Icon>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="start">
                        <Box display="flex" alignItems={"center"} gap={1}>
                            <IconButton
                                onClick={() => setSearch("")}
                                size="small"
                                sx={{ display: search ? "inherit" : "none" }}
                            >
                                <Icon color={"error"}>clear</Icon>
                            </IconButton>

                            <IconButton size="small" onClick={handleAdd}>
                                <Icon>add</Icon>
                            </IconButton>
                        </Box>
                    </InputAdornment>
                }
            />
        </Box>
    );
}
