import { Icon, IconButton } from "@mui/material";
import { Container } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../context-utils/NotificationContext";
import { AuthContext } from "../context/AuthContext";
import loadMessages from "../functions/loadMessages";
import loadRooms from "../functions/loadRooms";
import sendInteractiveMessage from "../functions/sendInteractiveMessage";
import { whatsappMessage } from "../functions/whatsappMessage";
import {
    ButtonC,
    GridContainer,
    GridDivider,
    GridFlexBox,
    IconC,
    Loading,
    Name,
    TextFieldC,
    Title
} from "../themes/themes";
import cfDate from "../utils-functions/cfDate";

const FROM_NUMBER = "103179706032387";
const type = "text";

export default function WhatsappMessagePage() {
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [rooms, setRooms] = useState();
    const [fromNumber] = useState(FROM_NUMBER);
    const [toNumber, setToNumber] = useState();
    const [loadedRooms, setLoadedRooms] = useState();
    const [token, setToken] = useState();

    const { user } = useContext(AuthContext);
    const { openNotification } = useContext(NotificationContext);

    const navigate = useNavigate();

    useEffect(() => {
        let unsubcribe = () => {};
        if (user?.longAccessToken) {
            setToken(user.longAccessToken);
        } else {
            navigate("/facebook-login-page");
        }
        unsubcribe = loadRooms(setRooms, setLoading);
        return () => unsubcribe();
    }, []);

    const handleSend = async () => {
        if (!token) {
            openNotification("Please login to Facebook to send messages", "info");
            return;
        }
        const response = await whatsappMessage(token, fromNumber, toNumber, message, type, user, openNotification);
        if (response) {
            setMessage("");
        }
    };

    const handleSendIM = () => {
        sendInteractiveMessage(token, fromNumber, toNumber, "button", user, openNotification);
    };

    const handleClickRoom = (room) => {
        setToNumber(room.toNumber);
        setLoadedRooms((loadedRooms) => {
            if (loadedRooms) {
                const f = loadedRooms.find((r) => r.id === room.id);
                if (f) {
                    return loadedRooms;
                } else {
                    return [...loadedRooms, room];
                }
            } else {
                return [room];
            }
        });
    };

    const roomId = fromNumber + "-" + toNumber;

    return (
        <Container>
            <GridContainer alignItems="flex-start">
                <GridFlexBox>
                    <Title>Whatsapp Messages</Title>
                </GridFlexBox>
                <GridDivider />
                <Loading loading={loading} />
                <GridFlexBox w="25%">
                    <GridContainer>
                        {rooms?.map((room) => (
                            <React.Fragment key={room.id}>
                                <GridFlexBox fs>
                                    <IconButton onClick={() => handleClickRoom(room)} style={{ padding: 0 }}>
                                        <IconC cp>face</IconC>
                                        <Name fs10> {room.toNumber ?? room.toNumber}</Name>
                                    </IconButton>
                                </GridFlexBox>
                            </React.Fragment>
                        ))}
                    </GridContainer>
                </GridFlexBox>
                <GridFlexBox w="75%">
                    {loadedRooms?.map((room) => (
                        <MessageBoxComponent
                            key={room.id}
                            roomId={room.id}
                            fromNumber={fromNumber}
                            message={message}
                            setMessage={setMessage}
                            handleSend={handleSend}
                            setLoading={setLoading}
                            handleSendIM={handleSendIM}
                            currentRoomId={roomId}
                        />
                    ))}
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}

function MessageBoxComponent({
    roomId,
    fromNumber,
    message,
    setMessage,
    handleSend,
    setLoading,
    handleSendIM,
    currentRoomId
}) {
    const [messages, setMessages] = useState();

    useEffect(() => {
        let unsubcribe = () => {};
        unsubcribe = loadMessages(roomId, setMessages, setLoading);
        return () => unsubcribe();
    }, []);

    let display = "none";
    if (currentRoomId === roomId) display = "flex";

    return (
        <GridContainer display={display} pr1>
            {messages?.map((m) => (
                <React.Fragment key={m.id}>
                    <GridFlexBox w="100%" justifyContent={checkSender(m.fromNumber, fromNumber)}>
                        <Name fs10 onClick={() => console.log(m)}>
                            {cfDate(m.date, true)}
                        </Name>
                    </GridFlexBox>
                    <GridFlexBox w="85%" justifyContent={checkSender(m.fromNumber, fromNumber)}>
                        <Name>{m.message}</Name>
                    </GridFlexBox>
                    <GridFlexBox w="15%">
                        <Icon style={{ color: checkStatus(m.status).color }}>{checkStatus(m.status).icon}</Icon>
                    </GridFlexBox>
                </React.Fragment>
            ))}
            <TextFieldC value={message} onChange={(e) => setMessage(e.target.value)} />
            <GridFlexBox>
                <ButtonC onClick={handleSend}>Send</ButtonC>
            </GridFlexBox>
            <GridFlexBox>
                <ButtonC onClick={handleSendIM}>Send Interactive Message</ButtonC>
            </GridFlexBox>
        </GridContainer>
    );
}

function checkSender(number, fromNumber) {
    if (number === fromNumber) {
        return "flex-end";
    } else {
        return "flex-start";
    }
}

const checkStatus = (status) => {
    let icon = "";
    let color = "black";
    switch (status) {
        case "sent":
            icon = "";
            color = "black";
            break;
        case "delivered":
            icon = "done";
            color = "black";
            break;
        case "read":
            icon = "done_all";
            color = "teal";
            break;
        case "failed":
            icon = "sms_failed";
            color = "red";
            break;
        default:
            icon = "";
            color = "black";
    }
    return { icon, color };
};
