// CodeBlock.js
import { useTheme } from "@emotion/react";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneLight, vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const CodeBlock = ({ language, value }) => {
    const theme = useTheme();
    const mode = theme.palette.mode;
    return (
        <SyntaxHighlighter language={language} style={mode === "light" ? oneLight : vscDarkPlus}>
            {value}
        </SyntaxHighlighter>
    );
};

export { CodeBlock };
