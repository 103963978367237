import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { CodeBlock } from "./CodeBlock";

export default function MarkDown({ markdown }) {
    return (
        <ReactMarkdown
            components={{
                ul: ({ ...props }) => (
                    <ul style={{ marginTop: "-2em", listStyleType: "none", padding: 0 }} {...props} />
                ),
                li: ({ ...props }) => <li style={{ marginBottom: "-0.5em" }} {...props} />,
                p: ({ ...props }) => <p style={{ margin: 0 }} {...props} />,
                code({ inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                        <CodeBlock language={match[1]} value={String(children).replace(/\n$/, "")} {...props} />
                    ) : (
                        <code className={className} {...props}>
                            {children}
                        </code>
                    );
                }
            }}
            sx={{ whiteSpace: "pre-wrap", breakWord: "break-word" }}
        >
            {markdown || ""}
        </ReactMarkdown>
    );
}
