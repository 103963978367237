import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { keyframes } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/DJCLogoGrey.png";
import { AuthContext } from "../../context/AuthContext";
import signUp from "../../functions/signUp";

import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

// ----------------------------------------------------------------------

export default function SignUpTrialPage() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [signupReferral, setSignupReferral] = useState({ shortId: "", fullId: "" });
    const { user, setUser, setAuthUser } = useContext(AuthContext);

    const { referral } = useParams();
    const navigate = useNavigate();

    const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
    const validateName = (name) => name.trim().length > 0;
    const validatePassword = (password) => password.trim().length > 0;
    const pulseAnimation = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

    // Define the breathing animation keyframes
    const breathingAnimation = keyframes`
  0%, 100% {
    transform: scaleX(0.55);
  }
  50% {
    transform: scaleX(0.5);
  }
`;

    // Combine both animations in the styled component
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        overflow: "hidden", // Ensure the scaling does not exceed the component bounds
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 300 : 900],
            animation: `${pulseAnimation} 2s infinite ease-in-out` // Apply pulse animation to the entire component
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
            animation: `${breathingAnimation} 2s infinite ease-in-out, ${pulseAnimation} 2s infinite ease-in-out`, // Apply both animations to the bar
            transformOrigin: "left" // Anchor transformation to the left
        }
    }));

    useEffect(() => {
        if (user) {
            navigate("/stripe-checkout-page/free-trial");
        }
    }, [user]);

    useEffect(() => {
        const checkIfReferralExist = async (referral) => {
            console.log("checkIfReferralExist: init ", referral);

            const usersColRef = collection(db, "users");
            const q = query(usersColRef, where("referralCode", "==", referral), limit(1));

            try {
                const usersSnapshot = await getDocs(q);

                if (!usersSnapshot.empty) {
                    console.log("checkIfReferralExist: User= ", usersSnapshot.docs[0].data());
                    const referralObj = { ...usersSnapshot.docs[0].data(), id: usersSnapshot.docs[0].id };
                    const shortId = referralObj.referralCode;
                    setSignupReferral({
                        shortId: shortId,
                        fullId: referralObj.id
                    });
                    console.log(
                        `checkIfReferralExist: Referral code "${referral}" is valid, the id is ${referralObj.id}`
                    );
                } else {
                    setSignupReferral({
                        shortId: referral || "",
                        fullId: ""
                    });
                }
            } catch (err) {
                console.log("checkIfReferralExist: Error= ", err.message);
            }
        };

        if (referral) {
            console.log("SignUpPage: referral= ", referral);
            //Check if the referral exist in the system or not.
            checkIfReferralExist(referral);
        }
    }, []);

    const handleSubmit = async () => {
        if (!validateName(name)) {
            setDialogContent("Please enter a valid name.");
            setOpenDialog(true);
            return;
        }
        if (!validateEmail(email)) {
            setDialogContent("Please enter a valid email address.");
            setOpenDialog(true);
            return;
        }
        if (!validatePassword(password)) {
            setDialogContent("Please enter a password.");
            setOpenDialog(true);
            return;
        }
        setBackdropOpen(true);
        // Add your submit logic here

        const newEmail = email.trim().toLowerCase();
        const newUser = await signUp(name, newEmail, phone, password, setUser, setAuthUser, signupReferral);
        if (newUser) {
            console.log(user);
            toast.success("Signed in successfully");
        } else {
            toast("You already have an account. You can login using the same email.");
            setBackdropOpen(false);
            navigate("/sign-in/stripe-checkout-page/free-trial");
        }
        setBackdropOpen(false);
        if (newUser) navigate("/stripe-checkout-page");
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <>
            <Container sx={{ pt: { xs: 5, md: 5 }, pb: { xs: 5, md: 5 } }} disableGutters>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        justifyContent: "center",
                        alignItems: "center",
                        gap: { xs: 4, md: 4 },
                        mb: { xs: 2, md: 2 },
                        textAlign: "center"
                    }}
                >
                    <img src={Logo} alt="" width={150} />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        justifyContent: "center",
                        gap: { xs: 4, md: 4 },
                        mb: { xs: 8, md: 10 },
                        textAlign: "center"
                    }}
                >
                    <Card
                        sx={{
                            width: "100%",
                            maxWidth: { xs: "95%", sm: 350, md: 600 },
                            m: "auto",
                            boxShadow: "5px 5px 15px rgba(0,0,0,0.3)"
                        }}
                    >
                        <CardContent>
                            <Box>
                                <Typography variant="h3" gutterBottom>
                                    Start Your 7-Days Free Trial Now!
                                </Typography>
                                <BorderLinearProgress variant="determinate" value={50} />
                                <Box sx={{ mb: "20px", mt: "20px" }}>
                                    <Typography variant="body2">Create account : Step 1 of 2</Typography>
                                </Box>

                                <TextField
                                    fullWidth
                                    label="Full Name"
                                    variant="outlined"
                                    sx={{ mb: 3 }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    autoComplete="off"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    variant="outlined"
                                    sx={{ mb: 3 }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="off"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Mobile Phone"
                                    variant="outlined"
                                    sx={{ mb: 3 }}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    autoComplete="off"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    label="Password"
                                    type="password"
                                    variant="outlined"
                                    sx={{ mb: 3 }}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete="new-password"
                                    required
                                />
                            </Box>
                            <Button fullWidth variant="contained" sx={{ mb: 2, height: "50px" }} onClick={handleSubmit}>
                                CONTINUE
                            </Button>
                        </CardContent>
                        <CardActions
                            sx={{
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                mt: 2
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "start", textAlign: "center", padding: "15px" }}>
                                {/* <Iconify icon={"material-symbols:lock"} width={35} sx={{ mt: "5px" }} /> */}
                                <Typography variant="body2" sx={{ ml: 1 }}>
                                    By providing us with your information you are consenting to the collection and use
                                    of your information in accordance with our{" "}
                                    <a
                                        href="https://www.djc.ai/termsandservices"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ textDecoration: "underline", color: "inherit" }}
                                    >
                                        Terms of Service
                                    </a>{" "}
                                    and{" "}
                                    <a
                                        href="https://www.djc.ai/privacypolicy"
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ textDecoration: "underline", color: "inherit" }}
                                    >
                                        Privacy Policy
                                    </a>
                                    .
                                </Typography>
                            </Box>
                        </CardActions>
                    </Card>
                </Box>
            </Container>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Notification</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogContent}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Ok</Button>
                </DialogActions>
            </Dialog>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
