import { setDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export const updateAgentHeader = async (data, img, user) => {
    const userRef = doc(db, "users", user.email);

    try {
        let imageUrl = null;
        if (img !== null) {
            const storage = getStorage();
            const imageRef = ref(storage, `agentAvatar/${user.clientId}`);
            const metadata = {
                contentType: 'image/jpeg', // Adjust based on the actual image type
            };

            await uploadBytes(imageRef, img, metadata);
            imageUrl = await getDownloadURL(imageRef);
        }

        // Get the current user document data
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();

        // Update the 'listingHeader' array in the 'settings' field
        const updatedUserData = {
            ...userData,
            settings: {
                ...(userData.settings || {}), // Use existing settings or create an empty object
                listingHeader: {
                    ...data,
                    avatar: imageUrl !== null ? imageUrl : userData.settings?.listingHeader?.avatar || "",
                    agentDomain: data.agentDomain || "property.djcsystem.com"
                }
            }
        };

        // Save the updated data back to the user document
        await setDoc(userRef, updatedUserData, { merge: true });

        console.log("Agent Header data saved to Firebase successfully!");
    } catch (error) {
        console.error("Error saving data to Firebase:", error.message);
    }
};
