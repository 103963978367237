import {
    Box,
    Button,
    Chip,
    Divider,
    Icon,
    IconButton,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Handle, Position } from "reactflow";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";
import FlowContext from "./FlowContext";

export default function AIBlockNode({ data, isConnectable }) {
    const [block, setBlock] = useState();
    const [timeUnit, setTimeUnit] = useState("minutes");
    const [time, setTime] = useState(0);
    const [systemPrompt, setSystemPrompt] = useState("");
    const { setUpdateTrigger } = useContext(FlowContext);
    const [blockInit, setBlockInit] = useState(false);

    useEffect(() => {
        const { blockId, flowId, createdBy } = data;
        const docRef = doc(db, "users", createdBy, "whatsappFlows", flowId, "blocks", blockId);
        const unsubscribe = onSnapshot(docRef, (snap) => {
            const block = mapDocSnapshot(snap);
            setBlock(block);
        });

        return unsubscribe;
    }, [data]);

    useEffect(() => {
        if (!block) return;
        if (blockInit) return;

        setSystemPrompt(block.systemPrompt || "");
        setTime(block.time || 0);
        setTimeUnit(block.timeUnit || "minutes");

        setBlockInit(true);
    }, [block]);

    //rerender status to bring rerender to parent component.
    const triggerRerender = () => {
        setUpdateTrigger((prev) => prev + 1); // Increment trigger to cause re-render
    };

    const handleChangeTime = (e) => {
        const value = e.target.value;
        console.log(value);

        if (parseFloat(value) < 0) {
            toast.error("Please enter a positive number");
            return;
        }

        setTime(value);
    };

    const handleSave = async () => {
        if (!block) return;

        const { blockId, flowId, createdBy } = data;
        const docRef = doc(db, "users", createdBy, "whatsappFlows", flowId, "blocks", blockId);

        try {
            const timeValue = isNaN(parseFloat(time)) ? 0 : parseFloat(time);
            await updateDoc(docRef, { time: timeValue, timeUnit, systemPrompt });
            toast.success("AI Node updated successfully");
        } catch (error) {
            console.error(error);
            toast.error("Failed to update AI Node");
        }
    };

    const confirmation = useConfirmation();

    const handleDelete = async () => {
        if (!block) return;

        const { blockId, flowId, createdBy } = data;
        const docRef = doc(db, "users", createdBy, "whatsappFlows", flowId, "blocks", blockId);

        try {
            const response = await confirmation("Delete block?", "Press OK to delete this block");
            if (!response) return;

            await deleteDoc(docRef);
            triggerRerender();
            toast.success("AI Node deleted successfully");
        } catch (error) {
            console.error(error);
            toast.error("Failed to delete AI Node");
        }
    };

    return (
        <Paper sx={{ width: "300px" }}>
            {block?.blockType !== "trigger" && (
                <Handle
                    type="target"
                    position={Position.Left}
                    isConnectable={isConnectable}
                    style={{
                        background: "#525252",
                        width: "15px",
                        height: "15px",
                        borderRadius: "5px",
                        opacity: 0.5,
                    }}
                />
            )}

            <Handle
                type="source"
                position={Position.Right}
                isConnectable={isConnectable}
                id={"SEQUENTIAL"}
                style={{
                    background: "#525252",
                    width: "15px",
                    height: "15px",
                    borderRadius: "5px",
                    opacity: 0.5,
                }}
            />
            <Box p={1} display="flex" flexDirection={"column"} gap={1} className="nowheel">
                <Box display="flex" alignItems={"center"} gap={1} justifyContent={"space-between"}>
                    <Box display={"flex"} gap={1} alignItems={"center"}>
                        <Typography>AI Node</Typography>
                        <Chip
                            size="small"
                            label={block?.blockType}
                            color={block?.blockType === "trigger" ? "error" : "primary"}
                            onClick={() => console.log(block)}
                        />
                    </Box>
                    <IconButton size="small" onClick={handleDelete}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Box>
                {block?.blockType !== "trigger" ? (
                    <>
                        <Box>
                            <TextField
                                rows={5}
                                multiline
                                fullWidth
                                value={systemPrompt}
                                placeholder="System Prompt"
                                onChange={(e) => setSystemPrompt(e.target.value)}
                                className="nodrag"
                            />
                        </Box>
                        <Box display="flex" alignItems="center" gap={1} className="nodrag">
                            <TextField size="small" onChange={handleChangeTime} value={time} />
                            <Select
                                value={timeUnit}
                                size={"small"}
                                fullWidth
                                onChange={(e) => setTimeUnit(e.target.value)}
                            >
                                <MenuItem value={"minutes"}>Minutes</MenuItem>
                                <MenuItem value={"hours"}>Hours</MenuItem>
                                <MenuItem value={"days"}>Days</MenuItem>
                            </Select>
                        </Box>
                        <Box className="nodrag">
                            <Button variant="contained" color="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </Box>
                    </>
                ) : (
                    <Box>
                        <Box my={2}>
                            <Divider />
                        </Box>
                        <Typography>AI replied</Typography>
                    </Box>
                )}
            </Box>
        </Paper>
    );
}
