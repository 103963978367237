import { HumanMessage, SystemMessage } from "@langchain/core/messages";
import { ChatOpenAI } from "@langchain/openai";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

const queryData = async (ids, query) => {
    try {
        console.log("Querying data...");

        const data = await Promise.all(
            ids.map(async (id) => {
                console.log("ID: ", id);
                const docRef = doc(db, "records", id);
                const docSnap = await getDoc(docRef);
                const record = mapDocSnapshot(docSnap);
                return record;
            })
        );

        console.log("Query: ", query);
        data.map((doc) => console.log(doc.input, "\n"));

        const chatModel = new ChatOpenAI({
            model: "gpt-3.5-turbo",
            temperature: 0.9,
            apiKey: process.env.REACT_APP_OPENAI_KEY_2,
        });

        const chatPrompt = [
            new SystemMessage(
                `Imagine you are a friendly customer service , answer questions based ONLY on the following context, reply in creative ways in your own words (but do not mention based on the context).<context>\n\n${data.map((doc) => doc.input).join("\n\n")}</context>\n\n`
            ),
            new HumanMessage(query),
        ];
        const chatRes = await chatModel.invoke(chatPrompt);

        return chatRes.content;
    } catch (err) {
        console.log(err);
        return "";
    }
};

export default queryData;
