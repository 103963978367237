import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { notification } from "../../utils-functions/notification";

export async function getPages(email) {
    try {
        const collectionRef = collection(db, "pages");
        const q = query(collectionRef, where("admins", "array-contains", email));
        const querySnapshot = await getDocs(q);
        const pages = mapSnapshot(querySnapshot);
        console.log("pages:", pages);
        return pages;
    } catch (err) {
        console.log(err);
        notification("Opps", err.message, "danger");
    }
}
