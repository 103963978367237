import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import mapSnapshot from "../utils-functions/mapSnapshot";

const NOW = new Date();
const TWO_MONTHS_AGO = new Date(NOW.setDate(NOW.getDate() - 60));

export default function loadFollowUps(user, setFollowUps) {
    try {
        const collectionRef = collection(db, "users", user.id, "followUps");
        const q = query(collectionRef, orderBy("date"));
        return onSnapshot(q, (followUpsSnap) => {
            let followUps = mapSnapshot(followUpsSnap);
            setFollowUps(followUps);
        });
    } catch (error) {
        console.log(error);
    }
}

