import { addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function addReminder(user, date, message, type) {
    try {
        const collectionRef = collection(db, "reminders");
        const { id } = await addDoc(collectionRef, {
            chatId: user.chatId || "",
            date: new Date(date),
            message,
            email: user.email || "",
            displayName: user.displayName,
            type
        });
        console.log("Reminder added into Firestore");
        return id;
    } catch (error) {
        console.log(error);
    }
}
