import { Box, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export default function LabelsList({ labels, fs12, leadsMode, setSearchLabel }) {
    const { user } = useContext(AuthContext);

    const displayLabel = (label) => {
        if (leadsMode === "ASSIGNED_LEADS") {
            if (label.userEmail === user.id) return true;
        }
        if (leadsMode === "SOURCE_LEADS") {
            return true;
        }
    };

    //Handle Click Label
    const handleClick = (label) => {
        console.log("Clicked label: ", label);
        setSearchLabel((prevState) => {
            if (prevState) {
                const f = prevState.find((l) => l.label === label.label);
                if (f) return prevState;
                const newState = [...prevState, label];
                return newState;
            } else {
                return [label];
            }
        });
    };

    return (
        <Box display={"flex"} flexWrap={"wrap"}>
            {labels &&
                labels.map((label, i) => {
                    if (displayLabel(label)) {
                        return (
                            <Box key={i}>
                                <IconButton
                                    size="small"
                                    style={{ padding: "0 2px 0 0" }}
                                    onClick={() => handleClick(label)}
                                >
                                    <Label label={label} fs12={fs12}>
                                        {label.label}
                                    </Label>
                                </IconButton>
                            </Box>
                        );
                    } else {
                        return "";
                    }
                })}
        </Box>
    );
}

const Label = ({ children, label, fs12 }) => {
    let fontSize = 10;
    if (fs12) fontSize = 12;
    return (
        <Typography
            style={{
                fontSize: fontSize,
                border: "1px solid",
                borderRadius: 7,
                paddingLeft: 7,
                paddingRight: 7,
                marginLeft: 3,
                backgroundColor: label.color,
                color: label.fontColor,
                borderColor: label.color
            }}
        >
            {children}
        </Typography>
    );
};
