import { useTheme } from "@emotion/react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, IconButton, Slide } from "@mui/material";
import { red } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { tokens } from "../theme";
import { ButtonC, GridFlexBox, Title } from "../themes/themes";

export default function UndoComponent2({ handleUndo, object, setObject }) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        if (!object) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }, [object]);

    return (
        <Grid
            container
            display={open ? "flex" : "none"}
            position={"fixed"}
            bottom={20}
            left={"5vw"}
            width={"90vw"}
            height="100px"
            overflow={"auto"}
            alignItems={"center"}
            style={{ zIndex: 1000 }}
        >
            <Slide direction="right" in={open}>
                <Grid container border={"solid 1px"} style={{ backgroundColor: colors.primary[900] }} padding={"8px"}>
                    <GridFlexBox xs={10}>
                        <Title>Undo Action</Title>
                    </GridFlexBox>
                    <GridFlexBox xs={2} fe>
                        <IconButton onClick={() => setObject()} size="small" style={{ paddingBottom: 0 }}>
                            <CancelIcon />
                        </IconButton>
                    </GridFlexBox>
                    <GridFlexBox gap={1} p5 xs={12}>
                        <ButtonC small fs10 bgColor={red[500]} onClick={() => handleUndo()}>
                            Undo
                        </ButtonC>
                    </GridFlexBox>
                </Grid>
            </Slide>
        </Grid>
    );
}
