import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export async function addRemark(action, lead, remark) {
    try {
        const docRef = doc(db, "leads", lead.id, "actions", action.id);
        await updateDoc(docRef, { remark: remark });
        console.log("Added Remark into lead action");
    } catch (error) {
        console.log(error);
    }
}
