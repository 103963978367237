let API_ADDRESS = "";
if (location.hostname === "localhost") {
    API_ADDRESS = "http://localhost:8080/";
} else {
    API_ADDRESS = process.env.REACT_APP_WHATSAPP_API_ADDRESS;
}

export default async function deleteClient(clientId, secret, apiAddress) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        clientId: clientId,
        secret: secret,
    });

    var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    try {
        const response = await fetch(`${API_ADDRESS}delete-client`, requestOptions);
        const result = await response.json();
        console.log(result);
    } catch (error) {
        console.log("error", error);
    }
}
