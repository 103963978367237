import { Container, Drawer } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FloatingButton } from "../components/FloatingButton";
import { LeadsFlexBox } from "../components/LeadsFlexBox";
import { SideBar } from "../components/SideBar";
import { AuthContext } from "../context/AuthContext";
import { LeadsContext } from "../context/LeadsContext";
import { StoreContext } from "../context/StoreContext";
import getLeads from "../functions/getLeads";
import loadFolders from "../functions/loadFolders";
import loadFollowUps from "../functions/loadFollowUps";
import { ChipC, GridContainer, GridDivider, GridFlexBox, Name, Title } from "../themes/themes";
import cfDate from "../utils-functions/cfDate";
import getDay from "../utils-functions/getDay";

const now = new Date();
const END_DATE = new Date(now.setHours(23, 59, 59));
const START_DATE = new Date(now.setMonth(now.getMonth() - 12));
START_DATE.setHours(0, 0, 1);

const LEADS_MODE = ["ASSIGNED_LEADS", "SOURCE_LEADS"];

export default function FollowUpLeadsPage() {
    const { user } = useContext(AuthContext);
    const { assignedLeads, setAssignedLeads, unsubscribeAssignedLeads, setUnsubscribeAssignedLeads } =
        useContext(LeadsContext);
    const { folderLeads, setFolderLeads, unsubscribeFolderLeads, setUnsubscribeFolderLeads } =
        useContext(LeadsContext);
    const { maxNumberAssignedLeads: maxNumber, setMaxNumberAssignedLeads: setMaxNumber } =
        useContext(LeadsContext);
    const { folders, setFolders, setUnsubscribeStore } = useContext(StoreContext);
    const { followUps, setFollowUps, addUnsubscribeStore } = useContext(StoreContext);

    const [loading, setLoading] = useState(false);
    const [startDate] = useState(START_DATE);
    const [endDate] = useState(END_DATE);
    const [leadsMode] = useState(LEADS_MODE[0]);
    const [openSideBar, setOpenSideBar] = useState(false);
    const [folder, setFolder] = useState();
    const [open, setOpen] = useState(true);
    const [isFirstMount, setIsFirstMount] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        let unsubscribe = () => {};
        if (!folders) {
            unsubscribe = loadFolders(user, setFolders);
            setUnsubscribeStore((array) => {
                if (array) {
                    let newArray = [...array];
                    newArray.push(unsubscribe);
                    setUnsubscribeStore(newArray);
                } else {
                    setUnsubscribeStore([unsubscribe]);
                }
            });
        }
    }, []);

    // Load user Follow ups
    useEffect(() => {
        let unsubscribe = () => {};
        if (!followUps) {
            console.log("loadFollowUps");
            unsubscribe = loadFollowUps(user, setFollowUps);
            addUnsubscribeStore(unsubscribe);
        }
    }, []);

    // Create follow ups object
    const followUpObj = useMemo(() => {
        console.log("running memo...");
        if (assignedLeads) {
            //modified by wayne 1 nov 2023
            //if followUps is array where length = 0 or undefined, it will return null instead of returning [].
            console.log("followUps:", followUps);
            if (followUps !== undefined && followUps.length > 0) {
                let followUpObj = {};
                followUps.forEach((followUp) => {
                    const lead = assignedLeads.find((lead) => lead?.id === followUp.leadId);
                    followUpObj[cfDate(followUp.date)] = followUpObj[cfDate(followUp.date)]
                        ? [...followUpObj[cfDate(followUp.date)], { ...lead, followUpId: followUp.id }]
                        : [{ ...lead, followUpId: followUp.id }];
                });
                return followUpObj;
            } else {
                return null;
            }
        }
        return null;
    }, [followUps, assignedLeads]);

    //   Loading leads
    useEffect(() => {
        let unsubscribe = () => {};

        if (folder) {
            unsubscribeFolderLeads?.();
            unsubscribe = getData();
            setUnsubscribeFolderLeads(() => () => unsubscribe());
            return () => unsubscribe();
        }

        if (!assignedLeads) {
            unsubscribe = getData();
            setUnsubscribeAssignedLeads(() => () => unsubscribe());
        }
    }, [folder]);

    // load leads when maxNumber changes
    useEffect(() => {
        if (isFirstMount) {
            setIsFirstMount(false);
            return;
        }
        unsubscribeAssignedLeads?.();
        let unsubscribe = () => {};
        unsubscribe = getData();
        unsubscribeAssignedLeads, setUnsubscribeAssignedLeads;

        return () => {
            unsubscribe();
        };
    }, [maxNumber]);

    const getData = () => {
        let setFunction = () => {};
        if (folder) {
            setFunction = setFolderLeads;
        } else {
            setFunction = setAssignedLeads;
        }
        setLoading(true);
        return getLeads(user, setFunction, startDate, endDate, setLoading, leadsMode, folder, maxNumber);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setOpenSideBar(open);
    };

    const triggerSlide = () => {
        setOpen((state) => !state);
        setTimeout(() => {
            setOpen((state) => !state);
        }, 100);
    };

    const handleClickFolder = (folder) => {
        navigate("/assigned-leads-page");
    };

    const title = "Follow Up Leads";

    return (
        <Container maxWidth="md" disableGutters>
            <FloatingButton toggleDrawer={toggleDrawer} folder={folder} />
            <GridContainer>
                <GridFlexBox>
                    <Title>Follow ups</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
            {/*modified by wayne 1 nov 2023
            if followUpObj is empty, will display "No follow up schedule." to user instead of displaying blank page.
            */}
            {followUpObj ? (
                Object.keys(followUpObj).map((date) => (
                    <React.Fragment key={date}>
                        <GridContainer>
                            <GridFlexBox fs pl1 gap={1}>
                                <Name>Follow up scheduled on</Name>
                                <Name bold pl1 ct>
                                    {date}
                                </Name>
                                <ChipC size="small" color={getDay(date).color}>
                                    {getDay(date).label}
                                </ChipC>
                            </GridFlexBox>
                        </GridContainer>
                        <LeadsFlexBox
                            leads={followUpObj[date]}
                            folder={folder}
                            setFolder={setFolder}
                            folders={folders}
                            title={title}
                            setMaxNumber={setMaxNumber}
                            setLoading={setLoading}
                            loading={loading}
                            maxNumber={maxNumber}
                            leadsMode={leadsMode}
                            triggerSlide={triggerSlide}
                            displayHeader={false}
                            enableSelect={false}
                            followUpPage={true}
                        />
                    </React.Fragment>
                ))
            ) : (
                <div>No follow up schedule.</div>
            )}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Drawer anchor={"left"} open={openSideBar} onClose={toggleDrawer(false)}>
                <SideBar
                    toggleDrawer={toggleDrawer}
                    folders={[]}
                    folder={folder}
                    setFolder={setFolder}
                    triggerSlide={triggerSlide}
                    handleClickFolder={handleClickFolder}
                    leadsMode={leadsMode}
                />
            </Drawer>
        </Container>
    );
}
