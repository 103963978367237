import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function addKeyword(keyword, user, currentFlow) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        const keywords = currentFlow.keywords || [];
        await updateDoc(docRef, { keywords: [...keywords, keyword] });
        console.log("Document written with ID: ", currentFlow.id);
    } catch (error) {
        console.log(error);
    }
}
