import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import { notification } from "../../utils-functions/notification";

export default async function shareWhatsappBlastHistory(item, friend, user) {
    try {
        const collectionRef = collection(db, "users", friend.id, "whatsappBlasts");
        const history = { ...item, date: new Date(), from: user.id, names: [] };
        await addDoc(collectionRef, history);
        console.log("Shared whatsapp blast history with friend: ", friend.displayName);
        notification("Success", "Shared whatsapp blast history with friend: " + friend.displayName, "success");
    } catch (error) {
        console.log(error);
        notification("Error", error.message, "danger");
    }
}
