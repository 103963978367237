import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    Divider,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@mui/material";
import { Name } from "../../themes/themes";
import React from "react";

export default function SettingsDialog({
    open,
    setOpen,
    currentFlow,
    model,
    setModel,
    handleChangeAppointment,
    handleChangeDisableAI,
    handleChangeSaveLead,
    handleChangeSendAudio,
    handleUnresolveTelegramNotify,
    user,
    handleChangeAiDelayReply,
    aiDelayReply,
}) {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Button
                variant="contained"
                size={"small"}
                onClick={() => setOpen(true)}
                disabled={!currentFlow.id}
            >
                Settings
            </Button>
            <Dialog
                onClose={handleClose}
                open={open}
                PaperProps={{
                    sx: {
                        width: {
                            xs: "95vw", // width for extra-small screens
                            sm: "300px", // width for medium screens and above
                        },
                    },
                }}
            >
                <DialogTitle>Whatsapp Flow Settings</DialogTitle>
                <Box m={"16px"}>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>AI Model</Name>
                        <Select
                            disabled={!currentFlow.id}
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={"gpt-4o-mini"}>gpt-4o-mini</MenuItem>
                            <MenuItem value={"gemini-1.5-flash"}>gemini-1.5-flash</MenuItem>
                            {/* <MenuItem value={"gpt-3.5-turbo"}>gpt-3.5-turbo</MenuItem> */}
                            {/* <MenuItem value={"claude-3-haiku-20240307"}>Claude 3 Haiku</MenuItem> */}
                            <Divider />
                            {user.addOnItems?.includes("gpt-4") && (
                                <MenuItem value={"gpt-4o"}>gpt-4o</MenuItem>
                            )}
                            {user.addOnItems?.includes("gpt-4") && (
                                <MenuItem value={"gemini-1.5-pro"}>gemini-1.5-pro</MenuItem>
                            )}
                            {user.addOnItems?.includes("gpt-4") && (
                                <MenuItem value={"claude-3-5-sonnet-20240620"}>Claude 3.5 Sonnet</MenuItem>
                            )}
                        </Select>
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Appointment</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeAppointment}
                            checked={currentFlow.appointment === undefined ? false : currentFlow.appointment}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Enable AI</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeDisableAI}
                            checked={currentFlow.disableAI === undefined ? true : !currentFlow.disableAI}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Save Leads</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeSaveLead}
                            checked={currentFlow.saveLead === undefined ? true : currentFlow.saveLead}
                        />
                    </Box>
                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Voice Reply</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleChangeSendAudio}
                            checked={currentFlow.sendAudio === undefined ? false : currentFlow.sendAudio}
                        />
                    </Box>

                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>AI Delay Reply</Name>
                        <Select
                            disabled={!currentFlow.id}
                            value={currentFlow.aiDelayReply || "0 second"}
                            onChange={(e) => handleChangeAiDelayReply(e.target.value)}
                            size="small"
                        >
                            <MenuItem value={0}>0 second</MenuItem>
                            <MenuItem value={3}>3 seconds</MenuItem>
                            <MenuItem value={6}>6 seconds</MenuItem>
                            <MenuItem value={9}>9 seconds</MenuItem>
                        </Select>
                    </Box>

                    <Box display="flex" justifyContent={"space-between"}>
                        <Name>Unresolve Queries Telegram Notification</Name>
                        <Switch
                            disabled={currentFlow.id ? false : true}
                            onChange={handleUnresolveTelegramNotify}
                            checked={
                                currentFlow.sendTelegramNotificationUnresolvedQueries === undefined
                                    ? false
                                    : currentFlow.sendTelegramNotificationUnresolvedQueries
                            }
                        />
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}
