import { Box, Paper, Switch } from "@mui/material";
import { Name } from "../themes/themes";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import saveUserSettings from "../functions/database-functions/users-functions/saveUserSettings";

export default function FloatingToggle({ desktopView, setDesktopView }) {
    const { user } = useContext(AuthContext);

    const handleToggle = async () => {
        const newDesktopView = !desktopView;
        setDesktopView(newDesktopView);
        const settings = user.settings || {};
        await saveUserSettings(user, { ...settings, desktopView: newDesktopView });
    };

    return (
        <Box position={"fixed"} bottom={"10px"} right={"10px"} zIndex={1000} display="flex">
            <Paper sx={{ padding: 1 }}>
                <Box display={"flex"} alignItems={"center"}>
                    <Name>Table view:</Name>
                    <Switch checked={desktopView} onChange={handleToggle} color="button" />
                </Box>
            </Paper>
        </Box>
    );
}
