import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function deleteFollowUp(followUp) {
    try {
        console.log(followUp);
        const docRef = doc(db, "users", followUp.user, "followUps", followUp.id);
        await deleteDoc(docRef);
        console.log("Document successfully deleted!");
    } catch (error) {
        console.log(error);
    }
}
