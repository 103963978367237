import { Box, Typography, Radio, RadioGroup, FormControlLabel, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { toast } from "react-hot-toast";

export default function PollComponent({ message,fromMe }) {
    const theme = useTheme();
    //to set selectedOption when user selected. it should update the message when user select.
    const [selectedOption, setSelectedOption] = useState(null);
    const userSelected = message.selectedOptions ? message.selectedOptions[0].localId : null;

    // console.log("options:", message?.selectedOptions[0]?.localId);


    const question =
        message.pollCreation?.pollCreationMessageV3?.name || message.pollCreation?.pollCreationMessage?.name;

    let options = [];
    if (message.pollCreation?.pollCreationMessageV3?.options) {
        options = message.pollCreation.pollCreationMessageV3.options.map((option, index) => ({
            value: option.optionName,
            label: option.optionName,
            votes: 0,
            votesPercentage: 0,
        }));
    }

    if (message.pollCreation?.pollCreationMessage?.options) {
        options = message.pollCreation.pollCreationMessage.options.map((option, index) => ({
            value: option.optionName,
            label: option.optionName,
            votes: 0,
            votesPercentage: 0,
        }));
    }

    const handleSelectOption = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleClickOption = ()=>{
        if (fromMe) {
            toast.error("Only client can chose poll option.");
            console.log("Only client can chose poll option.");
        }else{
            toast.error("Response by poll selection currently not support.");
            console.log("Response by poll selection currently not support.");
        }
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="250px"
            // bgcolor="#dcf8c6"
            p="16px"
            borderRadius="8px"
            border="1px solid #dcdcdc"
            sx={{ backgroundColor: theme.palette.mode === "light" ? "#dcf8c6" : "#075e54" }}
        >
            <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                <Typography
                    variant="h6"
                    fontWeight="bold"
                    color={theme.palette.mode === "light" ? "textSecondary" : "textPrimary"}
                >
                    {question}
                </Typography>
                <Box display="flex" alignItems="center" mt="4px" width="100%">
                    <CheckCircleIcon style={{ color: "grey", marginRight: "4px", fontSize: "12px" }} />
                    <Typography
                        variant="caption"
                        color={theme.palette.mode === "light" ? "grey" : "textPrimary"}
                    >
                        Select one
                    </Typography>
                </Box>
            </Box>
            <RadioGroup value={selectedOption} onChange={handleSelectOption} style={{ width: "100%" }}>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        value={option.value}
                        control={
                            fromMe ? (
                                <Radio
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon style={{ color: "#25D366" }} />}
                                    disabled
                                />
                            ) : (
                                <Radio
                                    icon={<RadioButtonUncheckedIcon />}
                                    checkedIcon={<CheckCircleIcon style={{ color: "#25D366" }} />}
                                />
                            )
                        }
                        onClick={handleClickOption}
                        label={
                            <Box display="flex" flexDirection="column" width="100%">
                                <Box display="flex" alignItems="center">
                                    <Typography
                                        variant="body2"
                                        color={
                                            theme.palette.mode === "light" ? "textSecondary" : "textPrimary"
                                        }
                                        mr="8px"
                                    >
                                        {option.label}
                                    </Typography>
                                    {/* <Typography variant="body2" color="black" ml="auto">
                                        {option.votes}
                                    </Typography> */}
                                </Box>
                                <Box
                                    width="170px"
                                    bgcolor="#e0e0e0"
                                    borderRadius="4px"
                                    overflow="hidden"
                                    mt="4px"
                                >
                                    <Box
                                        bgcolor={
                                            selectedOption === index || userSelected === index
                                                ? "#25D366"
                                                : "#d3d3d3"
                                        }
                                        width="100%"
                                        height="8px"
                                    />
                                </Box>
                            </Box>
                        }
                        style={{ width: "100%", margin: 0 }}
                    />
                ))}
            </RadioGroup>
            {/* <Typography variant="body2" color="black" alignSelf="flex-end" mt="8px">
                {new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
            </Typography>
            <Box width="100%" textAlign="center" mt="8px">
                <Typography variant="body2" color="black" sx={{ cursor: "pointer" }}>
                    View votes
                </Typography>
            </Box> */}
        </Box>
    );
}
