export default function formatPhoneNumber(phone) {
    // Group number
    if (phone.includes("@g.us")) {
        return phone;
    }

    let p = phone;
    if (!p) return "";
    if (typeof p === "number") p = p.toString();
    let cleanedPhone = p.replace(/[^0-9]/g, "");

    if (cleanedPhone[0] === "0") {
        cleanedPhone = "6" + cleanedPhone;
    }
    return cleanedPhone;
}
