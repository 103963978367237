import cfDate from "./cfDate";

export default function getDay(date) {
    let today = new Date();
    let dateToCompare = new Date(cfDate(date));
    let hours = today.getHours();
    dateToCompare.setHours(hours);
    const diffTime = dateToCompare - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let text;
    let color;
    text = diffDays + " days later";
    color = "blue";
    if (diffDays === 1) {
        text = "Tomorrow";
    }
    if (diffDays === 0) {
        color = "orange";
        text = "Today";
    }
    if (diffDays < 0) {
        color = "red";
        text = Math.abs(diffDays) + " days ago";
    }
    if (diffDays === -1) {
        color = "red";
        text = "Yesterday";
    }

    return { label: text, color: color };
}
