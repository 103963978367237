import React from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import FullDialog from "../../components/FullDialog";
import UploadListingDocument from "../ListingPage/UploadListingDocument";

import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export const UploadListingFilesButton = ({
    handleListingFile,
    handleDeleteImage,
    label = "Upload",
    icon = null,
    currentListing,
    toolTip = ""
}) => {
    const [open, setOpen] = React.useState(false);
    const handleClose = (files) => {
        setOpen(false);
        saveFiles(files);
        // handleListingFile(files);
    };

    const handleClickExistingFile = (files) => {
        setOpen(false);
        console.log('1');
        handleDeleteImage(files);
        console.log('2');
        // setOpen(false);
        // handleListingFile(files);
    };

    const saveFiles = async (files) => {
        if (!currentListing.id) {
            console.error("No currentListing.id provided.");
            return;
        }

        try {
            // Generate new documents for the uploaded files
            const collectionRef = collection(db, "listings", currentListing.id, "files");
            const docRefs = await Promise.all(files.map((file) => addDoc(collectionRef, file)));
            let newImageIds = docRefs.map((docRef) => docRef.id);

            // Check if there are any new image IDs to add
            if (newImageIds.length === 0) {
                console.log("No new files to add.");
                return;
            }

            // Merge new image IDs into the existing listing
            const listingsCollectionRef = doc(db, "listings", currentListing.id);
            const currentListingRef = await getDoc(listingsCollectionRef);

            if (!currentListingRef.exists()) {
                console.error("The listing document does not exist.");
                return;
            }

            let updatedPropertyImg = [...(currentListingRef.data().propertyImg || []), ...newImageIds];
            console.log("new updatedPropertyImg :", updatedPropertyImg);
            await updateDoc(listingsCollectionRef, { propertyImg: updatedPropertyImg });

            // Notify the parent component to refresh the images list
            handleListingFile(files.map((_, index) => ({ id: newImageIds[index] }))); // Adjust based on your actual data structure

            console.log("Files saved successfully.");
        } catch (error) {
            console.error("An error occurred while saving files:", error);
        }
    };

    return (
        <React.Fragment>
            {icon ? (
                <Tooltip title={toolTip}>
                    <IconButton style={{ padding: "0 2px 0 2px" }} onClick={() => setOpen(true)}>
                        {icon}
                    </IconButton>
                </Tooltip>
            ) : (
                <Button variant="contained" onClick={() => setOpen(true)}>
                    {label}
                </Button>
            )}

            <FullDialog
                title={"Upload Files"}
                open={open}
                handleClose={() => setOpen(false)}
                Component={
                    <UploadListingDocument
                        handleFile={handleClose}
                        handleClickExistingFile={handleClickExistingFile}
                        currentListing={currentListing}                        
                    />
                }
            />
        </React.Fragment>
    );
};
