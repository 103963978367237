import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { green } from "@mui/material/colors";
import { collection, deleteDoc, doc, onSnapshot, orderBy, query, updateDoc, where } from "firebase/firestore";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { ChipC, GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import { useInput } from "./../../context-utils/InputContext";
import addForm from "./addForm";
import FullDialog from "./../../components/FullDialog";
import FormSubmissionsPage from "./FormSubmissionsPage";
import { set } from "firebase/database";

export default function CreateFormPage() {
    const [currentForm, setCurrentForm] = useState(null);
    const [currentFormId, setCurrentFormId] = useState(null);
    const [currentFieldName, setCurrentFieldName] = useState(null);
    const [copySuccess, setCopySuccess] = useState("");
    const [openTable, setOpenTable] = useState(false);

    const [forms, setForms] = useState([]);
    const [formName, setFormName] = useState("");

    const { user } = useContext(AuthContext);

    // load data
    useEffect(() => {
        if (!user) return;
        const collectionRef = collection(db, "forms");
        const q = query(collectionRef, where("userId", "==", user.id), orderBy("date", "desc")); // need to create index in firestore
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const forms = mapSnapshot(querySnapshot);
            console.log(forms);
            setForms(forms);
        });
        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (!currentFormId) return;
        const docRef = doc(db, "forms", currentFormId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            const form = { ...doc.data(), id: doc.id };
            setCurrentForm(form);
        });
        return unsubscribe;
    }, [currentFormId]);

    const handleClickAdd = async () => {
        const form = {
            name: formName,
            userId: user.id,
            date: new Date(),
            fields: [
                { name: "name", type: "text", isRequired: true },
                { name: "phone", type: "text", isRequired: true },
                { name: "email", type: "text", isRequired: true }
            ]
        };
        const id = await addForm(form);
        setCurrentFormId(id);
        setFormName("");
    };

    const handleClickForm = (form) => {
        setCurrentFieldName(null);
        setCurrentFormId(form.id);
    };

    const confirmation = useConfirmation();

    const handleDelete = async (form) => {
        const result = await confirmation("Are you sure you want to delete this form?");
        if (result) {
            const docRef = doc(db, "forms", form.id);
            await deleteDoc(docRef);
            toast.success("Form deleted");
            console.log("Document deleted");
        }
    };

    const copyToClipboard = () => {
        const url = `https://djcsystem.com/form/${currentFormId}`;
        navigator.clipboard.writeText(url).then(
            () => {
                setCopySuccess("Copied!");
                setTimeout(() => setCopySuccess(""), 3000); // Reset message after 3 seconds
            },
            () => {
                setCopySuccess("Failed to copy");
            }
        );
    };

    const input = useInput();

    const handleEditFormName = async (form) => {
        const response = await input("Edit name", "Enter the new name", "name", form?.name || "");
        const existingNames = forms.map((form) => form.name);
        if (existingNames.includes(response)) {
            toast.error("Form name already exists");
            return;
        }
        if (response) {
            const docRef = doc(db, "forms", form.id);
            await updateDoc(docRef, { name: response });
            toast.success("Form name updated");
        }
    };

    const handleChangeSendEmail = async (event) => {
        const docRef = doc(db, "forms", currentFormId);
        await updateDoc(docRef, { sendEmail: event.target.checked });
    };

    const handleChangeSendWhatsapp = async (event) => {
        const docRef = doc(db, "forms", currentFormId);
        await updateDoc(docRef, { sendWhatsapp: event.target.checked });
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Forms</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
            <Grid container display="flex" spacing={1}>
                {/* Column 1 */}
                <Grid item xs={12} md={4}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ height: "50vh", width: "100%", padding: "8px" }}>
                                <GridContainer>
                                    <GridFlexBox>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            value={formName}
                                            onChange={(e) => setFormName(e.target.value)}
                                            placeholder="Enter form name"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClickAdd}>
                                                            <AddIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </GridFlexBox>
                                    <GridFlexBox>
                                        <Paper
                                            sx={{ width: "100%", height: "30vh", overflowY: "auto" }}
                                            variant="outlined"
                                        >
                                            <List sx={{ width: "100%", padding: "0" }}>
                                                {forms?.map((form) => (
                                                    <React.Fragment key={form.id}>
                                                        <ListItem
                                                            disablePadding
                                                            secondaryAction={
                                                                <Box display={"flex"}>
                                                                    <ChipC
                                                                        color={form.submissionsCount ? "blue" : "grey"}
                                                                        onClick={() => {
                                                                            setCurrentFormId(form.id);
                                                                            setOpenTable(true);
                                                                        }}
                                                                    >
                                                                        {form.submissionsCount || 0}
                                                                    </ChipC>
                                                                    <IconButton
                                                                        size="small"
                                                                        edge="end"
                                                                        onClick={() => handleEditFormName(form)}
                                                                    >
                                                                        <EditIcon fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        size="small"
                                                                        edge="end"
                                                                        onClick={() => handleDelete(form)}
                                                                    >
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Box>
                                                            }
                                                        >
                                                            <ListItemButton
                                                                dense
                                                                onClick={() => handleClickForm(form)}
                                                                selected={currentForm?.id === form.id}
                                                            >
                                                                <ListItemText
                                                                    primary={
                                                                        <Name bold={currentForm?.id === form.id}>
                                                                            {form.name}
                                                                        </Name>
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    </React.Fragment>
                                                ))}
                                            </List>
                                        </Paper>
                                    </GridFlexBox>
                                </GridContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Column 2 */}
                <Grid item xs={12} md={4}>
                    <Grid container display="flex" spacing={1}>
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ width: "100%", padding: "8px" }}>
                                <Form
                                    form={currentForm}
                                    currentFieldName={currentFieldName}
                                    setCurrentFieldName={setCurrentFieldName}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Column 3 */}
                <Grid item xs={12} md={4}>
                    <Grid container display="flex" spacing={1}>
                        {currentForm && (
                            <Grid item display="flex" xs={12} flexDirection="column">
                                <Paper sx={{ width: "100%", padding: "8px" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={currentForm?.sendEmail || false}
                                                onChange={handleChangeSendEmail}
                                                name="sendEmail"
                                            />
                                        }
                                        label="Send Email"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={currentForm?.sendWhatsapp || false}
                                                onChange={handleChangeSendWhatsapp}
                                                name="sendWhatsapp"
                                            />
                                        }
                                        label="Send Whatsapp"
                                    />
                                </Paper>
                            </Grid>
                        )}

                        {currentForm && currentFieldName && (
                            <Grid item display="flex" xs={12} flexDirection="column">
                                <Paper sx={{ width: "100%", padding: "8px" }}>
                                    <FieldSettings form={currentForm} fieldName={currentFieldName} />
                                </Paper>
                            </Grid>
                        )}
                        <Grid item display="flex" xs={12} flexDirection="column">
                            <Paper sx={{ height: "10vh", width: "100%", padding: "8px" }}>
                                {currentFormId && (
                                    <>
                                        <Name>Get Form Link:</Name>
                                        <Typography variant="caption">{`https://djcsystem.com/form/${currentFormId}`}</Typography>
                                        <Box display="flex">
                                            <Button
                                                size="small"
                                                startIcon={<FileCopyOutlinedIcon />}
                                                onClick={copyToClipboard}
                                            >
                                                Copy
                                            </Button>
                                            {copySuccess && <Name color={green[500]}>{copySuccess}</Name>}
                                        </Box>
                                    </>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <FullDialog
                open={openTable}
                handleClose={() => setOpenTable(false)}
                title={currentForm?.name || "Submissions"}
                Component={<FormSubmissionsPage form={currentForm} />}
            />
        </Container>
    );
}

const Form = ({ form, currentFieldName, setCurrentFieldName }) => {
    const [fieldName, setFieldName] = useState("");
    const [description, setDescription] = useState("");
    const [thankyouMessage, setThankyouMessage] = useState("");

    const inputRef = useRef(null);

    useEffect(() => {
        if (!form) return;
        setDescription(form.description || "");
        setThankyouMessage(form.thankyouMessage || "");
    }, [form]);

    const handleClickAdd = () => {
        const docRef = doc(db, "forms", form.id);
        const newForm = { ...form };
        if (!newForm.fields) newForm.fields = [];
        if (newForm.fields.some((field) => fieldName.trim() === field.name)) {
            toast.error("Field name already exists");
            return;
        }
        const newFields = [...newForm.fields, { name: fieldName.trim(), type: "text" }];
        updateDoc(docRef, { fields: newFields });
        setFieldName("");
    };

    const handleDeleteField = (fieldName) => {
        const docRef = doc(db, "forms", form.id);
        const newForm = { ...form };
        if (!newForm.fields) newForm.fields = [];
        const newFields = newForm.fields.filter((f) => f.name !== fieldName);
        updateDoc(docRef, { fields: newFields });
    };

    const handleUp = (field) => {
        const docRef = doc(db, "forms", form.id);
        const newForm = { ...form };
        if (!newForm.fields) newForm.fields = [];
        const index = newForm.fields.indexOf(field);
        if (index === 0) return;
        const temp = newForm.fields[index - 1];
        newForm.fields[index - 1] = field;
        newForm.fields[index] = temp;
        updateDoc(docRef, { fields: newForm.fields });
    };

    const handleDown = (field) => {
        const docRef = doc(db, "forms", form.id);
        const newForm = { ...form };
        if (!newForm.fields) newForm.fields = [];
        const index = newForm.fields.indexOf(field);
        if (index === newForm.fields.length - 1) return;
        const temp = newForm.fields[index + 1];
        newForm.fields[index + 1] = field;
        newForm.fields[index] = temp;
        updateDoc(docRef, { fields: newForm.fields });
    };

    const handleSaveDescription = () => {
        const docRef = doc(db, "forms", form.id);
        let message = description;

        // Replace all occurrences of "\n" with "  \n"
        message = message.replace(/\n/g, "  \n");
        updateDoc(docRef, { description: message });
    };

    const handleSaveThankyouMessage = () => {
        const docRef = doc(db, "forms", form.id);

        let message = thankyouMessage;

        // Replace all occurrences of "\n" with "  \n"
        message = message.replace(/\n/g, "  \n");
        updateDoc(docRef, { thankyouMessage: message });
    };

    const handleClickName = () => {
        const input = inputRef.current;
        const startPos = input.selectionStart;
        const endPos = input.selectionEnd;

        setThankyouMessage(thankyouMessage.slice(0, startPos) + `{name}` + thankyouMessage.slice(endPos));
        input.focus();
    };

    return (
        <GridContainer>
            {form && (
                <>
                    <GridFlexBox>
                        <Name>{form?.name}</Name>
                    </GridFlexBox>
                    <GridDivider />
                    <GridFlexBox>
                        <TextField
                            label="Description"
                            fullWidth
                            size="small"
                            placeholder="Enter form description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            multiline
                            rows={7}
                            onBlur={handleSaveDescription}
                        />
                    </GridFlexBox>
                    <GridFlexBox fs>
                        <IconButton onClick={handleClickName}>
                            <Name>{`{name}`}</Name>
                        </IconButton>
                    </GridFlexBox>

                    <GridFlexBox>
                        <TextField
                            inputRef={inputRef}
                            label="Thank you message"
                            fullWidth
                            size="small"
                            value={thankyouMessage}
                            onChange={(e) => setThankyouMessage(e.target.value)}
                            multiline
                            rows={7}
                            onBlur={handleSaveThankyouMessage}
                        />
                    </GridFlexBox>
                    <GridDivider />
                    <GridFlexBox>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Enter field name"
                            value={fieldName}
                            onChange={(e) => setFieldName(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickAdd}>
                                            <AddIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </GridFlexBox>
                    <GridFlexBox>
                        <Paper sx={{ width: "100%", padding: "4px" }} variant="outlined">
                            {form?.fields?.map((field, i) => (
                                <React.Fragment key={field.name}>
                                    <GridContainer>
                                        <GridFlexBox fs xs={9}>
                                            <Name
                                                cp
                                                onClick={() => setCurrentFieldName(field.name)}
                                                bold={currentFieldName === field.name}
                                            >
                                                {field.name}
                                            </Name>
                                        </GridFlexBox>
                                        <GridFlexBox xs={1}>
                                            <IconButton
                                                onClick={() => handleUp(field)}
                                                size={"small"}
                                                disabled={i === 0}
                                            >
                                                <ArrowUpwardIcon />
                                            </IconButton>
                                        </GridFlexBox>
                                        <GridFlexBox xs={1}>
                                            <IconButton
                                                onClick={() => handleDown(field)}
                                                size={"small"}
                                                disabled={i === form?.fields?.length - 1}
                                            >
                                                <ArrowDownwardIcon />
                                            </IconButton>
                                        </GridFlexBox>
                                        <GridFlexBox xs={1}>
                                            <IconButton onClick={() => handleDeleteField(field)} size={"small"}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </GridFlexBox>
                                    </GridContainer>
                                </React.Fragment>
                            ))}
                        </Paper>
                    </GridFlexBox>
                </>
            )}
        </GridContainer>
    );
};

const FieldSettings = ({ form, fieldName }) => {
    const index = useMemo(() => {
        const newForm = { ...form };
        if (!newForm.fields) newForm.fields = [];
        const index = newForm.fields.findIndex((f) => f.name === fieldName);
        return index;
    }, [form, fieldName]);

    const handleChange = (event) => {
        const newForm = { ...form };
        if (!newForm.fields) newForm.fields = [];
        newForm.fields[index].type = event.target.value;
        const docRef = doc(db, "forms", form.id);
        updateDoc(docRef, { fields: newForm.fields });
    };

    const handleCheckboxChange = (event) => {
        const newForm = { ...form };
        if (!newForm.fields) newForm.fields = [];
        newForm.fields[index].isRequired = event.target.checked;
        const docRef = doc(db, "forms", form.id);
        updateDoc(docRef, { fields: newForm.fields });
    };

    return (
        <GridContainer>
            <GridFlexBox>
                <Name>{fieldName}</Name>
            </GridFlexBox>
            <GridDivider />
            <GridFlexBox>
                <FormControl fullWidth>
                    <InputLabel>Field Type</InputLabel>
                    <Select
                        value={form?.fields?.[index]?.type || "text"}
                        label="Field Type"
                        onChange={handleChange}
                        fullWidth
                        size="small"
                    >
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="dropdown">Dropdown</MenuItem>
                        <MenuItem value="longText">Long Text</MenuItem>
                    </Select>
                </FormControl>
            </GridFlexBox>
            <GridFlexBox fs>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={form?.fields?.[index]?.isRequired || false}
                            onChange={handleCheckboxChange}
                            name="required"
                        />
                    }
                    label="Required"
                />
            </GridFlexBox>
        </GridContainer>
    );
};
