import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

export default async function deleteAppointment(user, appointment) {
    try {
        const docRef = doc(db, "users", user.id, "appointments", appointment.id);
        await deleteDoc(docRef);
        console.log("Appointment deleted from Firestore");
        const reminderRef = doc(db, "reminders", appointment.reminderId);
        await deleteDoc(reminderRef);
        console.log("Reminder deleted from Firestore: ", appointment.reminderId);
    } catch (error) {
        console.log(error);
    }
}
