import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Divider, Icon, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect } from "react";
import getThumbnail from "../UploadFile/getThumbnail";
import isFileImage from "../UploadFile/isFileImage";

export default function ImageComponent({ image, width = "150px", handleDelete, i }) {
    const [imageThumbnailURL, setImageThumbnailURL] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClickMore = (event) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (e) => {
        setAnchorEl(null);
        e.stopPropagation();
    };

    useEffect(() => {
        const loadThumbnail = async () => {
            if (isFileImage(image)) {
                const thumbnailURL = await getThumbnail(image);
                setImageThumbnailURL(thumbnailURL);
            }
        };
        loadThumbnail();
    }, [image]);

    const handleOpen = () => {
        window.open(image.downloadURL, "_blank");
    };

    return (
        <Box display="flex" alignItems={"center"}>
            {!isFileImage(image) ? (
                <Icon sx={{ fontSize: "45px" }}>article</Icon>
            ) : (
                <img src={imageThumbnailURL || image.downloadURL} alt="file" width={width} />
            )}
            {handleDelete && (
                <Box>
                    <IconButton onClick={handleClickMore}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button"
                        }}
                    >
                        <MenuItem
                            onClick={(e) => {
                                handleClose(e);
                                handleOpen();
                            }}
                        >
                            <OpenInNewIcon sx={{ marginRight: "8px" }} />
                            <Typography>Open</Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={(e) => {
                                handleClose(e);
                                handleDelete(i);
                            }}
                        >
                            <DeleteIcon color="error" sx={{ marginRight: "8px" }} />
                            <Typography>Delete</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
            )}
        </Box>
    );
}
