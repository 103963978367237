import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

const checkAppointmentAvailability = async (formattedProposedDateTime, email, contact) => {
    let appPaddingTimeBefore = 0;
    let appPaddingTimeAfter = 0;
    // query for appointment padding time
    const offDayRef = collection(db, "calendarOffDays");
    const offDayQ = query(offDayRef, where("createdBy", "==", email));

    const calenderOffDaysSnapShot = await getDocs(offDayQ);

    calenderOffDaysSnapShot.forEach((doc) => {
        console.log(doc);
        // Get the data from each document
        const data = doc.data();
        // Add the document ID to the data object
        appPaddingTimeBefore = data.appPaddingTimeBefore;
        appPaddingTimeAfter = data.appPaddingTimeAfter;
        console.log("appPaddingTimeBefore:", appPaddingTimeBefore);
        console.log("appPaddingTimeAfter:", appPaddingTimeAfter);
    });

    // Calculate buffer times as Firestore Timestamps
    const startTimeBeforeBuffer = Timestamp.fromDate(
        new Date(formattedProposedDateTime.getTime() - appPaddingTimeBefore * 60 * 1000)
    ); // 1 hour before proposed time
    const endTimeAfterBuffer = Timestamp.fromDate(
        new Date(formattedProposedDateTime.getTime() + appPaddingTimeAfter * 60 * 1000)
    ); // 1 hour after proposed time

    console.log("Start Time Buffer:", startTimeBeforeBuffer.toDate().toString());
    console.log("End Time Buffer:", endTimeAfterBuffer.toDate().toString());

    const appointmentsRef = collection(db, "appointments");
    const q = query(
        appointmentsRef,
        where("owner", "==", email),
        // where("appDateTime", ">=", startTimeBeforeBuffer),
        // where("appDateTime", "<=", endTimeAfterBuffer),
        where("startTime", "<", endTimeAfterBuffer),
        where("endTime", ">", startTimeBeforeBuffer)
    );

    const querySnapshot = await getDocs(q);
    console.log("querySnapshot.size:", querySnapshot.size);

    // // Filter the results in application code to exclude the unwanted custContact
    // const filteredAppointments = querySnapshot.docs
    //     .map((doc) => ({ id: doc.id, ...doc.data() }))
    //     .filter((appointment) => appointment.custContact !== contact);

    if (querySnapshot.size > 0) {
        console.log("querySnapshot.size:", querySnapshot.size);
        // Get the first conflicting appointment's time
        const firstDoc = querySnapshot.docs[0];
        const conflictingAppointment = firstDoc.data();
        console.log("First Document Data:", conflictingAppointment);
        const appointmentTime = conflictingAppointment.appDateTime;
        // Format the time to AM/PM
        const formattedTime = appointmentTime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });

        return { status: true, time: formatTimestampToTimeString(formattedTime) };
    } else {
        return {
            status: false,
            startTime: formattedProposedDateTime.getTime() - appPaddingTimeBefore * 60 * 1000,
            endTime: formattedProposedDateTime.getTime() + appPaddingTimeAfter * 60 * 1000,
        };
    }
    // Return false if no conflicting appointments (time slot is available), true otherwise
};

function formatTimestampToTimeString(timestamp) {
    const date = new Date(timestamp.seconds * 1000);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const timeString = minutes === 0 ? `${hours}${ampm}` : `${hours}.${minutesStr}${ampm}`;
    return timeString;
}

export default checkAppointmentAvailability;
