import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateDisableTelegramNotify(user, currentFlow, checked) {
    try {
        const docRef = doc(db, "users", user.id, "whatsappFlows", currentFlow.id);
        updateDoc(docRef, { sendTelegramNotificationUnresolvedQueries: checked });
    } catch (err) {
        console.log(err);
    }
}
