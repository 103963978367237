function convertToEmbedUrl(url) {
    // Check if the URL is already an embed URL
    if (url.includes("youtube.com/embed/")) {
        return url; // It's already an embed URL, return as is
    }

    // Extract the video ID from a standard YouTube URL or a shortened youtu.be URL
    let videoId = null;
    if (url.includes("youtube.com/watch?v=")) {
        videoId = url.split("youtube.com/watch?v=")[1].split("&")[0];
    } else if (url.includes("youtu.be/")) {
        videoId = url.split("youtu.be/")[1].split("?")[0];
    }

    // If a video ID was found, construct and return the embed URL
    if (videoId) {
        return `https://www.youtube.com/embed/${videoId}`;
    }

    // If the URL doesn't match any known YouTube URL format, return the original URL
    return url;
}

export default convertToEmbedUrl;
