import { Grid, IconButton } from "@mui/material";
import { collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import React from "react";
import { db } from "../../firebase/firebase-utils";
import { GridDivider, IconC, Name } from "../../themes/themes";
import { useConfirmation } from "./../../context-utils/ConfirmationContext";
import formatDate from "./../../functions/common-functions/formatDate";

export default function MemberDetails({ user, checkUser }) {
    const confirmation = useConfirmation();

    const handleDeleteUser = async (user) => {
        const docRef = doc(db, "users", user.id);
        const response = await confirmation("Delete user", "Confirm?");
        if (response) {
            const deletedDocRef = doc(db, "deletedUsers", user.id);
            await setDoc(deletedDocRef, user, { merge: true });
            await deleteDoc(docRef);
            console.log(`${user.displayName} deleted`);
        }
    };

    return (
        <React.Fragment>
            <Grid container display="flex" spacing={1}>
                <Grid item display="flex" xs={12} justifyContent={"flex-start"}>
                    <IconC>calendar_today</IconC>
                    <Name ml1>{formatDate(user.date)}</Name>
                </Grid>
                <Grid item display="flex" xs={11} justifyContent={"flex-start"}>
                    <IconC
                        onClick={() => {
                            checkUser(user);
                        }}
                    />
                    <Name ml1>{`${user.displayName}`}</Name>
                </Grid>
                <Grid item xs={1} display="flex" justifyContent={"flex-end"}>
                    {user.role === "Super Admin" && (
                        <IconButton onClick={() => handleDeleteUser(user)} size="small">
                            <IconC>delete</IconC>
                        </IconButton>
                    )}
                </Grid>
                <Grid item display="flex" xs={12} justifyContent={"flex-start"}>
                    <IconC>email</IconC>
                    <Name ml1>{user.email}</Name>
                </Grid>
                <Grid item display="flex" xs={12} justifyContent={"flex-start"}>
                    <IconC>phone</IconC>
                    <Name ml1>{user.phone}</Name>
                </Grid>
                <Grid item display="flex" xs={12} justifyContent={"flex-start"}>
                    <IconC>telegram</IconC>
                    <Name ml1>{user.chatId ? `Telegram ${user.chatId}` : ""}</Name>
                </Grid>
                <Grid item display="flex" xs={12} justifyContent={"flex-start"}>
                    <IconC>account_tree</IconC>
                    <Name nowrap={false} ml1>
                        {user.addOn || "none"}
                    </Name>
                </Grid>
                <Grid item display="flex" xs={12} justifyContent={"flex-start"}>
                    <IconC>man4</IconC>
                    <Name nowrap={false} ml1>
                        Referral: {user.referral || ""}
                    </Name>
                </Grid>
                <Grid item display="flex" xs={12} justifyContent={"flex-start"}>
                    <IconC>fingerprint</IconC>
                    <Name nowrap={false} ml1>
                        UID: {user.uid || ""}
                    </Name>
                </Grid>
                <GridDivider />
            </Grid>
        </React.Fragment>
    );
}
