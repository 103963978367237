export const convertStringToDate = (stringDate) => {
    // Match patterns for dd/mm/yyyy and dd-mm-yyyy
    const regex = /^\d{1,2}[\/-]\d{1,2}[\/-]\d{4}$/;
    if (regex.test(stringDate)) {
        let [day, month, year] = stringDate.split(/\/|-/);
        day = day.padStart(2, "0");
        month = month.padStart(2, "0");
        // Create a date string in ISO format: yyyy-mm-dd
        const isoDateString = `${year}-${month}-${day}`;
        const dateObj = new Date(isoDateString);
        // Validate the date conversion and handle errors
        if (isNaN(dateObj.getTime())) {
            console.error("Invalid date format provided.");
            return null;
        }
        return dateObj;
    }
    return null; // Return null for non-matching formats
};

export default function convertDate(dateInput) {
    if (dateInput instanceof Date) {
        return dateInput; // Already a Date object
    }
    if (typeof dateInput === "object" && dateInput !== null && "seconds" in dateInput) {
        // Convert timestamp from a Firestore-like object
        return new Date(dateInput.seconds * 1000);
    }
    if (typeof dateInput === "number") {
        // Convert Unix timestamp
        return new Date(dateInput * 1000);
    }
    if (typeof dateInput === "string") {
        // Attempt to convert a date string
        const convertedDate = convertStringToDate(dateInput);
        return convertedDate ? convertedDate : new Date(dateInput); // Fallback to parsing directly
    }
    console.error("Invalid date input type.");
    return null; // Return null for unsupported types
}
