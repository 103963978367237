import { useTheme } from "@emotion/react";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Box, Fab } from "@mui/material";
import { orange } from "@mui/material/colors";
import React from "react";
import { tokens } from "../theme";
import AddIcon from '@mui/icons-material/Add';

export function FloatingButton({ toggleDrawer, folder, setOpenQuickAddLeadCard = () => { } }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    function isFunctionEmpty(func) {
        const content = func.toString();
        const body = content.substring(content.indexOf('{') + 1, content.lastIndexOf('}')).trim();
        return body.length === 0;
    }

    return (
        <Box position={"fixed"} bottom={"10px"} left={"10px"} zIndex={1000} display="flex" sx={{ flexDirection: "column-reverse", alignItems: "center" }}>
            <Fab
                color="folderButton"
                sx={{ color: colors.primary[100], ":hover": { bgcolor: orange[800], color: "white" } }}
                onClick={toggleDrawer(true)}
            >
                <FolderOpenIcon />
            </Fab>
            {folder && (
                <Fab
                    sx={{
                        backgroundColor: colors.blueAccent[500],
                        color: colors.primary[100],
                        ":hover": { bgcolor: colors.blueAccent[600], color: "white" }
                    }}
                    onClick={toggleDrawer(true)}
                >
                    {folder.folderName}
                </Fab>
            )}

            {/* This is the quick add lead button */}
            {
                !isFunctionEmpty(setOpenQuickAddLeadCard) &&
                <Fab
                    color="primary"
                    aria-label="add"
                    sx={{ margin: "5% 0" }}
                    onClick={() => setOpenQuickAddLeadCard(true)}
                >
                    <AddIcon />
                </Fab>
            }

        </Box>
    );
}
