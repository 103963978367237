import { Box, Button, Container, IconButton, TextField } from "@mui/material";
import { ChipC, GridContainer, GridDivider, GridFlexBox, Loading, Name, TextFieldDate, Title } from "../themes/themes";
import React, { useContext, useEffect, useState } from "react";
import formatDate from "../utils-functions/formatDate";
import { AuthContext } from "../context/AuthContext";
import { StoreContext } from "../context/StoreContext";
import loadBookedSales from "../functions/loadBookedSales";
import getDay from "../utils-functions/getDay";
import convertDate from "../utils-functions/convertDate";

import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import addBookedSale from "../functions/addBookedSale";
import { addAction } from "../functions/addAction";
import moment from "moment";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";

const DATE = formatDate(new Date(), true);
const BookedSale = ({
    lead,
    handleClose
}) => {
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(DATE);
    const [state, setState] = useState();
    const [edit, setEdit] = useState(false);

    const { bookedSales, setBookedSales, addUnsubscribeStore } = useContext(StoreContext);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        let unsubscribe = () => { };
        if (!bookedSales) {
            console.log("loadBookSale");
            unsubscribe = loadBookedSales(user, setBookedSales);
            addUnsubscribeStore(unsubscribe);
        }
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("BookedSale date: ", date);
        console.log("BookedSale state: ", state);

        setLoading(true);
        const localDate = date.split("T")[0];
        const time = date.split("T")[1];
        const { projectName, unitNo, remark } = state;
        const action = "Booked Sale";
        const remarkDisplay =
            "Date: " +
            moment(localDate).format("YYYY-MM-DD") +
            " | Time: " +
            time +
            " | Project Name: " +
            projectName +
            " | Unit Number: " +
            unitNo +
            " | Remark: " +
            remark;

        const bookedSale = {
            date: new Date(date),
            projectName: projectName,
            unitNo: unitNo,
            remark: remark,
            leadId: lead.id,
            clientName: lead.name,
            clientEmail: lead.email,
            clientPhone: lead.phone,
            leadOwner: lead.ownerEmail,
            user: user.id,
            created: new Date(),
            updated: new Date()
        };

        //Insert the bookedSale so that it will add a field of bookedSale inside the lead
        await addAction(lead, action, remarkDisplay, user, null, null, { ...bookedSale });

        //Add bookedSale to user's bookedSales subcollection
        await addBookedSale(user, bookedSale);

        setLoading(false);
        handleClose(bookedSale);
    }

    const handleDelete = async (bookedSale) => {
        try {
            const docRef = doc(db, "users", user.id, "bookedSales", bookedSale.id);
            await deleteDoc(docRef);
            console.log("Booked sale deleted from Firestore");
        } catch (error) {
            console.log(error);
        }
    };

    const displayDate = (date) => {
        const display = formatDate(convertDate(date), true);
        const dateOnly = display.split("T")[0];
        const timeOnly = display.split("T")[1];
        return `${dateOnly} | ${timeOnly}`;
    };

    return (
        <Container maxWidth="md" disableGutters>
            <GridContainer pl1 pr1>
                <GridFlexBox>
                    <Title>Booked Sale</Title>
                </GridFlexBox>

                <GridDivider />
                <Loading loading={loading} />
                <GridDivider />

                {lead && (
                    <>
                        <GridFlexBox>
                            <Name ct bold>
                                {lead?.name}
                            </Name>
                        </GridFlexBox>
                        <GridDivider />
                    </>
                )}

                <GridFlexBox>
                    <TextFieldDate onChange={(e) => setDate(e.target.value)} value={date} />
                </GridFlexBox>
                <GridFlexBox>
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="projectName"
                                label="Project Name"
                                onChange={handleChange}
                                required
                            />
                        </Box>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                required
                                variant="outlined"
                                name="unitNo"
                                label="Unit Number"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box my={1}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                name="remark"
                                label="Remark"
                                onChange={handleChange}
                            />
                        </Box>
                        <Box my={1}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<AddIcon />}
                                type="submit"
                                disabled={loading}
                            >
                                Add
                            </Button>
                        </Box>
                    </form>
                </GridFlexBox>

                <GridDivider />
                <GridFlexBox xs={10} justifyContent="flex-start">
                    <Name>Recorded Booked Sales</Name>
                </GridFlexBox>
                <GridFlexBox xs={2} fe>
                    <IconButton onClick={() => setEdit((state) => !state)}>
                        {edit ? <HighlightOffIcon /> : <EditIcon />}
                    </IconButton>
                </GridFlexBox>
                <GridDivider />
                {bookedSales?.map((bookedSale) => (
                    <React.Fragment key={bookedSale.id}>
                        <GridFlexBox fs xs={6}>
                            <Name ct bold>
                                Client name: {bookedSale.clientName}
                            </Name>
                        </GridFlexBox>

                        <GridFlexBox fe xs={6} gap={1}>
                            <ChipC color={getDay(bookedSale.date).color}>{getDay(bookedSale.date).label}</ChipC>
                            <Name ct>{displayDate(bookedSale.date)}</Name>
                            {edit && (
                                <IconButton style={{ padding: "0 0 0 8px" }} onClick={() => handleDelete(bookedSale)}>
                                    <DeleteIcon color="warning" />
                                </IconButton>
                            )}
                        </GridFlexBox>
                        <GridFlexBox fs xs={12}>
                            <Name ct>Project name: {bookedSale.projectName}</Name>
                        </GridFlexBox>
                        <GridDivider />
                    </React.Fragment>
                ))}

            </GridContainer>
        </Container>
    );
}

export default BookedSale;