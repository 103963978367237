import axios from "axios";

export default async function claude(prompt, setMessages, messages, model, system, user) {
    console.log("Model:", model);

    if (!system) {
        system = `You are a helpful assistant.Take your time to understand user requirement and reply accordingly. accuracy is the most important key point in your response.`;
    }

    const response = await fetchData(model, system, messages, prompt, user.uid);
    setMessages((prevMessages) => [
        ...prevMessages,
        {
            role: "assistant",
            content: response
        }
    ]);

    console.log("Messages to send:", messages);
}

async function fetchData(model, system, msgs, prompt, uid) {
    let data = JSON.stringify({
        chatHistory: msgs,
        prompt: prompt,
        system: system,
        model: model,
        uid: uid
    });

    let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://asia-east2-facebook-api-59e5c.cloudfunctions.net/claudeAPI",
        headers: {
            "Content-Type": "application/json"
        },
        data: data
    };

    try {
        const response = await axios.request(config);
        console.log(JSON.stringify(response.data));
        return response.data;
    } catch (error) {
        console.log(error);
    }
}
