import {
    Backdrop,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    MenuItem,
    Pagination,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography
} from "@mui/material";
import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    updateDoc,
    where,
    arrayRemove,
    onSnapshot,
    writeBatch,
    arrayUnion,
    setDoc,
    getDoc
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import convertToEmbedUrl from "./checkEmbeddedLink";
import AddIcon from "@mui/icons-material/Add";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { useGetFriends } from "../../context/StoreContext";
import { InputContext } from "../../context-utils/InputContext";
import { ListContext } from "../../context-utils/ListContext";

export default function TutorialsPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [openTutorialGroup, setOpenTutorialGroup] = useState(false);
    const [openAddTutorialGroup, setOpenAddTutorialGroup] = useState(false);
    const [openAddCategory, setOpenAddCategory] = useState(false);
    const [tutorials, setTutorials] = useState([]);
    const [tutorialGroups, setTutorialGroups] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [newCategory, setNewCategory] = useState("");
    const [refresh, setRefresh] = useState(false);

    const [userTutorialGroups, setUserTutorialGroups] = useState({
        user: "",
        tutorialGroups: []
    });
    const [newTutorialGroup, setNewTutorialGroup] = useState({
        groupName: "",
        groupDescription: ""
    });
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        link: "",
        thumbnail: "",
        tutorialGroups: [],
        category: "",
        language: "english",
        access: "private"
    });
    const [tutorialGroupsFormData, setTutorialGroupsFormData] = useState({
        groupName: "",
        groupDesc: "",
        createdBy: "",
        date: new Date(),
        admins: []
    });
    const [videoDialogOpen, setVideoDialogOpen] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState("");
    const [isEditing, setIsEditing] = useState(false); // New state to track editing mode
    const [isEditingTutorialGroups, setIsEditingTutorialGroups] = useState(false); // New state to track editing mode
    const [selectedGroup, setSelectedGroup] = useState(null);
    const { user } = useContext(AuthContext);
    const [categoryMode, setCategoryMode] = useState("select"); // 'select' or 'input'
    const [availableCategories, setAvailableCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]); // For managing multiple selected categories
    const [filteredTutorials, setFilteredTutorials] = useState([]);
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [editedGroupName, setEditedGroupName] = useState("");
    const [editedGroupDescription, setEditedGroupDescription] = useState("");
    const [openUserGroup, setOpenUserGroup] = useState(false);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [openCategory, setOpenCategory] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categories, setCategories] = useState([]);
    const itemsPerPage = 24;

    const handleCloseBackDrop = () => {
        setOpenBackDrop(false);
    };
    const handleOpenBackDrop = () => {
        setOpenBackDrop(true);
    };

    const handleSelectLanguage = (event) => {
        const { name, checked } = event.target;
        setSelectedLanguages((prev) => (checked ? [...prev, name] : prev.filter((l) => l !== name)));
    };

    const updateNameDetails = async () => {
        if (!selectedGroup?.id) return;

        // Assuming 'db' is your Firestore instance and 'selectedGroup.id' is the ID of the document
        const groupRef = doc(db, "tutorialGroups", selectedGroup.id);
        await updateDoc(groupRef, {
            groupName: editedGroupName || selectedGroup.groupName, // Fallback to original if no edit
            updatedDate: new Date()
        });

        setIsEditingName(false);
    };

    const updateGroupDescDetails = async () => {
        if (!selectedGroup?.id) return;

        // Assuming 'db' is your Firestore instance and 'selectedGroup.id' is the ID of the document
        const groupRef = doc(db, "tutorialGroups", selectedGroup.id);
        await updateDoc(groupRef, {
            groupDescription: editedGroupDescription || selectedGroup.groupDescription,
            updatedDate: new Date() // Fallback to original
        });

        setIsEditingDescription(false);
    };

    const handleSelectGroup = (group) => {
        setSelectedGroup(group);
    };

    const handleSelectCategory = (event) => {
        const categoryId = event.target.name;
        setSelectedCategories((prev) =>
            prev.includes(categoryId) ? prev.filter((id) => id !== categoryId) : [...prev, categoryId]
        );
    };

    const handleClickAddTutorial = () => {
        setOpen(true);
        setIsEditing(false);
        setFormData({
            title: "",
            description: "",
            link: "",
            thumbnail: "",
            tutorialGroups: [],
            category: "",
            access: "private",
            language: "english"
        }); // Reset form data
    };

    const handleCloseTutorialGroup = () => {
        setOpenTutorialGroup(false);
    };

    const handleCloseAddTutorialGroup = () => {
        setOpenAddTutorialGroup(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleChangeTutorialGroup = (event) => {
        const { name, value } = event.target;
        setTutorialGroupsFormData({ ...tutorialGroupsFormData, [name]: value });
    };

    const handleSubmitTutorialGroup = async () => {
        let updatedTutorialGroups = [...tutorialGroups];
        if (isEditingTutorialGroups) {
            // Update the tutorial group
            const tutorialGroupRef = doc(db, "tutorialGroups", tutorialGroupsFormData.id);
            const updatedTutorialGroup = { ...tutorialGroupsFormData, updatedDate: new Date(), admins: user.id };
            await updateDoc(tutorialGroupRef, updatedTutorialGroup);
            toast.success("Tutorial group updated");

            // Update the tutorial group in the local state
            updatedTutorialGroups = tutorialGroups.map((tutorialGroup) =>
                tutorialGroup.id === tutorialGroupsFormData.id
                    ? { ...updatedTutorialGroup, id: tutorialGroupsFormData.id }
                    : tutorialGroup
            );
        } else {
            // Add a new tutorial group
            const collectionRef = collection(db, "tutorialGroups");
            const tutorialGroups = {
                ...tutorialGroupsFormData,
                date: new Date(),
                updatedDate: new Date(),
                createdBy: user.id
            };
            const docRef = await addDoc(collectionRef, tutorialGroups);
            toast.success("Tutorial added");

            // Add the new tutorial group to the local state
            updatedTutorialGroups.push({ ...tutorialGroupsFormData, id: docRef.id });
        }

        // setTutorialGroups(updatedTutorialGroups);
        setOpenTutorialGroup(false);
        setTutorialGroupsFormData({ name: "", description: "", admins: [] });
        setIsEditingTutorialGroups(false);
    };

    const handleCloseAddCategory = () => {
        setOpenAddCategory(false);
    };

    const handleAddTutorial = async () => {
        console.log("formData:", formData);

        let updatedTutorials = [...tutorials];
        if (isEditing) {
            // Update the tutorial
            console.log("formData:", formData);
            const tutorialRef = doc(db, "tutorials", formData.id);
            const updatedTutorial = { ...formData, updatedDate: new Date(), createdBy: user.id };
            delete updatedTutorial.id; // Remove the id property before updating
            await updateDoc(tutorialRef, updatedTutorial);
            toast.success("Tutorial updated");

            // Update the tutorial in the local state
            updatedTutorials = tutorials.map((tutorial) =>
                tutorial.id === formData.id ? { ...updatedTutorial, id: formData.id } : tutorial
            );
        } else {
            // Add a new tutorial
            const collectionRef = collection(db, "tutorials");
            const tutorial = { ...formData, date: new Date(), updatedDate: new Date(), createdBy: user.id };
            const docRef = await addDoc(collectionRef, tutorial);
            toast.success("Tutorial added");

            // Add the new tutorial to the local state
            updatedTutorials.push({ ...tutorial, id: docRef.id });
        }

        // setTutorials(updatedTutorials);
        setFormData({
            title: "",
            description: "",
            link: "",
            thumbnail: "",
            tutorialGroups: [],
            category: "",
            language: "english",
            access: "private"
        });
        setOpen(false);
        setIsEditing(false);
    };

    const handleVideoClick = (videoUrl) => {
        setSelectedVideo(videoUrl);
        setVideoDialogOpen(true);
    };

    const handleVideoDialogClose = () => {
        setVideoDialogOpen(false);
    };

    const handleEditClick = (tutorial) => {
        setFormData(tutorial); // Populate the form with the tutorial data
        setOpen(true);
        setIsEditing(true); // Set editing mode to true
    };

    const confirmation = useConfirmation();

    const handleDeleteClick = async (tutorial) => {
        const response = await confirmation("Delete tutorial", "Click ok to delete");
        if (!response) return;

        // const updatedTutorials = tutorials.filter((t) => t.id !== tutorial.id);
        // setTutorials(updatedTutorials);
        const tutorialRef = doc(db, "tutorials", tutorial.id);

        await deleteDoc(tutorialRef);
        setRefresh(!refresh);
        toast.success("Tutorial deleted");
    };

    const handleCategorySelectChange = (event) => {
        setFormData({ ...formData, category: event.target.value });
        if (event.target.value === "New Category") {
            setCategoryMode("input");
        }
    };

    const handleNewTutorialGroupChange = (event) => {
        const { name, value } = event.target;
        setNewTutorialGroup((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleOpenCategoryDialog = () => {
        setOpenCategory(true);
    };

    const handleOpenTutorialGroupDialog = () => {
        setOpenTutorialGroup(true);
    };

    const handleOpenUserDialog = () => {
        setOpenUserGroup(true);
    };

    const handleCloseCategory = () => {
        setOpenCategory(false);
    };

    const handleCloseUserGroup = () => {
        setOpenUserGroup(false);
    };

    const handleCloseTutorialGroupDialog = () => {
        setOpenAddTutorialGroup(false);
        setNewTutorialGroup({ groupName: "", groupDescription: "" }); // Reset form
    };

    const handleSubmitNewTutorialGroup = async () => {
        if (tutorialGroups.length >= 30) {
            toast.error("Limit of tutorial groups reached. You can only create 30 tutorial groups.");
            return;
        }

        if (!newTutorialGroup.groupName.trim()) {
            toast.error("Group name is required.");
            return;
        }

        try {
            const docRef = await addDoc(collection(db, "tutorialGroups"), {
                groupName: newTutorialGroup.groupName,
                groupDescription: newTutorialGroup.groupDescription || "", // Assuming groupDescription is optional
                createdBy: user.id,
                admins: [user.id],
                date: new Date(),
                updatedDate: new Date()
            });
            toast.success("Tutorial group added.");

            handleCloseTutorialGroupDialog();
        } catch (error) {
            console.error("Error adding tutorial group:", error);
            toast.error("Failed to add tutorial group.");
        }
    };

    const handleDeleteTutorialGroup = async (groupId) => {
        const response = await confirmation("Delete tutorial group", "Confirm to delete this group?");
        if (!response) return;

        try {
            // Delete the group from Firebase
            await deleteDoc(doc(db, "tutorialGroups", groupId));

            // Batch updates for tutorials and users collection to remove the groupName
            const batch = writeBatch(db);
            const tutorialsQuery = query(
                collection(db, "tutorials"),
                where("tutorialGroups", "array-contains", groupId)
            );
            const userDocsQuery = query(collection(db, "users"), where("tutorialGroups", "array-contains", groupId));

            // Execute queries and batch removal operations
            const [tutorialsSnapshot, userDocsSnapshot] = await Promise.all([
                getDocs(tutorialsQuery),
                getDocs(userDocsQuery)
            ]);
            tutorialsSnapshot.forEach((doc) =>
                batch.update(doc.ref, { tutorialGroups: arrayRemove(groupId), updatedDate: new Date() })
            );
            userDocsSnapshot.forEach((doc) =>
                batch.update(doc.ref, { tutorialGroups: arrayRemove(groupId), updatedDate: new Date() })
            );

            // Commit the batch
            await batch.commit();

            toast.success("Tutorial group removed");
        } catch (error) {
            console.error("Error removing tutorial group:", error);
            toast.error("Failed to remove tutorial group.");
        }
    };

    const handleDeleteCategory = async (cat) => {
        const response = await confirmation("Delete category", "Confirm to delete this category?");
        if (!response) return;

        try {
            // Delete the category from Firebase
            console.log("cat:", cat);
            await deleteDoc(doc(db, "tutorialCategory", cat.id));

            // Search for tutorials with the category to be deleted
            const userDocsQuery = query(collection(db, "tutorials"), where("category", "==", cat.tutorialCategory));
            const querySnapshot = await getDocs(userDocsQuery);

            // Create a batch to update all tutorials that have the deleted category
            const batch = writeBatch(db);
            querySnapshot.forEach((doc) => {
                batch.update(doc.ref, { category: "" });
            });
            await batch.commit();

            toast.success("Category removed and tutorials updated.");
        } catch (error) {
            console.error("Error removing category:", error);
            toast.error("Failed to remove category.");
        }
    };

    const getFriends = useGetFriends();
    const { openGlobalList } = useContext(ListContext);
    const { openInputDialog } = useContext(InputContext);

    async function addAdmin(friends) {
        try {
            const response = await openGlobalList(friends, "Assign to", "displayName", "face", true, true);
            if (!response) return;
            let email;
            if (response === "addAccount") {
                email = await openInputDialog("Add friend", "Please enter your friend email", "Email", "");
            } else {
                email = response.email;
            }
            const groupRef = doc(db, "tutorialGroups", selectedGroup.id);
            await updateDoc(groupRef, {
                admins: arrayUnion(email),
                updatedDate: new Date()
            });
            toast.success("Add admin successfully!");
        } catch (err) {
            console.log(err);
            toast.error("Add admin error!");
        }
    }

    async function chooseUser(friends) {
        try {
            const response = await openGlobalList(friends, "Assign to", "displayName", "face", true, true);
            if (!response) return;
            let email;
            if (response === "addAccount") {
                email = await openInputDialog("Add friend", "Please enter your friend email", "Email", "");
            } else {
                email = response.email;
            }

            const userRef = doc(db, "users", email);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();
                setUserTutorialGroups({
                    user: response.email,
                    tutorialGroups: userData.tutorialGroups || []
                });
            } else {
                console.log("No such user!");
            }
        } catch (err) {
            console.error("Error selecting user:", err);
        }
    }

    const handleAddAdmin = async () => {
        const friends = await getFriends(user);
        addAdmin(friends);
    };

    const handleAddUser = async () => {
        const friends = await getFriends(user);
        chooseUser(friends);
    };

    const handleUserAssignAccess = async () => {
        // Assuming 'userTutorialGroups.user' holds the user's ID or a unique identifier
        // and 'formData.tutorialGroups' contains the selected tutorial group IDs or names
        if (!userTutorialGroups.user || !userTutorialGroups.tutorialGroups.length > 0) {
            toast.error("Please select a user and at least one tutorial group.");
            return;
        }

        try {
            const docRef = doc(db, "users", userTutorialGroups.user);

            // Example data structure - adjust as needed
            const userData = {
                tutorialGroups: userTutorialGroups.tutorialGroups,
                updatedDate: new Date()
            };

            // Set document in Firestore
            await setDoc(docRef, userData, { merge: true }); // Merge true to not overwrite other fields

            toast.success("User groups updated successfully!");
        } catch (error) {
            console.error("Error updating user groups: ", error);
            toast.error("Failed to update user groups.");
        }
    };

    const handleSubmitCategory = async () => {
        try {
            // Reference the 'tutorialCategory' collection
            const colRef = collection(db, "tutorialCategory");
            // Create a query against the collection looking for the category
            const q = query(colRef, where("tutorialCategory", "==", newCategory));

            // Execute the query
            const querySnapshot = await getDocs(q);

            // Check if any documents match (i.e., the category already exists)
            if (!querySnapshot.empty) {
                // Category already exists
                toast.error("Category already exists.");
                return; // Exit the function early
            }

            // If here, no existing category found, proceed to add new category
            const obj = {
                tutorialCategory: newCategory,
                date: new Date(),
                createdBy: user.id
                // Add any additional fields as needed
            };

            // Add a new document with the new category
            await addDoc(colRef, obj);

            // Display success message
            toast.success("Category added successfully!");
            handleCloseAddCategory();
        } catch (error) {
            console.error("Error handling category submission: ", error);
            // Display error message
            toast.error("Failed to handle category submission.");
        }
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleDeleteAdmin = async (admin, selectedGroup) => {
        const response = await confirmation("Remove Admin", "Confirm to remove this admin?");
        if (!response) return;

        // Filter out the admin from the selectedGroup's admins array
        const updatedAdmins = selectedGroup.admins.filter((a) => a !== admin);

        // Reference to the groups collection
        const groupRef = doc(db, "tutorialGroups", selectedGroup.id);

        try {
            // Update the document with the new admins array
            await updateDoc(groupRef, {
                admins: updatedAdmins,
                updatedDate: new Date()
            });
            toast.success("Admin removed successfully!");
            console.log("Admin removed successfully");
        } catch (error) {
            console.error("Error removing admin: ", error);
            toast.error("Error removing admin!");
        }
    };

    const indexOfLastTutorial = currentPage * itemsPerPage;
    const indexOfFirstTutorial = indexOfLastTutorial - itemsPerPage;
    const currentTutorials = filteredTutorials.slice(indexOfFirstTutorial, indexOfLastTutorial);

    const canDisplayAction = (tutorial) => {
        if (tutorial.createdBy === user.email) {
            return true;
        }

        const isAdmin = tutorial.tutorialGroups?.some((tgId) => {
            const group = tutorialGroups.find((tg) => tg.id === tgId);

            if (group?.admins?.includes(user.id)) {
                return true;
            } else {
                console.log(user.email, " is not admin");
                return false;
            }
        });

        return isAdmin;
    };

    useEffect(() => {
        const filterTutorials = () => {
            let filtered = tutorials;

            // Apply category filter if any category is selected
            if (selectedCategories.length > 0) {
                filtered = filtered.filter((tutorial) => selectedCategories.includes(tutorial.category));
            }

            // Apply language filter if any language is selected
            if (selectedLanguages.length > 0) {
                filtered = filtered.filter((tutorial) => selectedLanguages.includes(tutorial.language));
            }

            // Sort tutorials by updatedDate or date, newer dates first
            filtered.sort((a, b) => {
                // Use updatedDate if available, otherwise fall back to date
                // Convert Firestore Timestamp to JavaScript Date object if necessary
                const dateA = a.updatedDate
                    ? a.updatedDate.toDate
                        ? a.updatedDate.toDate()
                        : new Date(a.updatedDate)
                    : new Date(a.date.toDate ? a.date.toDate() : a.date);
                const dateB = b.updatedDate
                    ? b.updatedDate.toDate
                        ? b.updatedDate.toDate()
                        : new Date(b.updatedDate)
                    : new Date(b.date.toDate ? b.date.toDate() : b.date);

                // Compare the dates to determine order
                return dateB - dateA; // This will sort newer dates to the front
            });

            // Remove duplicates by creating a Map with unique IDs as keys
            const uniqueFiltered = Array.from(new Map(filtered.map((tutorial) => [tutorial.id, tutorial])).values());

            setFilteredTutorials(uniqueFiltered);
        };

        filterTutorials();
    }, [tutorials, selectedCategories, selectedLanguages, categories]);

    useEffect(() => {
        handleOpenBackDrop(); // Show the backdrop when starting to fetch data

        const tutorialsRef = collection(db, "tutorials");
        const queryPublic = query(tutorialsRef, where("access", "==", "public"));
        const queryPrivate = query(
            tutorialsRef,
            where("access", "==", "private"),
            where("createdBy", "==", user.email)
        );

        // Function to map snapshot to tutorials
        const mapSnapshotToTutorials = (querySnapshot) =>
            querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data()
            }));

        let unsubscribe = () => {};
        if (user?.tutorialGroups?.length > 0) {
            const tutorialsQuery = query(
                tutorialsRef,
                where("tutorialGroups", "array-contains-any", user.tutorialGroups)
            );
            unsubscribe = onSnapshot(
                tutorialsQuery,
                (querySnapshot) => {
                    const fetchedTutorials = mapSnapshotToTutorials(querySnapshot);
                    setTutorials(fetchedTutorials);
                    handleCloseBackDrop();
                },
                (error) => {
                    console.error("Error fetching tutorials:", error);
                    handleCloseBackDrop();
                }
            );
        }

        const unsubscribe2 = onSnapshot(queryPublic, (querySnapshot) => {
            const tutorialsPublic = mapSnapshotToTutorials(querySnapshot);
            setTutorials((prev) => [...prev, ...tutorialsPublic]);
        });

        const unsubscribe3 = onSnapshot(queryPrivate, (querySnapshot) => {
            const tutorialsPrivate = mapSnapshotToTutorials(querySnapshot);
            setTutorials((prev) => [...prev, ...tutorialsPrivate]);
            handleCloseBackDrop();
        });

        // New logic to fetch tutorials based on admin groups
        const fetchAdminGroupTutorials = async () => {
            const tutorialGroupsRef = collection(db, "tutorialGroups");
            const groupsQuery = query(tutorialGroupsRef, where("admins", "array-contains", user.email));
            const groupsSnapshot = await getDocs(groupsQuery);
            const groupIds = groupsSnapshot.docs.map((doc) => doc.id);

            if (groupIds.length > 0) {
                const adminTutorialsQuery = query(
                    tutorialsRef,
                    where("tutorialGroups", "array-contains-any", groupIds)
                );
                const adminTutorialsSnapshot = await getDocs(adminTutorialsQuery);
                const adminTutorials = adminTutorialsSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setTutorials((prev) => [...prev, ...adminTutorials]);
            }
        };

        fetchAdminGroupTutorials().catch(console.error);

        return () => {
            unsubscribe();
            unsubscribe2();
            unsubscribe3();
            handleCloseBackDrop(); // Ensure backdrop is closed when component unmounts or updates
        };
    }, [user?.tutorialGroups, user.email, refresh]);

    // useEffect(() => {
    //     handleOpenBackDrop(); // Show the backdrop when starting to fetch data

    //     let tutorialsQuery;
    //     if (user?.tutorialGroups?.length > 0) {
    //         tutorialsQuery = query(
    //             collection(db, "tutorials"),
    //             where("tutorialGroups", "array-contains-any", user.tutorialGroups)
    //         );
    //     }

    //     const queryPublic = query(collection(db, "tutorials"), where("access", "==", "public"));
    //     const queryPrivate = query(
    //         collection(db, "tutorials"),
    //         where("access", "==", "private"),
    //         where("createdBy", "==", user.email)
    //     );

    //     let unsubscribe = () => {};
    //     if (tutorialsQuery) {
    //         unsubscribe = onSnapshot(
    //             tutorialsQuery,
    //             (querySnapshot) => {
    //                 const fetchedTutorials = querySnapshot.docs.map((doc) => ({
    //                     id: doc.id,
    //                     ...doc.data()
    //                 }));
    //                 setTutorials(fetchedTutorials);
    //                 handleCloseBackDrop(); // Hide the backdrop after fetching
    //             },
    //             (error) => {
    //                 console.error("Error fetching tutorials:", error);
    //                 handleCloseBackDrop(); // Also hide the backdrop in case of an error
    //             }
    //         );
    //     }

    //     const unsubscribe2 = onSnapshot(queryPublic, (querySnapshot) => {
    //         const tutorialsPublic = mapSnapshot(querySnapshot);
    //         setTutorials((prev) => [...prev, ...tutorialsPublic]);
    //     });

    //     const unsubscribe3 = onSnapshot(queryPrivate, (querySnapshot) => {
    //         const tutorialsPrivate = mapSnapshot(querySnapshot);
    //         setTutorials((prev) => [...prev, ...tutorialsPrivate]);
    //         handleCloseBackDrop();
    //     });

    //     return () => {
    //         unsubscribe();
    //         unsubscribe2();
    //         unsubscribe3();
    //         handleCloseBackDrop();
    //     };
    // }, [user?.tutorialGroups,user,refresh]);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(collection(db, "tutorialGroups"), where("admins", "array-contains", user.id)),
            (querySnapshot) => {
                const groups = mapSnapshot(querySnapshot);
                setTutorialGroups(groups);
            },
            (error) => {
                // Handle any errors
                console.error("Error fetching tutorial groups:", error);
            }
        );

        // Cleanup function to unsubscribe from the listener when the component unmounts or the dependencies change
        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        const unsubscribe = onSnapshot(
            query(collection(db, "tutorialCategory"), where("createdBy", "==", user.id)),
            (querySnapshot) => {
                const cat = mapSnapshot(querySnapshot);
                setCategories(cat);
            },
            (error) => {
                // Handle any errors
                console.error("Error fetching tutorial groups:", error);
            }
        );

        // Cleanup function to unsubscribe from the listener when the component unmounts or the dependencies change
        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        // Check if there's a selected group to listen to
        if (selectedGroup?.id) {
            // Reference to the selected group document in Firestore
            const groupRef = doc(db, "tutorialGroups", selectedGroup.id);
            // Set up the real-time listener
            const unsubscribe = onSnapshot(groupRef, (doc) => {
                // Update the selectedGroup state with the latest data
                // Make sure to handle the case where the document might have been deleted
                if (doc.exists()) {
                    setSelectedGroup({
                        id: doc.id,
                        ...doc.data()
                    });
                } else {
                    // Handle the case where the selected group was deleted from Firestore
                    setSelectedGroup(null);
                }
            });

            // Clean up the listener when the component unmounts or when selectedGroup changes
            return () => unsubscribe();
        }
    }, [selectedGroup?.id]); // Depend on selectedGroup.id to re-subscribe when it changes

    //     useEffect(() => {
    //         async function fetchMatchingBlocks() {
    //             console.log("in");
    //             const usersRef = collection(db, "users");
    //             const usersSnapshot = await getDocs(usersRef);
    //             let matchingBlocksPromises = [];

    //             for (const userDoc of usersSnapshot.docs) {
    //                 const whatsappFlowsRef = collection(db, `users/${userDoc.id}/whatsappFlows`);
    //                 const whatsappFlowsSnapshot = await getDocs(whatsappFlowsRef);

    //                 for (const whatsappFlowDoc of whatsappFlowsSnapshot.docs) {
    //                     const blocksRelationRef = collection(
    //                         db,
    //                         `users/${userDoc.id}/whatsappFlows/${whatsappFlowDoc.id}/blocksRelation`
    //                     );
    //                     const blocksQuery = query(blocksRelationRef, where("sourceNodeId", "==", "targetNodeId"));

    //                     matchingBlocksPromises.push(
    //                         getDocs(blocksQuery).then((blocksSnapshot) => {
    //                             return blocksSnapshot.docs.map((blockDoc) => ({
    //                                 ...blockDoc.data(),
    //                                 whatsappFlowId: whatsappFlowDoc.id,
    //                                 userId: userDoc.id
    //                             }));
    //                         })
    //                     );
    //                 }
    //             }
    // console.log('out');
    //             const matchingBlocksResults = await Promise.all(matchingBlocksPromises);
    //             const flatResults = matchingBlocksResults.flat();
    //             console.log("Results:", flatResults);
    //         }

    //         fetchMatchingBlocks();
    //     }, []);

    return (
        <div>
            <Box m="16px" display="flex" alignItems="center" gap={1}>
                <Button variant="contained" onClick={handleClickAddTutorial} size="large">
                    Add Tutorial
                </Button>
                <Button variant="contained" onClick={handleOpenTutorialGroupDialog} size="large">
                    Manage Group
                </Button>
                <Button variant="contained" onClick={handleOpenCategoryDialog} size="large">
                    Manage Category
                </Button>
                <Button variant="contained" onClick={handleOpenUserDialog} size="large">
                    Manage User
                </Button>
            </Box>

            <Box m="16px" display="flex" alignItems="center" gap={1}>
                <Typography>Filter by Category:</Typography>

                {categories.map((category) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedCategories.includes(category.id)}
                                onChange={handleSelectCategory}
                                name={category.id}
                            />
                        }
                        label={category.tutorialCategory}
                        key={category.id}
                    />
                ))}
            </Box>

            <Box m="16px" display="flex" alignItems="center" gap={1}>
                <Typography>Filter by Language:</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedLanguages.includes("english")}
                            onChange={handleSelectLanguage}
                            name="english"
                        />
                    }
                    label="English"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedLanguages.includes("chinese")}
                            onChange={handleSelectLanguage}
                            name="chinese"
                        />
                    }
                    label="Chinese"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedLanguages.includes("malay")}
                            onChange={handleSelectLanguage}
                            name="malay"
                        />
                    }
                    label="Malay"
                />
            </Box>

            <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(filteredTutorials.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="caption">
                    Total Listings: {filteredTutorials.length} | Page {currentPage} of{" "}
                    {Math.ceil(filteredTutorials.length / itemsPerPage)}
                </Typography>
            </Grid>

            <Grid container spacing={1} sx={{ padding: 2 }}>
                {currentTutorials.map((tutorial, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} onClick={() => handleVideoClick(tutorial.link)}>
                        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <CardMedia
                                component="img"
                                image={tutorial.thumbnail}
                                alt={tutorial.title}
                                sx={{ cursor: "pointer" }}
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h6">{tutorial.title}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {tutorial.description}
                                </Typography>
                            </CardContent>
                            {canDisplayAction(tutorial) && (
                                <CardActions>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            alignItems: "center"
                                        }}
                                    >
                                        {/* Box for Buttons (Edit and Delete) */}
                                        <Box>
                                            <Button
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEditClick(tutorial);
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                color="error"
                                                size="small"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDeleteClick(tutorial);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </Box>

                                        {/* Box for Language and Access Typography, aligned to the end (right) */}
                                        <Box sx={{ display: "flex", gap: 2 }}>
                                            <Tooltip
                                                title={
                                                    tutorial?.language === "english"
                                                        ? "ENGLISH"
                                                        : tutorial?.language === "chinese"
                                                        ? "CHINESE"
                                                        : tutorial?.language === "malay"
                                                        ? "MALAY"
                                                        : ""
                                                }
                                            >
                                                <Typography variant="caption" color="text.secondary">
                                                    {tutorial?.language === "english"
                                                        ? "EN"
                                                        : tutorial?.language === "chinese"
                                                        ? "CN"
                                                        : tutorial?.language === "malay"
                                                        ? "MALAY"
                                                        : ""}
                                                </Typography>
                                            </Tooltip>
                                            <Tooltip
                                                title={
                                                    tutorial?.access === "public" ? "Public viewable" : "Private only"
                                                }
                                            >
                                                <Typography
                                                    variant="caption"
                                                    color="text.secondary"
                                                    sx={{ textTransform: "capitalize" }}
                                                >
                                                    {tutorial?.access}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </CardActions>
                            )}
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(filteredTutorials.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                    />
                </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography variant="caption">
                    Total Listings: {filteredTutorials.length} | Page {currentPage} of{" "}
                    {Math.ceil(filteredTutorials.length / itemsPerPage)}
                </Typography>
            </Grid>
            <Dialog open={videoDialogOpen} onClose={handleVideoDialogClose} maxWidth="lg" fullWidth sx={{ padding: 0 }}>
                <DialogContent sx={{ padding: "4px" }}>
                    <iframe
                        width="100%"
                        height="500"
                        src={convertToEmbedUrl(selectedVideo)}
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="Video"
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{isEditing ? "Edit Tutorial" : "Add a Tutorial"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="title"
                        label="Title"
                        multiline
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.title}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="description"
                        label="Description"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={formData.description}
                        onChange={handleChange}
                    />

                    <Divider sx={{ margin: "2px" }} />
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="body">Tutorial Group:</Typography>
                    </Box>
                    <Box m="0px" display="flex" justifyContent="flex-start" alignItems="center" gap={1} flexWrap="wrap">
                        <ToggleButtonGroup
                            color="primary"
                            value={formData.tutorialGroups}
                            onChange={(event, newTutorialGroupIds) => {
                                setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    tutorialGroups: newTutorialGroupIds
                                }));
                            }}
                            aria-label="Tutorial Groups"
                            sx={{
                                flexWrap: "wrap",
                                alignItems: "flex-start",
                                "& .MuiToggleButton-root": {
                                    marginBottom: "8px", // Adds spacing between rows
                                    marginRight: "0px" // Adds spacing between buttons
                                }
                            }}
                        >
                            {tutorialGroups.map((group) => (
                                <ToggleButton key={group.id} value={group.id}>
                                    {group.groupName}
                                </ToggleButton>
                            ))}
                        </ToggleButtonGroup>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenTutorialGroupDialog}
                            startIcon={<AddIcon />}
                            sx={{ height: "fit-content" }} // Adjust button height to fit its content
                        >
                            Manage Group
                        </Button>
                    </Box>

                    <Divider sx={{ margin: "2px" }} />
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, my: 1 }}>
                        <TextField
                            select
                            label="Category"
                            name="category"
                            fullWidth
                            value={formData.category}
                            onChange={handleCategorySelectChange}
                            variant="outlined"
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.tutorialCategory} value={category.id}>
                                    {category.tutorialCategory}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    <TextField
                        select
                        margin="dense"
                        name="language"
                        label="Language"
                        fullWidth
                        variant="outlined"
                        value={formData.language}
                        onChange={handleChange}
                    >
                        <MenuItem value="english">English</MenuItem>
                        <MenuItem value="chinese">Chinese</MenuItem>
                        <MenuItem value="malay">Bahasa Malaysia</MenuItem>
                    </TextField>
                    <TextField
                        margin="dense"
                        name="link"
                        label="Video Link"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.link}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        name="thumbnail"
                        label="Thumbnail URL"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.thumbnail}
                        onChange={handleChange}
                    />
                    <TextField
                        select
                        margin="dense"
                        name="access"
                        label="Access"
                        fullWidth
                        variant="outlined"
                        value={formData.access}
                        onChange={handleChange}
                    >
                        <MenuItem value="private">Private</MenuItem>
                        <MenuItem value="public">Public</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddTutorial}>{isEditing ? "Update" : "Add"}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAddTutorialGroup} onClose={handleCloseAddTutorialGroup}>
                <DialogTitle>Add/Edit Tutorial Group</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="groupName"
                        label="Group Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={tutorialGroupsFormData.groupName}
                        onChange={handleChangeTutorialGroup}
                    />
                    <TextField
                        margin="dense"
                        name="groupDescription"
                        label="Group Description"
                        multiline
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.groupDesc}
                        onChange={handleChangeTutorialGroup}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddTutorialGroup}>Cancel</Button>
                    <Button onClick={handleSubmitTutorialGroup}>Update</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openTutorialGroup} onClose={handleCloseTutorialGroup} fullWidth maxWidth="md">
                <DialogTitle>Manage Tutorial Group</DialogTitle>
                <DialogContent>
                    <Divider>Tutorial Groups</Divider>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, marginTop: 2 }}>
                        {/* List of Tutorial Groups */}
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
                            {/* List of Tutorial Groups */}
                            <Button
                                onClick={() => setOpenAddTutorialGroup(true)}
                                variant="contained"
                                size="small"
                                sx={{ marginBottom: 1 }}
                            >
                                Add
                            </Button>
                            {tutorialGroups
                                ?.sort((a, b) => a.date.toDate() - b.date.toDate())
                                .map((group) => (
                                    <Chip
                                        size="small"
                                        key={group.id}
                                        label={`${group.groupName}`}
                                        variant="outlined"
                                        onClick={() => handleSelectGroup(group)}
                                        onDelete={() => handleDeleteTutorialGroup(group.id)}
                                        color="primary"
                                    />
                                ))}
                        </Box>

                        {selectedGroup && (
                            <Box sx={{ mt: 2, p: 2, border: "1px solid", borderColor: "divider", borderRadius: "4px" }}>
                                {isEditingName ? (
                                    <TextField
                                        size="small"
                                        defaultValue={selectedGroup.groupName}
                                        onChange={(e) => setEditedGroupName(e.target.value)}
                                        onBlur={updateNameDetails} // Update Firestore on blur
                                        autoFocus
                                        fullWidth
                                    />
                                ) : (
                                    <Typography variant="h6" component="div" onClick={() => setIsEditingName(true)}>
                                        Group Name : {selectedGroup.groupName}
                                    </Typography>
                                )}

                                {isEditingDescription ? (
                                    <TextField
                                        size="small"
                                        defaultValue={selectedGroup.groupDescription}
                                        onChange={(e) => setEditedGroupDescription(e.target.value)}
                                        onBlur={updateGroupDescDetails} // Update Firestore on blur
                                        autoFocus
                                        fullWidth
                                        multiline
                                    />
                                ) : (
                                    <Typography variant="subtitle1" onClick={() => setIsEditingDescription(true)}>
                                        Description : {selectedGroup.groupDescription}
                                    </Typography>
                                )}

                                <Divider sx={{ my: 1 }}>Admins</Divider>
                                <Button
                                    onClick={handleAddAdmin}
                                    variant="contained"
                                    size="small"
                                    sx={{ marginBottom: 1 }}
                                >
                                    Add
                                </Button>
                                {selectedGroup?.admins.map((admin) => (
                                    <Chip
                                        size="small"
                                        key={admin}
                                        label={admin}
                                        variant="outlined"
                                        onDelete={() => handleDeleteAdmin(admin, selectedGroup)}
                                        color="primary"
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                    <Divider sx={{ marginTop: 2 }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTutorialGroup}>Cancel</Button>
                    {/* <Button onClick={handleSubmitNewTutorialGroup}>Add</Button> */}
                </DialogActions>
            </Dialog>

            <Dialog open={openAddTutorialGroup} onClose={handleCloseAddTutorialGroup} fullWidth maxWidth="md">
                <DialogTitle>Add Tutorial Group(maximum 30 groups)</DialogTitle>
                <DialogContent>
                    <Divider>Add New Tutorial Groups</Divider>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="groupName"
                        label="Group Name"
                        type="text"
                        fullWidth
                        value={newTutorialGroup.groupName}
                        onChange={handleNewTutorialGroupChange}
                    />
                    <TextField
                        margin="dense"
                        name="groupDescription"
                        label="Group Description"
                        type="text"
                        multiline
                        fullWidth
                        value={newTutorialGroup.groupDescription}
                        onChange={handleNewTutorialGroupChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddTutorialGroup}>Cancel</Button>
                    <Button onClick={handleSubmitNewTutorialGroup}>Add</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openUserGroup} onClose={handleCloseUserGroup} fullWidth maxWidth="md">
                <DialogTitle>Manage user</DialogTitle>
                <DialogContent>
                    <Divider>Set Access To User</Divider>
                    <Button onClick={handleAddUser} variant="contained" size="small" sx={{ marginBottom: 1 }}>
                        Select User
                    </Button>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2 // Creates space between items
                        }}
                    >
                        {userTutorialGroups.user && (
                            <>
                                <Typography variant="body">Selected User : {userTutorialGroups.user}</Typography>
                                <Typography variant="body">Select Group : </Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={userTutorialGroups.tutorialGroups} // This holds the IDs of the tutorial groups
                                    onChange={(event, newTutorialGroupIds) => {
                                        setUserTutorialGroups((prevFormData) => ({
                                            ...prevFormData,
                                            tutorialGroups: newTutorialGroupIds
                                        }));
                                    }}
                                    aria-label="Tutorial Groups"
                                    sx={{
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        "& .MuiToggleButton-root": {
                                            marginBottom: "8px", // Adds spacing between rows
                                            marginRight: "0px" // Adds spacing between buttons
                                        }
                                    }}
                                >
                                    {tutorialGroups.map((group) => (
                                        <ToggleButton
                                            key={group.id}
                                            value={group.id}
                                            selected={userTutorialGroups.tutorialGroups.includes(group.id)}
                                        >
                                            {group.groupName}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </>
                        )}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseUserGroup}>Cancel</Button>
                    <Button onClick={handleUserAssignAccess}>Update</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCategory} onClose={handleCloseCategory} fullWidth maxWidth="md">
                <DialogTitle>Manage Category</DialogTitle>
                <DialogContent>
                    <Divider>Categories</Divider>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1, marginTop: 2 }}>
                        {/* List of Tutorial Groups */}
                        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
                            {/* List of Tutorial Groups */}
                            <Button
                                onClick={() => setOpenAddCategory(true)}
                                variant="contained"
                                size="small"
                                sx={{ marginBottom: 1 }}
                            >
                                Add
                            </Button>
                            {categories.map((cat) =>
                                cat.tutorialCategory ? (
                                    <Chip
                                        size="small"
                                        key={cat.tutorialCategory}
                                        label={`${cat.tutorialCategory}`}
                                        variant="outlined"
                                        // onClick={() => handleSelectCategory(cat)}
                                        onDelete={() => handleDeleteCategory(cat)}
                                        color="primary"
                                    />
                                ) : null
                            )}
                        </Box>

                        {selectedGroup && (
                            <Box sx={{ mt: 2, p: 2, border: "1px solid", borderColor: "divider", borderRadius: "4px" }}>
                                {isEditingName ? (
                                    <TextField
                                        size="small"
                                        defaultValue={selectedGroup.groupName}
                                        onChange={(e) => setEditedGroupName(e.target.value)}
                                        onBlur={updateNameDetails} // Update Firestore on blur
                                        autoFocus
                                        fullWidth
                                    />
                                ) : (
                                    <Typography variant="h6" component="div" onClick={() => setIsEditingName(true)}>
                                        Group Name : {selectedGroup.groupName}
                                    </Typography>
                                )}

                                {isEditingDescription ? (
                                    <TextField
                                        size="small"
                                        defaultValue={selectedGroup.groupDescription}
                                        onChange={(e) => setEditedGroupDescription(e.target.value)}
                                        onBlur={updateGroupDescDetails} // Update Firestore on blur
                                        autoFocus
                                        fullWidth
                                        multiline
                                    />
                                ) : (
                                    <Typography variant="subtitle1" onClick={() => setIsEditingDescription(true)}>
                                        Description : {selectedGroup.groupDescription}
                                    </Typography>
                                )}

                                <Divider sx={{ my: 1 }}>Admins</Divider>
                                <Button
                                    onClick={handleAddAdmin}
                                    variant="contained"
                                    size="small"
                                    sx={{ marginBottom: 1 }}
                                >
                                    Add
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Divider sx={{ marginTop: 2 }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCategory}>Cancel</Button>
                    {/* <Button onClick={handleSubmitNewTutorialGroup}>Add</Button> */}
                </DialogActions>
            </Dialog>

            <Dialog open={openAddCategory} onClose={handleCloseAddCategory} fullWidth maxWidth="md">
                <DialogTitle>Add Category</DialogTitle>
                <DialogContent>
                    <Divider>Add New Category</Divider>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="category"
                        label="category"
                        type="text"
                        fullWidth
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddCategory}>Cancel</Button>
                    <Button onClick={handleSubmitCategory}>Add</Button>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackDrop}
                onClick={handleCloseBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}
