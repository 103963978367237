import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapDocSnapshot from "../../utils-functions/mapDocSnapshot";

export default async function loadChatGptHistory(item, user) {
    try {
        const docRef = doc(db, "users", user.id, "chatGptHistory", item.id);
        const snapshot = await getDoc(docRef);
        const data = mapDocSnapshot(snapshot);
        return data.messages;
    } catch (error) {
        console.log(error);
    }
}
