import removeDuplicated from "../../functions/common-functions/removeDuplicates";
import { EMPTY } from "../../settings/settings";
import { checkItem } from "./checkItem";

export default function createDropDownList(objects, id, header, user) {
    const h = header.find((item) => item.id === id);
    const type = h.type || "text";
    const list = objects.map((object) => {
        const item = checkItem(object[id], type, user);

        return item || EMPTY;
    });

    const uniqueNames = removeDuplicated(list);
    const uniqueObjects = [];
    uniqueNames.forEach((name) => {
        const count = list.filter((item) => item === name).length;
        const object = { name: name, count: count };
        uniqueObjects.push(object);
    });
    uniqueObjects.sort((a, b) => {
        return b.count - a.count;
    });

    return uniqueObjects;
}
