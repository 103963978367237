import { Container, Grid, Paper } from "@mui/material";
import { collectionGroup, onSnapshot } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import { GridContainer, GridDivider, GridFlexBox, Name, Title } from "../../themes/themes";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import formatNumber from "./../../utils-functions/formatNumber";

export default function WhatsappFlowUsagePage() {
    const { user } = useContext(AuthContext);
    const [gptResponses, setGptResponses] = useState([]);
    const [usages, setUsages] = useState([]);

    useEffect(() => {
        const collectionRef = collectionGroup(db, "gptResponses");
        const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
            const data = mapSnapshot(snapshot);
            setGptResponses(data);
        });

        return unsubscribe;
    }, [user]);

    useEffect(() => {
        if (arrayIsEmpty(gptResponses)) return;
        const clientIds = gptResponses.reduce((acc, curr) => {
            if (acc.includes(curr.clientId)) return acc;
            return [...acc, curr.clientId];
        }, []);
        const usagesObjs = {};
        clientIds.forEach((clientId) => {
            const responses = gptResponses.filter((response) => response.clientId === clientId);
            const usage = responses.reduce((acc, curr) => {
                acc["completion_tokens"] = (acc["completion_tokens"] || 0) + curr.usage.completion_tokens;
                acc["prompt_tokens"] = (acc["prompt_tokens"] || 0) + curr.usage.prompt_tokens;
                acc["total_tokens"] = (acc["total_tokens"] || 0) + curr.usage.total_tokens;
                console.log(acc);
                return acc;
            }, {});
            usagesObjs[clientId] = usage;
        });
        const usages = Object.keys(usagesObjs).map((clientId) => {
            return {
                clientId,
                ...usagesObjs[clientId]
            };
        });
        usages.sort((a, b) => b.total_tokens - a.total_tokens);
        setUsages(usages);
        console.log(usages);
    }, [gptResponses]);

    return (
        <Container maxWidth="none">
            <GridContainer>
                <GridFlexBox>
                    <Title>Whatsapp Flow Usage</Title>
                </GridFlexBox>
                <GridDivider />
            </GridContainer>
            <Grid container display="flex" spacing={1}>
                <Grid item xs={12} md={6}>
                    <Grid container display="flex" spacing={1}></Grid>
                    <Grid item display="flex" xs={12} flexDirection="column">
                        <Paper sx={{ height: "50vh", width: "100%", padding: "8px", overflowY: "scroll" }}>
                            <Grid container display="flex" spacing={1}>
                                {usages.map((usage) => (
                                    <React.Fragment key={usage.clientId}>
                                        <GridFlexBox xs={6} fs>
                                            <Name>{usage.clientId}</Name>
                                        </GridFlexBox>
                                        <GridFlexBox xs={2} fe>
                                            <Name>{formatNumber(usage.completion_tokens, 0)}</Name>
                                        </GridFlexBox>
                                        <GridFlexBox xs={2} fe>
                                            <Name>{formatNumber(usage.prompt_tokens, 0)}</Name>
                                        </GridFlexBox>
                                        <GridFlexBox xs={2} fe>
                                            <Name>{formatNumber(usage.total_tokens, 0)}</Name>
                                        </GridFlexBox>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container display="flex" spacing={1}></Grid>
                    <Grid item display="flex" xs={12} flexDirection="column">
                        <Paper sx={{ height: "50vh", width: "100%", padding: "8px" }}></Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
