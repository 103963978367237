import * as XLSX from "xlsx";

function exportToExcel(data, fieldToDisplay) {
    let displayData = [];
    if (data && fieldToDisplay) {
        displayData = data.map((item) => {
            let obj = {};
            fieldToDisplay.forEach((field) => {
                if (field === "phone") {
                    obj[field] = keepOnlyNumbers(item[field]);
                } else {
                    obj[field] =
                        item[field] && typeof item[field] === "string"
                            ? item[field].replace(/\\n/g, "\n")
                            : item[field];
                }
            });
            return obj;
        });
    }

    console.log(displayData);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(displayData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Apply text wrapping and vertical alignment
        for (const key in worksheet) {
            if (key[0] === "!") continue;

            if (!worksheet[key].s) {
                worksheet[key].s = {};
            }
            worksheet[key].s.alignment = { wrapText: true, vertical: "center" };
        }

        XLSX.writeFile(workbook, "data.xlsx");
    };

    downloadExcel();
}

export default exportToExcel;

function keepOnlyNumbers(input) {
    if (!input) return "";
    input = input.toString();
    var output = input.replace(/\D/g, "");
    return output;
}
