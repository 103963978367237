import React, { useEffect } from "react";
import { Box, Typography, Input } from "@mui/material";
import useColors from "../../hooks/useColors";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default function EditableFieldComponent({ currentClient, field, label }) {
    const [edit, setEdit] = React.useState(false);
    const [value, setValue] = React.useState(currentClient?.[field] || "");

    const colors = useColors();

    useEffect(() => {
        setValue(currentClient?.[field] || "");
    }, [currentClient]);

    const handleChange = (e) => {
        setValue(e.target.value);
    };

    const handleSave = async () => {
        const docRef = doc(db, "clients", currentClient.id);
        await updateDoc(docRef, {
            [field]: value.trim()
        });
        setEdit(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSave();
        }
    };

    return (
        <Box display="flex" gap="8px" alignItems={"center"}>
            <Box p="8px" width={{ xs: "auto", md: "80px" }}>
                <Typography variant="body2">{label}:</Typography>
            </Box>
            {!edit ? (
                <Box
                    onClick={() => setEdit(true)}
                    p="8px"
                    flex={1}
                    sx={{
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: colors.boxHighlight
                        }
                    }}
                >
                    <Typography variant="body2" sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
                        {currentClient?.[field] || "Not set"}
                    </Typography>
                </Box>
            ) : (
                <Input
                    value={value}
                    onChange={handleChange}
                    onBlur={handleSave}
                    autoFocus
                    fullWidth
                    onKeyDown={handleKeyDown}
                    sx={{ paddingLeft: "8px" }}
                />
            )}
        </Box>
    );
}
