import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import convertDate from "../../utils-functions/convertDate";
import { notification } from "../../utils-functions/notification";

export async function saveLeads(leads, page_, leadForm, owner) {
    console.log(owner);
    try {
        if (arrayIsEmpty(leads)) {
            notification("Opps", "No leads at the moment", "info");
            return;
        }
        const pageDocRef = doc(db, "pages", page_.id);
        const pageDoc = await getDoc(pageDocRef);
        const page = { ...pageDoc.data(), id: pageDoc.id };

        // Check each lead
        leads.forEach(async (lead) => {
            const docRef = doc(db, "leads", lead.id);
            const docSnapshot = await getDoc(docRef);
            if (!docSnapshot.exists()) {
                await setDoc(
                    docRef,
                    {
                        ...lead,
                        page_id: page.id,
                        pageName: page.name,
                        admins: page.admins,
                        id: lead.id,
                        form_id: leadForm.id,
                        formName: leadForm.displayName,
                        owner: owner.email,
                        source: "FB",
                        created: convertDate(lead.created_time),
                        created_time: convertDate(lead.created_time)
                    },
                    { merge: true }
                );
                console.log("Lead " + lead.id + " added into Firestore");
            } else {
                await updateDoc(docRef, {
                    ...lead,
                    page_id: page.id,
                    pageName: page.name,
                    admins: arrayUnion(owner.email),
                    id: lead.id,
                    form_id: leadForm.id,
                    formName: leadForm.displayName,
                    owner: owner.email,
                    source: "FB",
                    created: convertDate(lead.created_time),
                    created_time: convertDate(lead.created_time)
                });
                console.log("Lead " + lead.id + " updated into Firestore");
            }
        });
        notification("Done", "Leads added into your database", "success");
    } catch (error) {
        console.log(error);
        notification("Opps", error.message, "danger");
    }
}
