import type {
    GridColDef,
    GridFilterModel,
    GridRowSelectionModel,
    GridSlotsComponentsProps,
} from "@mui/x-data-grid";

import { collection, doc, getDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Box, Button, Chip, Typography } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { AuthContext } from "../../context/AuthContext";
import { db } from "../../firebase/firebase-utils";
import formatDate from "../../functions/common-functions/formatDate";
import formatNumber from "../../functions/common-functions/formatNumber";
import { arrayIsEmpty } from "../../utils-functions/arrayIsEmpty";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import processData from "./processData";

export type UserType =
    | Record<string, any>
    | {
          displayName: string;
          email: string;
          id: string;
          uid?: string;
          emailVerified?: boolean;
          loginType?: string;
          role?: string;
          teamIds?: string[];
          roles?: string[];
          referral?: string;
      }
    | null;

export type DownlineInfo = {
    level: number;
    data: UserType;
    email?: string;
};

export type CustomerInfo = {
    id: string;
};

export type SubscriptionInfo = {
    id: string;
    created: Date;
    status: "active" | "canceled";
    current_period_start: Date;
    current_period_end: Date;
    items: Array<{
        plan: {
            active: boolean;
            amount: number;
            currency: string;
            interval: string;
            interval_count: number;
            product: string | "month" | "year";
        };
    }>;
    amount: number;
    currency: string;
    interval: string;
};

export type PaymentInfo = {
    id: string;
    amount: number;
    amount_received: number;
    amount_refunded?: number;
    created: Date;
    currency: string;
    prices?: any;
};

export default function SalesOverviewPage() {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState<any>([]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [],
    });
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
    const [showActive, setShowActive] = useState(false);
    const [showPaid, setShowPaid] = useState(false);
    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalMrr, setTotalMrr] = useState(0);
    const [totalMrc, setTotalMrc] = useState(0);

    const { user } = useContext(AuthContext) as { user: UserType };

    useEffect(() => {
        if (!user) return;
        if (arrayIsEmpty(rows)) return;

        const totalRevenue = rows.reduce((acc: number, row: any) => {
            return acc + (row.totalPayment || 0);
        }, 0);

        // calculate total mrr
        const totalMrr = rows.reduce((acc: number, row: any) => {
            return acc + (row.mrr || 0);
        }, 0);

        // calculate total mrc
        const totalMrc = rows.reduce((acc: number, row: any) => {
            return acc + (row.mrc || 0);
        }, 0);

        setTotalRevenue(totalRevenue);
        setTotalMrr(totalMrr);
        setTotalMrc(totalMrc);
    }, [user, rows]);

    const loadEntireDownlines = useCallback(async () => {
        if (!user) return [];

        const usersRef = collection(db, "users");
        const q = query(usersRef, where("uplineIds", "array-contains", user.id), orderBy("date", "desc"));
        const downlines = await getDocs(q);

        const result: Record<string, DownlineInfo> = {};

        downlines.forEach((doc) => {
            const data = doc.data();
            const level = data.uplineIds.length;
            const downline = {
                level,
                data,
            };

            result[doc.id] = downline;
        });

        // Usage
        try {
            const processedData = await processData(result, user);
            console.log(processedData);

            return processedData;
        } catch (error) {
            console.error("Error processing data:", error);

            return [];
        }
    }, [user]);

    useEffect(() => {
        if (!user) return;

        setLoading(true);
        const loadData = async () => {
            const data = await loadEntireDownlines();
            data.sort((a, b) => a.date - b.date);
            data.sort((a, b) => a.level - b.level);
            setRows(data);
            setLoading(false);
        };

        loadData();
    }, [user]);

    const columns = useMemo<GridColDef<any>[]>(
        () => [
            {
                field: "displayName",
                headerName: "Name",
                width: 180,
                renderCell: (params) => (
                    <Box>
                        <Button onClick={() => console.log(params.row)}>{params.value}</Button>
                    </Box>
                ),
            },
            { field: "email", headerName: "Email", width: 180 },
            { field: "level", headerName: "Level", width: 100, align: "center" },
            { field: "referral", headerName: "Referral", width: 180 },

            {
                field: "addOn",
                headerName: "Status",
                width: 100,
                align: "center",
                renderCell: (params) => {
                    if (params.value === "whatsappCloud") {
                        return (
                            <Box>
                                <Chip label="active" size="small" color="primary" />
                            </Box>
                        );
                    }

                    return "";
                },
            },

            {
                field: "payments",
                headerName: "Payments",
                width: 180,
                renderCell: (params) => {
                    const payments = params.value as PaymentInfo[];
                    if (!payments) return null;

                    return (
                        <Box sx={{ overflowY: "auto" }} height="100%" p={1}>
                            {payments.map((payment) => (
                                <Box
                                    key={payment.id}
                                    display="flex"
                                    alignItems="center"
                                    gap={1}
                                    justifyContent="space-around"
                                >
                                    <Typography variant="caption" flex={1}>
                                        {formatDate(payment.created)}
                                    </Typography>
                                    <Typography variant="caption">
                                        {formatNumber(payment.amount_received)}
                                    </Typography>
                                    {Boolean(payment.amount_refunded) && (
                                        <Typography variant="caption" color="red">
                                            {formatNumber(payment.amount_refunded)}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    );
                },
            },

            {
                field: "totalPayment",
                headerName: "Total Payment",
                width: 100,
                type: "number",
                valueGetter: (value) => value || 0,
                renderCell: (params) => {
                    return (
                        <Box sx={{ overflowY: "auto" }} height="100%" p={1}>
                            <Typography variant="body2">
                                {formatNumber(params.row.totalPayment || 0)}
                            </Typography>
                        </Box>
                    );
                },
            },

            {
                field: "mrr",
                headerName: "MRR",
                width: 100,
                type: "number",
                valueGetter: (value) => value || 0,
                renderCell: (params) => {
                    return (
                        <Box sx={{ overflowY: "auto" }} height="100%" p={1}>
                            <Typography variant="body2">{formatNumber(params.row.mrr || 0)}</Typography>
                        </Box>
                    );
                },
            },

            {
                field: "subscriptions",
                headerName: "Subscriptions",
                width: 200,
                renderCell: (params) => {
                    const subscriptions = params.value as SubscriptionInfo[];
                    if (!subscriptions) return null;

                    return (
                        <Box sx={{ overflowY: "auto" }} height="100%" p={1}>
                            {subscriptions.map((subcription) => {
                                if (!subcription) return null;
                                if (subcription?.status === "canceled") return null;

                                return (
                                    <Box
                                        key={subcription.id}
                                        display="flex"
                                        alignItems="center"
                                        gap={1}
                                        justifyContent="space-around"
                                    >
                                        <Typography variant="caption" flex={1}>
                                            {formatDate(subcription.created)}
                                        </Typography>
                                        <Typography variant="caption">
                                            {formatNumber(subcription.amount)}
                                        </Typography>
                                        <Typography variant="caption">{subcription.interval}</Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                    );
                },
            },
        ],
        [user, rows]
    );

    const handleShowOnlyActive = () => {
        setShowActive((prev) => !prev);
    };

    const handleShowOnlyPaid = () => {
        setShowPaid((prev) => !prev);
    };

    const displayRows = useMemo(() => {
        console.log("Hello");
        let newRows = [...rows];

        if (showActive) {
            newRows = newRows.filter((row) => row.addOn === "whatsappCloud");
        }

        if (showPaid) {
            newRows = newRows.filter((row) => Boolean(row.totalPayment));
        }

        return newRows;
    }, [showPaid, showActive, rows]);

    const handleSeedUsersUplines = async () => {
        const collectionRef = collection(db, "users");
        const snapshot = await getDocs(collectionRef);
        const users = mapSnapshot<UserType>(snapshot);
        console.log(users);

        const seedUserUplines = async (user: UserType) => {
            if (!user) return;

            const uplineIds = [user.id];
            const uplineNames = [user.displayName];
            let uplineId = user?.referral;
            let uplineName = user?.displayName;

            while (uplineId) {
                uplineIds.push(uplineId);

                const uplineRef = doc(db, "users", uplineId);
                const uplineSnapshot = await getDoc(uplineRef);
                const uplineData: UserType = { ...uplineSnapshot.data(), id: uplineSnapshot.id };
                console.log("uplineId: ", uplineData.id);
                uplineId = uplineData.referral;
                uplineName = uplineData.displayName || "no name";
                uplineNames.push(uplineName);
                if (!uplineId) break;
            }
            const docRef = doc(db, "users", user.id);
            await updateDoc(docRef, { uplineIds, uplineNames });
            console.log("Updated uplineIds for user: ", user.id, uplineIds, uplineNames);
        };

        users.forEach(async (user) => {
            await seedUserUplines(user);
        });
    };

    return (
        <Box>
            <Box width="100%" height="calc(100vh - 150px)">
                <Box m={1} display="flex" alignItems={"center"} gap={1} justifyContent={"space-between"}>
                    {user?.role === "Super Admin" && (
                        <Button variant="contained" color="primary" onClick={handleSeedUsersUplines}>
                            Seed users uplines
                        </Button>
                    )}
                    <Box display="flex" alignItems={"center"} gap={1}>
                        <Typography>Total Revenue:</Typography>
                        <Chip label={formatNumber(totalRevenue)} color="primary" size="small" />
                        <Typography>MRR:</Typography>
                        <Chip label={formatNumber(totalMrr)} color="primary" size="small" />
                        <Typography>MRC:</Typography>
                        <Chip label={formatNumber(totalMrc)} color="primary" size="small" />
                    </Box>
                </Box>
                <DataGrid
                    rows={displayRows}
                    columns={columns}
                    loading={loading}
                    slots={{
                        toolbar: CustomToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rowHeight={100}
                    slotProps={{
                        toolbar: {
                            handleShowOnlyPaid,
                            handleShowOnlyActive,
                            showActive,
                            showPaid,
                            handleChange: () => console.log("Change"),
                            handleDelete: () => console.log("Delete"),
                            disabled: () => rowSelectionModel.length === 0,
                        },
                    }}
                    filterModel={filterModel}
                    onFilterModelChange={(newFilterModel) => {
                        setFilterModel(newFilterModel);
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}

                    // checkboxSelection
                />
            </Box>
        </Box>
    );
}

interface CustomToolbarProps {
    disabled: boolean;
    showActive: boolean;
    handleDelete: () => void;
    handleChange: () => void;
    handleShowOnlyPaid: () => void;
}

declare module "@mui/x-data-grid" {
    interface ToolbarPropsOverrides {
        props: CustomToolbarProps;
    }
}

function CustomToolbar(props: NonNullable<GridSlotsComponentsProps["toolbar"]>) {
    const { user } = useContext(AuthContext) as { user: UserType };
    const {
        showActive,
        showPaid,
        handleShowOnlyActive,
        handleShowOnlyPaid,
        handleDelete,
        handleChange,
        disabled = true,
    } = props;

    return (
        <GridToolbarContainer>
            <GridToolbar />
            <GridToolbarQuickFilter />
            <Button
                variant="contained"
                onClick={handleShowOnlyActive}
                size="small"
                color={showActive ? "secondary" : "primary"}
            >
                {showActive ? "Show All" : "Show Active"}
            </Button>
            <Button
                variant="contained"
                onClick={handleShowOnlyPaid}
                size="small"
                color={showPaid ? "secondary" : "primary"}
            >
                {showPaid ? "Show All" : "Show Paid"}
            </Button>

            {/* Change display to flex to enable the buttons */}
            <Box display="none" alignItems="center" pt="4px">
                <Button color="error" onClick={handleDelete} disabled={disabled} size="small">
                    Delete
                </Button>
                {user?.role === "Super Admin" && (
                    <Button color="warning" onClick={handleChange} disabled={disabled} size="small">
                        Add/Edit Field
                    </Button>
                )}
            </Box>
        </GridToolbarContainer>
    );
}
