import { useEffect, useState } from "react";
import useColors from "../../hooks/useColors";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import { Box, Button, DialogTitle, TextField, Typography } from "@mui/material";
import { Name } from "../../themes/themes";
import { db } from "../../firebase/firebase-utils";

const FlowComponent = ({ user, onClose }) => {
    const [flows, setFlows] = useState([]);
    const [currentFlow, setCurrentFlow] = useState();
    const [currentFlowId, setCurrentFlowId] = useState();

    const colors = useColors();

    useEffect(() => {
        const collectionRef = collection(db, "users", user.id, "whatsappFlows");
        const activeFlowsQuery = query(collectionRef, where("status", "==", "active"));

        const unsubscribe = onSnapshot(activeFlowsQuery, (snapshot) => {
            const data = mapSnapshot(snapshot);
            setFlows(data);
        });

        return () => {
            unsubscribe();
        };
    });

    useEffect(() => {
        if (!currentFlowId) return;
        const flow = flows.find((flow) => flow.id === currentFlowId);
        setCurrentFlow(flow);
    }, [currentFlowId]);

    const handleClickAssign = async () => {
        onClose(currentFlowId, currentFlow);
    };

    return (
        <>
            <DialogTitle>
                <Box display="flex" gap={3}>
                    <Typography variant="h5">Flows</Typography>
                    <Button variant="contained" disabled={!currentFlowId} onClick={handleClickAssign}>
                        Assign
                    </Button>
                </Box>
            </DialogTitle>
            <Box p={2} display="flex" height={"100%"} gap={1}>
                <Box
                    sx={{
                        width: "40%",
                        height: "100%",
                        border: `1px solid ${colors.topBarLine}`,
                        overflowY: "auto",
                    }}
                    display="flex"
                    flexDirection={"column"}
                >
                    {flows.map((flow) => (
                        <Box
                            key={flow.id}
                            onClick={() => {
                                console.log("clicked flow", flow);
                                setCurrentFlowId(flow.id);
                            }}
                            sx={{
                                borderBottom: `1px solid ${colors.topBarLine}`,
                                "&:hover": {
                                    backgroundColor: colors.boxHighlight, // Replace 'primary.main' with your desired color
                                },
                                cursor: "pointer",
                            }}
                            p={2}
                        >
                            <Name bold={currentFlowId === flow.id}>{flow.name}</Name>
                        </Box>
                    ))}
                </Box>
                <Box flex={1} display="flex" flexDirection={"column"} justifyContent={"flex-start"} gap={1}>
                    <TextField
                        multiline
                        rows={4}
                        value={currentFlow?.system || ""}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                    <TextField
                        multiline
                        rows={20}
                        value={currentFlow?.flowDesign || ""}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default FlowComponent;
