import AddIcon from "@mui/icons-material/Add";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    FormControl,
    Icon,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FullDialog from "../../components/FullDialog";
import { useConfirmation } from "../../context-utils/ConfirmationContext";
import { AuthContext } from "../../context/AuthContext";
import { LeadsContext } from "../../context/LeadsContext";
import { db } from "../../firebase/firebase-utils";
import useChooseFriend from "../../hooks/useChooseFriend";
import useColors from "../../hooks/useColors";
import { GridContainer, GridFlexBox, Loading, Name } from "../../themes/themes";
import formatDate from "../../utils-functions/formatDate";
import mapSnapshot from "../../utils-functions/mapSnapshot";
import LeadCardPage from "../LeadCardPage";
import { arrayIsEmpty } from "./../../utils-functions/arrayIsEmpty";
import convertDate from "./../../utils-functions/convertDate";
import formatNumber from "./../../utils-functions/formatNumber";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import getLeadsByPageId from "./getLeadsByPageId";
import LeadActionsComponent from "./LeadActionsComponent";
import ManageLabels from "./ManageLabels";

export default function LeadsTablePageV3() {
    const { user } = useContext(AuthContext);
    const {
        sourceLeads,
        setSourceLeads,
        assignedLeads,
        setAssignedLeads,
        reload,
        setReload,
        year,
        setYear,
        currentPageId,
        setCurrentPageId,
        pages,
        setPages,
    } = useContext(LeadsContext);

    const [loading, setLoading] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [leadsType, setLeadsType] = useState(0);
    const [loadAll, setLoadAll] = useState(false);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false, email: false });
    const [currentLead, setCurrentLead] = useState(null);
    const [openLabel, setOpenLabel] = useState(false);
    const [openCard, setOpenCard] = useState(false);
    const [type, setType] = useState("SOURCE_LEADS");
    const [hideAssignedLeads, setHideAssignedLeads] = useState(true);
    const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page: 0 });

    useEffect(() => {
        if (leadsType === 0) {
            if (sourceLeads?.length === 0) setReload(true);
            setType("SOURCE_LEADS");
        } else {
            if (assignedLeads?.length === 0) setReload(true);
            setType("ASSIGNED_LEADS");
        }
    }, [leadsType]);

    useEffect(() => {
        setRowSelectionModel([]);
    }, [hideAssignedLeads]);

    useEffect(() => {
        if (!user) return;

        const loadPages = async () => {
            const collectionRef = collection(db, "pages");
            const q = query(collectionRef, where("admins", "array-contains", user.id));
            const snapshot = await getDocs(q);
            const pages = mapSnapshot(snapshot);
            setPages([{ name: "[none]", id: "" }, { name: "[ALL]", id: "all" }, ...pages]);
        };
        loadPages();
    }, []);

    useEffect(() => {
        const loadLeads = async () => {
            setLoading(true);
            const leads = await getLeadsByPageId(currentPageId, user, loadAll, type, year);
            console.log(leads);
            if (type === "SOURCE_LEADS") {
                setSourceLeads(leads);
            } else {
                setAssignedLeads(leads);
            }
            setReload(false);
            setLoadAll(false);
            setLoading(false);
        };
        if (reload === false) return;
        loadLeads();
    }, [currentPageId, loadAll, reload, type]);

    const colors = useColors();

    const handleDeleteAssignment = async (lead, index) => {
        const docRef = doc(db, "leads", lead.id);
        const newAssignments = [...lead.assignments];
        newAssignments.splice(index, 1);
        await updateDoc(docRef, { assignments: newAssignments });

        if (type === "SOURCE_LEADS") {
            setSourceLeads((prev) => {
                const index = prev.findIndex((l) => l.id === lead.id);
                const newSourceLeads = [...prev];
                newSourceLeads.splice(index, 1, { ...lead, assignments: newAssignments });
                return newSourceLeads;
            });
        } else {
            setAssignedLeads((prev) => {
                const index = prev.findIndex((l) => l.id === lead.id);
                const newAssignedLeads = [...prev];
                newAssignedLeads.splice(index, 1, { ...lead, assignments: newAssignments });
                return newAssignedLeads;
            });
        }
    };

    const renderAssignments = (params) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    height: "52px",
                    overflowY: "auto",
                }}
            >
                {params.row.assignments?.map((assignment, i) => (
                    <Box
                        display={"flex"}
                        alignItems="center"
                        gap={1}
                        key={`${assignment.assign.email || ""}${i}`}
                        pb={"4px"}
                    >
                        <Box display="flex" flexDirection={"column"}>
                            <Box
                                sx={{
                                    backgroundColor: colors.chipColor,
                                    borderRadius: "5px",
                                    padding: "2px 5px",
                                }}
                            >
                                <Name fs10>{assignment.assign.name}</Name>
                            </Box>
                            <Name fs8>{formatDate(convertDate(assignment.date), true)}</Name>
                        </Box>
                        <IconButton size="small" onClick={() => handleDeleteAssignment(params.row, i)}>
                            <Icon fontSize="small">clear</Icon>
                        </IconButton>
                    </Box>
                ))}
            </Box>
        );
    };

    const columns = [
        { field: "id", headerName: "ID" },
        {
            field: type === "SOURCE_LEADS" ? "created" : "assignedDate",
            headerName: "Date",
            width: 100,
            valueGetter: (value) => convertDate(value),
            renderCell: (params) => {
                const dateTime = formatDate(params.value, true);
                const date = dateTime.split(" ")[0];
                const time = dateTime.split(" ")[1];
                return (
                    <Box>
                        <Typography fontSize="11px">{date}</Typography>
                        <Typography fontSize={"10px"}>{time}</Typography>
                    </Box>
                );
            },
        },
        {
            field: "name",
            headerName: "Name",
            width: 150,
            renderCell: (params) => (
                <Box p={1} display="flex" alignItems={"center"}>
                    <Chip
                        label={params.value}
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            console.log(params.row);
                            setCurrentLead(params.row);
                            setOpenCard(true);
                        }}
                        color={params.row.type === "ASSIGNED_LEAD" ? "primary" : "warning"}
                        size="small"
                    />
                </Box>
            ),
        },
        { field: "email", headerName: "Email", width: 180 },
        { field: "phone", headerName: "Phone", width: 150 },
        {
            field: "labels",
            headerName: "Labels",
            width: 180,
            valueGetter: (value) => {
                if (value) {
                    let labelsString = "";
                    value.forEach((label) => {
                        labelsString += label.label + ", ";
                    });
                    return labelsString;
                }
            },
            renderCell: (params) => (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        height: "52px",
                        overflowY: "auto",
                    }}
                >
                    {!arrayIsEmpty(params.row.labels) &&
                        params.row.labels.map((label, i) => (
                            <Box
                                sx={{
                                    backgroundColor: label.color,

                                    borderRadius: "5px",
                                    padding: "2px 5px",
                                    margin: "2px",
                                }}
                                key={`${label.label || ""}${i}`}
                            >
                                <Name
                                    fs10
                                    color={label.fontColor}
                                    onClick={() => {
                                        setCurrentLead(params.row);
                                        setOpenLabel(true);
                                    }}
                                    cp
                                >
                                    {label.label}
                                </Name>
                            </Box>
                        ))}
                    <IconButton
                        onClick={() => {
                            setCurrentLead(params.row);
                            setOpenLabel(true);
                        }}
                        size="small"
                    >
                        <AddIcon />
                    </IconButton>
                </Box>
            ),
        },
        {
            field: "remarks",
            headerName: "Remarks",
            editable: true,
            width: 200,
        },
        {
            field: "lastAction",
            headerName: "Last Action",
            width: 300,
            valueGetter: (value) =>
                value ? `${formatDate(convertDate(value.created), true)} - ${value.action}` : "",
            renderCell: (params) => <LeadActionsComponent lead={params.row} leadsMode={type} />,
        },
        {
            field: "assignments",
            headerName: "Assigned To",
            width: 150,
            valueGetter: (value) =>
                value?.length ? value.map((assignment) => assignment.assign.name).join(", ") : "",
            renderCell: (params) => renderAssignments(params),
        },
        { field: "assignBy", headerName: "Assigned By", width: 140 },
        { field: "pageName", headerName: "Page", width: 200 },
        { field: "campaignName", headerName: "Ad Set", width: 140 },
        { field: "source", headerName: "Source", width: 140 },
    ];

    const handleChangePage = (e) => {
        setReload(true);
        setCurrentPageId(e.target.value);
    };

    const handleChangeType = (event, newValue) => {
        setLeadsType(newValue);
    };

    const handleSetLoadAll = () => {
        setLoadAll(true);
        setReload(true);
    };

    const confirmation = useConfirmation();

    const deleteLeads = async () => {
        const response = await confirmation("Delete leads", "Are you sure you want to delete these leads?");
        if (!response) return;

        console.log(rowSelectionModel);
        const collectionRef = collection(db, "leads");
        rowSelectionModel.forEach(async (id) => {
            await deleteDoc(doc(collectionRef, id));
        });

        setSourceLeads((prev) => prev.filter((lead) => !rowSelectionModel.includes(lead.id)));
    };

    const leads = useMemo(() => {
        if (type === "SOURCE_LEADS") {
            if (hideAssignedLeads) {
                return sourceLeads?.filter((lead) => lead.type !== "ASSIGNED_LEAD") || [];
            }
            return sourceLeads || [];
        } else {
            return assignedLeads || [];
        }
    }, [type, sourceLeads, assignedLeads, hideAssignedLeads]);

    const navigate = useNavigate();

    const handleFixAssignedLeads = () => {
        navigate("/fix");
    };

    const friend = useChooseFriend();

    const handleAssignLeads = async () => {
        const response = await friend();
        if (!response) return;

        // Temporary storage for changes to be applied in bulk after the loop
        let bulkUpdates = [];

        for (const id of rowSelectionModel) {
            const docRef = doc(db, "leads", id);
            const assignment = {
                assign: { email: response.id, name: response.displayName },
                assignBy: user.id,
                date: new Date(),
            };
            await updateDoc(docRef, { assignments: arrayUnion(assignment) });

            // update sourceLead
            const lead = { ...leads.find((lead) => lead.id === id) };
            lead.assignments = lead.assignments ? [...lead.assignments, assignment] : [assignment];

            // Prepare the newLead
            const newId = `${id}:${response.id}`;
            const newDocRef = doc(db, "leads", newId);
            const newLead = {
                ...lead,
                id: newId,
                assignments: [assignment],
                assignBy: user.id,
                assignedDate: new Date(),
                lastAction: null,
                type: "ASSIGNED_LEAD",
                warriors: [response.id],
            };
            await setDoc(newDocRef, newLead, { merge: true });

            // Add to bulk updates
            bulkUpdates.push({ original: lead, newLead });
        }

        // Apply all updates in bulk
        setSourceLeads((prev) => {
            const newSourceLeads = [...prev];

            bulkUpdates.forEach(({ original, newLead }) => {
                // Find the index for each original lead in the updated array to ensure accuracy
                const index = newSourceLeads.findIndex((lead) => lead.id === original.id);
                if (index !== -1) {
                    // Replace original lead with updated original and newLead
                    newSourceLeads.splice(index, 1, original, newLead);
                } else {
                    // If for some reason the lead isn't found, you might just push the newLead
                    // Or handle this case as per your app's logic
                    newSourceLeads.push(newLead);
                }
            });

            return newSourceLeads;
        });

        toast.success("Leads assigned successfully");
    };

    const handleClickRefresh = () => {
        setReload(true);
    };

    const handleUpdateLead = (lead) => {
        if (type === "SOURCE_LEADS") {
            setSourceLeads((prev) => {
                const index = prev.findIndex((l) => l.id === lead.id);
                const newSourceLeads = [...prev];
                newSourceLeads.splice(index, 1, lead);
                return newSourceLeads;
            });
        } else {
            const index = assignedLeads.findIndex((l) => l.id === lead.id);
            setAssignedLeads((prev) => {
                const newAssignedLeads = [...prev];
                newAssignedLeads.splice(index, 1, lead);
                return newAssignedLeads;
            });
        }
    };

    const handleChangePaginationModel = (e) => {
        setPaginationModel((prev) => ({ ...prev, page: e.target.value }));
    };

    const handleProcessRowUpdate = async (updatedRow) => {
        const remarks = updatedRow.remarks || "";
        const docRef = doc(db, "leads", updatedRow.id);
        await updateDoc(docRef, { remarks });

        if (type === "SOURCE_LEADS") {
            setSourceLeads((prev) => {
                const index = prev.findIndex((lead) => lead.id === updatedRow.id);
                const newSourceLeads = [...prev];
                newSourceLeads.splice(index, 1, { ...updatedRow, remarks });
                return newSourceLeads;
            });
        } else {
            setAssignedLeads((prev) => {
                const index = prev.findIndex((lead) => lead.id === updatedRow.id);
                const newAssignedLeads = [...prev];
                newAssignedLeads.splice(index, 1, { ...updatedRow, remarks });
                return newAssignedLeads;
            });
        }

        return updatedRow;
    };

    const handleProcessRowUpdateError = (params, error) => {
        console.log(error);
    };

    const totalPages = useMemo(() => {
        return Math.ceil(leads.length / paginationModel.pageSize);
    }, [leads]);

    const handleClickBack = () => {
        setYear((prev) => prev - 1);
        setReload(true);
    };

    const handleClickForward = () => {
        setYear((prev) => prev + 1);
        setReload(true);
    };

    return (
        <Container maxWidth="none">
            <GridContainer>
                <Loading loading={loading} />
                <GridFlexBox fs md={5} xs={12}>
                    <Tabs value={leadsType} onChange={handleChangeType}>
                        <Tab label="Source Leads" />
                        <Tab label="Assigned Leads" />
                    </Tabs>
                    {type === "ASSIGNED_LEADS" && (
                        <IconButton onClick={handleFixAssignedLeads}>
                            <Icon>build</Icon>
                        </IconButton>
                    )}
                    {type === "SOURCE_LEADS" && (
                        <Box display="flex" alignItems={"center"} gap={"4px"}>
                            <Checkbox
                                checked={hideAssignedLeads}
                                onChange={(e) => setHideAssignedLeads(e.target.checked)}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                            <Typography>Hide assigned leads</Typography>
                        </Box>
                    )}
                    <IconButton onClick={handleClickRefresh}>
                        <Icon>refresh</Icon>
                    </IconButton>
                </GridFlexBox>
                <GridFlexBox md={7} xs={12} gap={1}>
                    <Box display="flex" alignItems={"center"} gap={1}>
                        <IconButton onClick={handleClickBack}>
                            <Icon>arrow_back</Icon>
                        </IconButton>
                        <Typography>{year}</Typography>
                        <IconButton onClick={handleClickForward}>
                            <Icon>arrow_forward</Icon>
                        </IconButton>
                    </Box>
                    <Typography>{`No. of leads: ${
                        leads?.length ? formatNumber(leads.length, 0) : 0
                    }`}</Typography>
                    {/* <Button sx={{ marginRight: "8px", marginLeft: "8px" }} onClick={handleSetLoadAll}>
                        Load All
                    </Button> */}
                    <FormControl fullWidth>
                        <InputLabel size="small">Page</InputLabel>
                        <Select value={currentPageId} label="Page" onChange={handleChangePage} size="small">
                            {pages.map((page) => (
                                <MenuItem value={page.id} key={page.id}>
                                    {page.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridFlexBox>
                <GridFlexBox fs>
                    <Paper sx={{ height: "80vh", width: "100%", overflowY: "auto" }}>
                        <DataGrid
                            rows={leads ? leads : []}
                            columns={columns}
                            slots={{
                                toolbar: () => (
                                    <CustomToolbar
                                        onDelete={deleteLeads}
                                        onAssign={handleAssignLeads}
                                        disabled={rowSelectionModel.length === 0}
                                        hideAssignedLeads={hideAssignedLeads}
                                    />
                                ),
                                noRowsOverlay: () => CustomNoRowsOverlay({ pageId: currentPageId }),
                            }}
                            onRowSelectionModelChange={(newRowSelectionModel) => {
                                setRowSelectionModel(newRowSelectionModel);
                            }}
                            rowSelectionModel={rowSelectionModel}
                            initialState={{}}
                            paginationModel={paginationModel}
                            onPaginationModelChange={setPaginationModel}
                            rowsPerPageOptions={[5, 10, 20, 100]}
                            checkboxSelection
                            disableRowSelectionOnClick
                            // isRowSelectable={(params) => {
                            //     if (type === "ASSIGNED_LEADS") return true;
                            //     if (params.row.type === "ASSIGNED_LEAD") {
                            //         return false;
                            //     } else {
                            //         return true;
                            //     }
                            // }}
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                            // getRowHeight={() => "auto"}
                            processRowUpdate={(updatedRow) => handleProcessRowUpdate(updatedRow)}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                        />
                    </Paper>
                </GridFlexBox>
                <GridFlexBox fe gap={1}>
                    <Typography>Page: </Typography>
                    <Select value={paginationModel.page} onChange={handleChangePaginationModel} size="small">
                        {/* map total number of pages */}
                        {Array.from({ length: totalPages }, (_, i) => (
                            <MenuItem key={i} value={i}>
                                {i + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </GridFlexBox>
            </GridContainer>
            <FullDialog
                open={openCard}
                handleClose={() => setOpenCard(false)}
                title={"Lead Card"}
                Component={
                    <LeadCardPage
                        lead={currentLead}
                        handleClose={() => setOpenCard(false)}
                        leadsMode={type}
                        handleUpdateLead={handleUpdateLead}
                    />
                }
            />
            <FullDialog
                open={openLabel}
                handleClose={() => setOpenLabel(false)}
                title={"Labels"}
                Component={
                    <ManageLabels
                        lead={currentLead}
                        setLead={setCurrentLead}
                        handleClose={() => setOpenLabel(false)}
                        setLeads={type === "SOURCE_LEADS" ? setSourceLeads : setAssignedLeads}
                    />
                }
            />
        </Container>
    );
}

function CustomToolbar({ onDelete, onAssign, disabled, hideAssignedLeads }) {
    return (
        <GridToolbarContainer>
            <GridToolbar
                csvOptions={{
                    delimiter: ",",
                    utf8WithBom: true,
                }}
            />
            <GridToolbarQuickFilter />
            <Box display="flex" alignItems={"center"} pt="4px">
                <Button
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={onDelete}
                    disabled={disabled}
                    size="small"
                >
                    Delete
                </Button>
                <Button
                    startIcon={<AssignmentIndOutlinedIcon />}
                    color="primary"
                    onClick={onAssign}
                    disabled={disabled || !hideAssignedLeads}
                    size="small"
                >
                    Assign
                </Button>
            </Box>
        </GridToolbarContainer>
    );
}
