import React from "react";
import CalendarView from "./calendar/view/calendar-view";
import { Box } from "@mui/material";

const AppointmentCalenderPage = () => {
    return (
        <Box sx={{marginTop: '20px',marginBottom:'40px'}}>
            <CalendarView />
        </Box>
    );
};

export default AppointmentCalenderPage;
