import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";

export default function loadWhatsappBlastHistory(user, setWhatsappBlastHistory, size = 10) {
    try {
        const collectionRef = collection(db, "users", user.id, "whatsappBlasts");
        const q = query(collectionRef, orderBy("date", "desc"), limit(size));
        return onSnapshot(q, (snapshot) => {
            const whatsappBlastHistory = mapSnapshot(snapshot);
            setWhatsappBlastHistory(whatsappBlastHistory);
        });
    } catch (err) {
        console.log(err);
    }
}
