export default function formatDate(d, time = false) {
    if (!d) return "";

    // check if d is a date
    if (!(d instanceof Date)) {
        return "";
    }

    let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    // Pad single digit month and day with leading zero
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const formattedDate = [year, month, day].join("-");

    // Convert 24-hour time to 12-hour format
    let hour = d.getHours(),
        minute = d.getMinutes(),
        ampm = hour >= 12 ? "PM" : "AM";

    hour = hour % 12;
    hour = hour ? hour : 12; // the hour '0' should be '12'
    minute = minute < 10 ? "0" + minute : minute;

    const formattedTime = `${hour}:${minute}${ampm}`;

    if (!time) {
        return formattedDate;
    } else {
        return `${formattedDate} ${formattedTime}`;
    }
}
