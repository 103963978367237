export function convertPhone(number) {
	if (number) {
		let tel = number.toString();
		tel = tel.replace(/\D+/g, "");
		if (tel[0] === "0") {
			tel = "6" + tel;
		}
		if (tel[0] === "1") {
			tel = "60" + tel;
		}
		return tel;
	} else {
		return null;
	}
}
