import React from "react";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import FullDialog from "../FullDialog";
import UploadDocument from "./UploadDocument";
import { AuthContext } from "./../../context/AuthContext";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default function UploadFilesButton({
    handleFile,
    label = "Upload",
    icon = null,
    toolTip = "",
    variant = "contained",
    hover = true,
    onClose = () => {},
}) {
    const [open, setOpen] = React.useState(false);
    const { user } = React.useContext(AuthContext);

    const handleClose = (files) => {
        setOpen(false);
        saveFiles(files);
        handleFile(files);
    };

    const handleClickExistingFile = (files) => {
        setOpen(false);
        handleFile(files);
    };

    const saveFiles = async (files) => {
        try {
            const collectionRef = collection(db, "users", user.id, "files");
            files.forEach(async (file) => {
                await addDoc(collectionRef, file);
                console.log("File added.", file);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const hoverStyle = () => {
        if (!hover) {
            return {
                ":hover": {
                    backgroundColor: "transparent", // Keeps the background color unchanged on hover
                    "@media (hover: none)": {
                        backgroundColor: "transparent", // Ensures the style applies for devices that can't hover
                    },
                },
            };
        } else {
            return {};
        }
    };

    const displayLabel = () => {
        if (!hover) {
            return <Typography> {label}</Typography>;
        } else {
            return label;
        }
    };

    return (
        <React.Fragment>
            {icon ? (
                <Tooltip title={toolTip}>
                    <IconButton
                        style={{ padding: "0 2px 0 2px" }}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(true);
                        }}
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            ) : (
                <Button variant={variant} onClick={() => setOpen(true)} sx={hoverStyle()}>
                    {displayLabel()}
                </Button>
            )}

            <FullDialog
                title={"Upload Files"}
                open={open}
                handleClose={() => {
                    onClose();
                    setOpen(false);
                }}
                Component={
                    <UploadDocument
                        handleFile={handleClose}
                        handleClickExistingFile={handleClickExistingFile}
                    />
                }
            />
        </React.Fragment>
    );
}
