export const SELECT_ITEM = {
    // propertyType: [
    //     "Single Storey Landed House",
    //     "Double Storey Landed House",
    //     "2.5 Storey Landed House",
    //     "Triple Storey Landed House",
    //     "Apartment",
    //     "Condominium",
    //     "Terrace House",
    //     "Semi-Detached House",
    //     "Detached House",
    //     "Townhouse",
    //     "Bungalow",
    //     "Villa",
    //     "Duplex",
    //     "Studio",
    //     "Service Residence",
    //     "Flat",
    //     "Penthouse",
    //     "Link House",
    //     "Cluster House",
    //     "Shop Lot",
    //     "Office",
    //     "Retail",
    //     "Industrial",
    //     "Warehouse",
    //     "Land",
    //     "Agricultural Land",
    //     "Commercial Land",
    //     "Residential Land",
    //     "Mixed Development",
    //     "SOHO (Small Office Home Office)",
    //     "Apartment/Flat",
    //     "Commercial Property",
    //     "Residential Property",
    //     "Other"
    // ],
    propertyType: {
        Residential: [
            "Apartment",
            "Flat",
            "Condominium",
            "Serviced Residence",
            "Townhouse",
            "1-Sty Terrace/Link House",
            "1.5-Sty Terrace/Link House",
            "2-Sty Terrace/Link House",
            "2.5-Sty Terrace/Link House",
            "3-Sty Terrace/Link House",
            "3.5-Sty Terrace/Link House",
            "4-Sty Terrace/Link House",
            "4.5-Sty Terrace/Link House",
            "5-Sty Terrace/Link House",
            "Cluster House",
            "Bungalow",
            "Bungalow Land",
            "Semi-Detached House",
            "Villa",
            "3-Storey Semi-Detached Homes",
            "Residential Land"
        ],
        Commercial: [
            "Retail Office",
            "Retail Space",
            "Shop",
            "Shop Office",
            "Office",
            "Sofo",
            "Soho",
            "Sovo",
            "Commercial Bungalow",
            "Commercial Semi-D",
            "Designer Suites",
            "Business Centre",
            "Hotel/Resort",
            "Commercial Land",
            "Suites",
            "Luxury Suites"
        ],
        Industrial: [
            "Factory",
            "Semi-D Factory",
            "Warehouse",
            "Detached Factory",
            "Light Industrial",
            "Link Factory",
            "Cluster Factory",
            "Industrial Land"
        ],
        // ... other categories
        Agricultural: ["Agricultural Land"]
    },
    tenure: ["Freehold", "Leasehold"],
    occupancy: ["Vacant", "Tenanted", "Owner-Occupied"],
    landTitle: ["Residential", "Commercial", "Industrial", "Agricultural"],
    facingDirection: ["North", "Northeast", "East", "Southeast", "South", "Southwest", "West", "Northwest"],
    bumiLot: ["Bumi Lot", "Non Bumi Lot"],
    furnish: ["Fully Furnish", "Partly Furnish", "Unfurnish"],
    status: ["Active", "Disabled"],
    propertyPurpose: ["For Sale", "For Rent", "For Auction"],
    propertyState: [
        "Johor",
        "Kedah",
        "Kelantan",
        "Kuala Lumpur",
        "Labuan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Pulau Pinang",
        "Perak",
        "Perlis",
        "Putrajaya",
        "Sabah",
        "Sarawak",
        "Selangor",
        "Terengganu"
    ]
};
