import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function saveWhatsappBlast(names, message, files, user) {
    let filesRef = Array.from(files).map((file) => ({ name: file.name, downloadURL: file.downloadURL }));
    try {
        const collectionRef = collection(db, "users", user.id, "whatsappBlasts");
        await addDoc(collectionRef, { date: new Date(), names, message, files: filesRef });
        console.log("WhatsappBlast successfully written!");
    } catch (error) {
        console.log(error);
    }
}
