import React, { createContext, useContext, useState } from "react";
import NotificationComponent from "./NotificationComponent";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [message, setMessage] = useState();
    const [type, setType] = useState();
    const [open, setOpen] = useState(false);

    // const { openNotification } = useContext(NotificationContext);
    // openNotification(message, type);
    const openNotification = async (message, type) => {
        setMessage(message);
        setOpen(true);
        setType(type);
    };

    return (
        <NotificationContext.Provider value={{ openNotification }}>
            {children}
            <NotificationComponent open={open} setOpen={setOpen} message={message} type={type} />
        </NotificationContext.Provider>
    );
};

export default function useNotification() {
    const { openNotification } = useContext(NotificationContext);
    return openNotification;
}
