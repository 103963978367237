import moment from "moment";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebase-utils";
import { notification } from "../utils-functions/notification";
import addFolder from "./addFolder";

export async function saveManualLead({ name, phone, email, remark, source, date, campaign, page, user, folders }) {
    try {
        let admins = [...page.admins, user.email];
        admins = admins.filter((c, index) => admins.indexOf(c) === index);
        console.log(admins);
        let created_time = new Date(date);
        if (date === moment(new Date()).format("YYYY-MM-DD")) {
            created_time = new Date();
        }
        console.log(created_time);
        const lead = {
            created: new Date(),
            created_time: created_time,
            field_data: [
                { name: "full_name", values: [name || ""] },
                { name: "phone_number", values: [phone || ""] },
                { name: "email", values: [email || ""] }
            ],
            name: name || "",
            phone: phone || "",
            email: email || "",
            remark: remark || "",
            ownerEmail: user.email || "",
            pageName: page.name || "",
            page_id: page.id || "",
            source: source || "",
            admins: admins,
            campaignName: campaign || "",
            folders
        };

        //*added by wayne 15-3-24 : add extra check on existing leads records to avoid duplicate leads.
        // if ((lead.email && lead.email.toString().trim()) || (lead.phone && lead.phone.toString().trim())) {
        //     const queries = [];
        //     if (lead.email && lead.email.toString().trim()) {
        //         queries.push(getDocs(query(collection(db, "leads"), where("email", "==", lead.email.toString().trim()))));
        //     }
        //     if (lead.phone && lead.phone.toString().trim()) {
        //         queries.push(getDocs(query(collection(db, "leads"), where("phone", "==", lead.phone.toString().trim()))));
        //     }
        
        //     const results = await Promise.all(queries);
        
        //     const exists = results.some(snapshot => snapshot.size > 0);
        //     if (exists) {
        //         console.log("This lead already exists");
        //         return;
        //     } else {
        //         console.log("Proceed to add leads.");
        //     }
        // }            

        //*

        const collectionRef = collection(db, "leads");
        await addDoc(collectionRef, lead);
        console.log("Manual lead added into Firestore");
    } catch (error) {
        console.log(error);
        notification("Opps", error.message, "danger");
    }
}

//comment by wayne : to be use in future
// export async function saveManualLead({ name, phone, email, remark, source, date, campaign, page, user, folders }) {
//     try {
//         let admins = [...page.admins, user.email].filter((c, index, self) => self.indexOf(c) === index);

//         let created_time = date === moment(new Date()).format("YYYY-MM-DD") ? new Date() : new Date(date);

//         // Normalize email and phone
//         const normalizedEmail = email?.toLowerCase().trim();
//         const normalizedPhone = phone?.toString().trim();

//         // Check for email existence
//         let emailExists = false;
//         if (normalizedEmail) {
//             const emailQuery = query(collection(db, "leads"), where("email", "==", normalizedEmail));
//             const emailQuerySnapshot = await getDocs(emailQuery);
//             emailExists = !emailQuerySnapshot.empty;
//         }

//         if (emailExists) {
//             console.log("A lead with the same email already exists.");
//             return;
//         }

//         // Check for phone existence
//         let phoneExists = false;
//         if (normalizedPhone) {
//             const phoneQuery = query(collection(db, "leads"), where("phone", "==", normalizedPhone));
//             const phoneQuerySnapshot = await getDocs(phoneQuery);
//             phoneExists = !phoneQuerySnapshot.empty;
//         }

//         if (phoneExists) {
//             console.log("A lead with the same phone already exists.");
//             return;
//         }

//         // If no duplicates, proceed to add the lead
//         const lead = {
//             created: new Date(),
//             created_time: created_time,
//             field_data: [
//                 { name: "full_name", values: [name || ""] },
//                 { name: "phone_number", values: [normalizedPhone || ""] },
//                 { name: "email", values: [normalizedEmail || ""] }
//             ],
//             name: name || "",
//             phone: normalizedPhone || "",
//             email: normalizedEmail || "",
//             remark: remark || "",
//             ownerEmail: user.email?.toLowerCase().trim() || "",
//             pageName: page.name || "",
//             page_id: page.id || "",
//             source: source || "",
//             admins,
//             campaignName: campaign || "",
//             folders
//         };

//         await addDoc(collection(db, "leads"), lead);
//         console.log("Manual lead added into Firestore");
//     } catch (error) {
//         console.log(error);
//         notification("Opps", error.message, "danger");
//     }
// }
