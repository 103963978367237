import { Container, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { useContext, useEffect, useState, useMemo } from "react";
import { GridContainer, GridDivider, GridFlexBox, Loading, Title } from "../../themes/themes";
import { AuthContext } from "../../context/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import mapSnapshot from "../../functions/common-functions/mapSnapshot";
import { StoreContext } from "../../context/StoreContext";
import moment from "moment";
import LeadDashBoardTable from "./LeadDashBoardTable";

export default function LeadsDashBoardPage() {
    const [loading, setLoading] = useState(false);
    const [source, setSource] = useState(0); // 0 = leads owner, 1 = caller
    const { user } = useContext(AuthContext);
    const [timeRange, setTimeRange] = useState("allTime");  // Options could be "week", "month", "year", "allTime"

    const { leadDashdata, setLeadDashdata, callerDashdata, setCallerDashdata } = useContext(StoreContext);

    const countRate = (rowData, type) => {
        if (type === 0) {
            return ((rowData.numberOfAppointments / rowData.totalLeadAssignment) * 100).toFixed(2);
        }
        else if (type === 1) {
            return ((rowData.numberOfBookedSales / rowData.totalLeadAssignment) * 100).toFixed(2);
        }
        else if (type === 2) {
            let result = ((rowData.timeToTakeAction / rowData.totalLeadAssignment)).toFixed(2);
            if (result == 0) {
                return "No data"
            }
            else {
                return result;
            }
        }
        else {
            return;
        }
    }

    const calculateDashData = (array) => {
        console.log("Filtering with time range:", timeRange);

        let startOfPeriod;
        if (timeRange === "week") {
            startOfPeriod = moment().startOf('week');
        } else if (timeRange === "month") {
            startOfPeriod = moment().startOf('month');
        } else if (timeRange === "year") {
            startOfPeriod = moment().startOf('year');
        }

        const filteredArray = array.map((item) => {
            let filteredLeads = item.assignedLeads.filter((lead) => {
                const leadAssignedDate = moment(new Date(lead.assignedDate.seconds * 1000));
                console.log("Checking leadAssignedDate", leadAssignedDate.format());

                if (timeRange === "week") {
                    return leadAssignedDate.isAfter(startOfPeriod);
                } else if (timeRange === "month") {
                    return leadAssignedDate.isAfter(startOfPeriod);
                } else if (timeRange === "year") {
                    return leadAssignedDate.isAfter(startOfPeriod);
                } else {
                    return true;
                }
            });

            console.log("Filtered leads:", filteredLeads);

            return {
                ...item,
                assignedLeads: filteredLeads
            };
        });

        filteredArray.forEach((item) => {
            let numberOfAppointments = 0;
            let numberOfBookedSales = 0;
            let numberOfUntouchedLeads = 0;
            let timeToTakeAction = 0;
            item.assignedLeads.forEach((lead) => {
                if (lead.appointments) numberOfAppointments++;
                if (lead.bookedSales) numberOfBookedSales++;
                if (!lead.lastAction) numberOfUntouchedLeads++;

                if ("firstAction" in lead) {
                    let leadAssignedDate = moment(new Date(lead.assignedDate.seconds * 1000));
                    let firstActionDate = moment(new Date(lead.firstAction.created.seconds * 1000));
                    timeToTakeAction += firstActionDate.diff(leadAssignedDate, "hours");
                }
            });
            item["totalLeadAssignment"] = item.assignedLeads.length || 0;
            item["numberOfAppointments"] = numberOfAppointments;
            item["numberOfBookedSales"] = numberOfBookedSales;
            item["numberOfUntouchedLeads"] = numberOfUntouchedLeads;
            item["timeToTakeAction"] = timeToTakeAction;
            item["appointmentRate"] = countRate(item, 0);
            item["bookedSaleRate"] = countRate(item, 1);
            item["avgTimeToTakeAction"] = countRate(item, 2);

            delete item.assignedLeads;
        });

        const cleanedArray = filteredArray.filter((item) => !isNaN(item.appointmentRate));

        return cleanedArray;
    }

    const getLeadDashData = async () => {
        console.log("getLeadDashData init");
        setLoading(true);
        const collectionRef = collection(db, "leads");
        const q = query(collectionRef, where("admins", "array-contains", user.id));
        try {
            const snapshot = await getDocs(q);
            const leads = mapSnapshot(snapshot);

            let array = [];
            leads.forEach((lead) => {
                if (lead.id.includes(":")) {
                    const assignedId = lead.id.split(":")[1];
                    const found = array.find((item) => item.id === assignedId);
                    if (found) {
                        found.assignedLeads.push(lead);
                    } else {
                        const obj = {
                            id: assignedId,
                            name: lead.assignments[0].assign.name,
                            assignedLeads: [lead],
                        };
                        array.push(obj);
                    }
                }
            });
            array.sort((a, b) => b.assignedLeads.length - a.assignedLeads.length);
            setLeadDashdata(array);
            console.log("leadArray: ", array);
            setLoading(false);
        } catch (err) {
            console.log(err.message);
            setLoading(false);
        }
    };

    const getCallerDashData = async () => {
        console.log("getCallerDashData init");
        setLoading(true);
        const collectionRef = collection(db, "leads");
        const q = query(collectionRef, where("warriors", "array-contains", user.id));
        try {
            const snapshot = await getDocs(q);
            const leads = mapSnapshot(snapshot);

            let array = [];
            leads.forEach((lead) => {
                if (lead.id.includes(":")) {
                    const assignedId = lead.id.split(":")[1];
                    //Only get the id that matches user's own id
                    if (assignedId === user.id) {
                        console.log("lead: ", lead);
                        const assignedBy = lead.assignments[0].assignBy;
                        console.log("assignedBy: ", assignedBy);
                        const found = array.find((item) => item.id === assignedBy);
                        if (found) {
                            found.assignedLeads.push(lead);
                        } else {
                            const obj = {
                                id: assignedBy,
                                name: assignedBy,
                                assignedLeads: [lead],
                            };
                            array.push(obj);
                        }
                    }
                }
            });
            array.sort((a, b) => b.assignedLeads.length - a.assignedLeads.length);
            setCallerDashdata(array);
            console.log("callerArray: ", array);
            setLoading(false);
        } catch (err) {
            console.log(err.message);
            setLoading(false);
        }
    }

    const calculatedLeadDashData = useMemo(() => {
        if (!leadDashdata) return null;

        return calculateDashData(leadDashdata, timeRange);
    }, [leadDashdata, timeRange]);

    const calculatedCallerDashData = useMemo(() => {
        if (!callerDashdata) return null;

        return calculateDashData(callerDashdata, timeRange);
    }, [callerDashdata, timeRange]);

    useEffect(() => {
        if (source === 0 && !leadDashdata) {
            getLeadDashData();
        }

        if (source === 1 && !callerDashdata) {
            getCallerDashData();
        }
    }, [leadDashdata, callerDashdata, source]);

    return (
        <Container maxWidth="none" disableGutters>
            <GridContainer>
                <GridFlexBox>
                    <Title>Leads Dashboard</Title>
                </GridFlexBox>
                <FormControl variant="outlined" style={{ margin: '1rem 0' }}>
                    <InputLabel id="time-range-label">Time Range</InputLabel>
                    <Select
                        labelId="time-range-label"
                        value={timeRange}
                        onChange={(e) => setTimeRange(e.target.value)}
                        label="Time Range"
                    >
                        <MenuItem value="week">This Week</MenuItem>
                        <MenuItem value="month">This Month</MenuItem>
                        <MenuItem value="year">This Year</MenuItem>
                        <MenuItem value="allTime">All Time</MenuItem>
                    </Select>
                </FormControl>
                <GridDivider />
                <Loading loading={loading} />
                <GridFlexBox>
                    {/* This is the table to display the data */}
                    {calculatedLeadDashData && <LeadDashBoardTable leadDashdata={calculatedLeadDashData} callerDashdata={calculatedCallerDashData} setLoading={setLoading} source={source} setSource={setSource} />}
                </GridFlexBox>
            </GridContainer>
        </Container>
    );
}
