import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";
import convertDate from "./../../utils-functions/convertDate";

const getLeadsByPageId = async (
    pageId,
    user,
    loadAll = false,
    type = "SOURCE_LEADS",
    year,
    startDate,
    endDate,
    folder
) => {
    const collectionRef = collection(db, "leads");
    let q;

    // Get first day of year and last day of year
    if (year) {
        startDate = new Date(year, 0, 1);
        endDate = new Date(year, 11, 31, 23, 59, 59);
    }

    try {
        if (type === "SOURCE_LEADS") {
            if (folder) {
                let folderName = user.id + ":" + folder.id;
                console.log(folderName);
                q = query(
                    collectionRef,
                    where("folders", "array-contains", folderName),
                    orderBy("created", "desc")
                );
            } else if (pageId === "all") {
                if (loadAll) {
                    q = query(
                        collectionRef,
                        where("admins", "array-contains", user.id),
                        orderBy("created", "desc")
                    );
                } else {
                    q = query(
                        collectionRef,
                        where("admins", "array-contains", user.id),
                        where("created", ">=", startDate),
                        where("created", "<=", endDate),
                        orderBy("created", "desc")
                    );
                }
            } else {
                if (loadAll) {
                    q = query(
                        collectionRef,
                        where("page_id", "==", pageId),
                        where("admins", "array-contains", user.id),
                        orderBy("created", "desc")
                    );
                } else {
                    q = query(
                        collectionRef,
                        where("page_id", "==", pageId),
                        where("admins", "array-contains", user.id),
                        where("created", ">=", startDate),
                        where("created", "<=", endDate),
                        orderBy("created", "desc")
                    );
                }
            }

            // ASSIGNED LEADS
        } else {
            if (folder) {
                let folderName = user.id + ":" + folder.id;
                console.log(folderName);
                q = query(
                    collectionRef,
                    where("folders", "array-contains", folderName),
                    where("type", "==", "ASSIGNED_LEAD"),
                    orderBy("created", "desc")
                );
            } else if (pageId === "all") {
                if (loadAll) {
                    q = query(
                        collectionRef,
                        where("warriors", "array-contains", user.id),
                        orderBy("assignedDate", "desc")
                    );
                } else {
                    q = query(
                        collectionRef,
                        where("warriors", "array-contains", user.id),
                        where("assignedDate", ">=", startDate),
                        where("assignedDate", "<=", endDate),
                        orderBy("assignedDate", "desc")
                    );
                }
            } else {
                if (loadAll) {
                    q = query(
                        collectionRef,
                        where("page_id", "==", pageId),
                        where("warriors", "array-contains", user.id),
                        orderBy("assignedDate", "desc")
                    );
                } else {
                    q = query(
                        collectionRef,
                        where("page_id", "==", pageId),
                        where("warriors", "array-contains", user.id),
                        where("assignedDate", ">=", startDate),
                        where("assignedDate", "<=", endDate),
                        orderBy("assignedDate", "desc")
                    );
                }
            }
        }

        const snapshot = await getDocs(q);
        if (!snapshot.empty) {
            let leads = snapshot.docs.map((doc) => {
                let data = {};
                try {
                    if (doc.data().field_data) {
                        doc.data().field_data.forEach((item) => {
                            if (item.name?.includes("name") || item.name?.includes("全名"))
                                data = { ...data, name: item.values[0] };
                            else if (item.name?.includes("email") || item.name?.includes("邮箱"))
                                data = { ...data, email: item.values[0] };
                            else if (item.name?.includes("phone") || item.name?.includes("手机号"))
                                data = { ...data, phone: item.values[0] };
                            else {
                                data = { ...data, [item.name]: item.values[0] };
                            }
                        });
                    }
                    let assignBy = doc.data().assignments?.[0]
                        ? doc.data().assignments?.[0]?.assignBy?.split("@")?.[0]
                        : "";
                    let date = convertDate(doc.data().created);
                    if (doc.data().type === "ASSIGNED_LEAD") {
                        date.setSeconds(date.getSeconds() - 1);
                    } else {
                        assignBy = "";
                    }
                    return {
                        ...doc.data(),
                        ...data,
                        created: date,
                        assignBy,
                        id: doc.id,
                    };
                } catch (err) {
                    console.log(err);
                }
            });
            if (type === "SOURCE_LEADS") {
                leads = sortLeads(leads);
            } else {
                leads.sort((a, b) => b.assignedDate - a.assignedDate);
            }
            return leads;
        } else {
            return [];
        }
    } catch (err) {
        console.log(err.message);
        return [];
    }
};

export default getLeadsByPageId;

// Function to sort leads
function sortLeads(leads) {
    // Group leads by source lead
    const groupedLeads = leads.reduce((acc, lead) => {
        const [sourceLeadId] = lead.id.split(":");
        if (!acc[sourceLeadId]) {
            acc[sourceLeadId] = [];
        }
        acc[sourceLeadId].push(lead);
        return acc;
    }, {});

    // Sort each group by created date and flatten the groups
    return Object.values(groupedLeads)
        .map((group) => group.sort((a, b) => new Date(b.created) - new Date(a.created)))
        .sort((a, b) => new Date(b[0].created) - new Date(a[0].created))
        .flat();
}
