import { alpha, styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const StyledCalendar = styled("div")(({ theme }) => ({
    width: "calc(100% + 2px)",
    marginLeft: -1,
    marginBottom: -1,

    "& .fc": {
        "--fc-border-color": alpha(theme.palette.grey[500], 0.16),
        "--fc-now-indicator-color": theme.palette.error.main,
        "--fc-today-bg-color": alpha(theme.palette.grey[500], 0.18),
        "--fc-page-bg-color": theme.palette.background.default,
        "--fc-neutral-bg-color": theme.palette.background.neutral,
        "--fc-list-event-hover-bg-color": theme.palette.action.hover,
        "--fc-highlight-color": theme.palette.action.hover,
    },

    "& .fc .fc-license-message": { display: "none" },
    "& .fc a": { color: theme.palette.text.primary },

    // Table Head
    // Table Head
    "& .fc .fc-col-header": {
        boxShadow: `inset 0 -1px 0 ${theme.palette.divider}`,
        "& th": {
            borderColor: "transparent",
            backgroundColor:
                theme.palette.mode === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
            color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.text.primary,
        },
        "& .fc-col-header-cell-cushion": {
            ...theme.typography.subtitle2,
            padding: "13px 0",
        },
    },

    "& .fc .fc-list-day .fc-list-day-cushion": {
        backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : theme.palette.grey[200],
        // Ensure that the text color is set appropriately for visibility
        color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.text.primary,
    },

    "& .fc .fc-daygrid-day-number, & .fc .fc-daygrid-day-top": {
        color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "inherit", // Assuming grey[700] is visible against your dark background
    },

    // List Empty
    "& .fc .fc-list-empty": {
        ...theme.typography.h6,
        backgroundColor: "transparent",
        color: theme.palette.text.secondary,
    },

    "& .fc .fc-event .fc-event-main-frame": {
        fontSize: 13,
        lineHeight: "20px",
        filter: "brightness(0.48)",
    },
    "& .fc .fc-daygrid-event .fc-event-title": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    "& .fc .fc-event .fc-event-time": {
        overflow: "unset",
        fontWeight: theme.typography.fontWeightBold,
    },

    "& .fc-daygrid-event .fc-event-main": {
        color: "#fff !important", // Set text color to white
    },

    // Popover
    "& .fc .fc-popover": {
        border: 0,
        overflow: "hidden",
        // boxShadow: theme.customShadows.dropdown,
        borderRadius: theme.shape.borderRadius * 1.5,
        backgroundColor: theme.palette.background.paper,
    },
    "& .fc .fc-popover-header": {
        ...theme.typography.subtitle2,
        padding: theme.spacing(1),
        backgroundColor: alpha(theme.palette.grey[500], 0.08),
    },
    "& .fc .fc-popover-close": {
        opacity: 0.48,
        transition: theme.transitions.create(["opacity"]),
        "&:hover": { opacity: 1 },
    },
    "& .fc .fc-more-popover .fc-popover-body": {
        padding: theme.spacing(1),
    },
    "& .fc .fc-popover-body": {
        "& .fc-daygrid-event.fc-event-start, & .fc-daygrid-event.fc-event-end": {
            margin: "2px 0",
        },
    },

    // Month View
    "& .fc .fc-day-other .fc-daygrid-day-top": {
        opacity: 1,
        "& .fc-daygrid-day-number": {
            color: theme.palette.text.disabled,
        },
    },
    "& .fc .fc-daygrid-day-number": {
        ...theme.typography.body2,
        padding: theme.spacing(1, 1, 0),
    },
    "& .fc .fc-daygrid-event": {
        marginTop: 4,
    },
    "& .fc .fc-daygrid-event.fc-event-start, & .fc .fc-daygrid-event.fc-event-end": {
        marginLeft: 4,
        marginRight: 4,
    },
    "& .fc .fc-daygrid-more-link": {
        ...theme.typography.caption,
        color: theme.palette.text.secondary,
        "&:hover": {
            backgroundColor: "unset",
            textDecoration: "underline",
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,
        },
    },

    // Week & Day View
    "& .fc .fc-timegrid-axis-cushion": {
        ...theme.typography.body2,
        // color: theme.palette.text.secondary,
        color: theme.palette.mode === "dark" ? theme.palette.grey[500] : theme.palette.text.primary,
    },
    "& .fc .fc-timegrid-slot-label-cushion": {
        ...theme.typography.body2,
        color: theme.palette.mode === "dark" ? theme.palette.grey[500] : theme.palette.text.primary,
    },

    // Agenda View
    "& .fc-direction-ltr .fc-list-day-text, .fc-direction-rtl .fc-list-day-side-text, .fc-direction-ltr .fc-list-day-side-text, .fc-direction-rtl .fc-list-day-text":
        {
            ...theme.typography.subtitle2,
        },
    "& .fc .fc-list-event": {
        ...theme.typography.body2,
        "& .fc-list-event-time": {
            color: "#000",
        },
    },

    // Added by DaveCKW
    "& .fc .fc-scrollgrid-section-body table, .fc .fc-scrollgrid-section-footer table": {
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.background.paper,
    },

    ".fc .fc-scroller-liquid": {
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.background.paper,
    },

    ".fc .fc-scroller": {
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.background.paper,
    },

    ".fc.fc-theme-standard .fc-view-harness td": {
        color: theme.palette.mode === "dark" ? theme.palette.grey[200] : theme.palette.background.primary,
    },

    // "& .fc .fc-col-header-cell-cushion": {
    //     color: "#000", // Set the text color to black for all days in the header
    // },

    // "& .fc .fc-timegrid-event .fc-event-title": {
    //     color: theme.palette.text.primary, // Set the text color for the event title
    // },

    // "& .fc .fc-timegrid-event .fc-event-time": {
    //     color: theme.palette.text.primary, // Set the text color for the event time
    // },
    // "& .fc .fc-event .fc-event-title": {
    //     color: "#fff", // Set text color to white
    // },
}));
