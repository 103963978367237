import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function saveChatHistory(response, chatGptHistoryId, user, model) {
    try {
        if (chatGptHistoryId) {
            const docRef = doc(db, "users", user.id, "chatGptHistoryIds", chatGptHistoryId.id);
            const data = {
                date: new Date(),
                userId: user.id,
                name: chatGptHistoryId.name,
                id: chatGptHistoryId.id,
                model
            };
            setDoc(docRef, data, { merge: true });
            const docRef2 = doc(db, "users", user.id, "chatGptHistory", chatGptHistoryId.id);
            setDoc(docRef2, { messages: response }, { merge: true });
            console.log("Saved chat history: ", chatGptHistoryId.name);
            return data;
        } else {
            const collectionRef = collection(db, "users", user.id, "chatGptHistoryIds");
            const name = response[response.length - 2].content;
            const data = {
                date: new Date(),
                userId: user.id,
                name: name,
                model
            };
            const r = await addDoc(collectionRef, data);
            const docRef2 = doc(db, "users", user.id, "chatGptHistory", r.id);
            setDoc(docRef2, { messages: response }, { merge: true });
            console.log("Saved chat history: ");
            return { ...data, id: r.id };
        }
    } catch (error) {
        console.log(error);
        return null;
    }
}
