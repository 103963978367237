import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-utils";

export default async function updateField({ docId, collectionId, field, value }) {    
    try {        
        const docRef = doc(db, collectionId, docId);

        //added by wayne 1 nov 2023
        //to convert value to number datatype format for the field "phone"
        if (field === 'phone') {
            value = Number(value);
        }
        /////////////////////////////////////////////////////////
        await updateDoc(docRef, { [field]: value });
        console.log("Document successfully updated!");
    } catch (error) {
        console.log(error);
    }
}
