export default function getAdminList(leads) {
    //added by wayne 8-11-2023
    //this function is to get a list of admins from leads.admins
    if (leads) {
        let uniqueAdminsSet = new Set();

        // Initialize an empty array to store unique admin objects
        let uniqueAdminsArray = [];
        leads.forEach((lead) => {
            lead.admins?.forEach((admin) => {
                // Check if the admin's value is not already in the Set
                if (!uniqueAdminsSet.has(admin)) {
                    // If not, add the admin's value to the Set
                    uniqueAdminsSet.add(admin);
                    // Push the entire admin object to the uniqueAdminsArray
                    uniqueAdminsArray.push({ email: admin });
                }
            });
        });
        return uniqueAdminsArray;
    } else {
        return null;
    }
}
