// Chat.js
import { Box, Button, Container, Divider, Grid, IconButton, List, ListItem, TextField } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { GridContainer, GridDivider, GridFlexBox, Name } from "../../themes/themes";
import SignalWifiStatusbar4BarIcon from "@mui/icons-material/SignalWifiStatusbar4Bar";
import { green } from "@mui/material/colors";
import { notification } from "../../utils-functions/notification";
import { AuthContext } from "../../context/AuthContext";

const WhatsappWebPage = () => {
    const [messages, setMessages] = useState([]);
    const [session, setSession] = useState("");
    const [clientId, setClientId] = useState("");
    const [input, setInput] = useState("");
    const [toNumber, setToNumber] = useState("");
    const [toName, setToName] = useState("");
    const [myName, setMyName] = useState("Dave Chong");
    const [qr, setQr] = useState("");
    const socketRef = useRef(null);
    const messagesEndRef = useRef(null);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        socketRef.current = io("http://34.142.171.163:8080/"); // Change to your server's port
        // http://34.143.252.175/ // Google Kubernetes Engine
        // http://localhost:8080

        const socket = socketRef.current;

        socket.auth = { username: user.id };
        socket.connect();

        socket.on("chat message", (msg) => {
            console.log(msg);
            setMessages((prevMessages) => [...prevMessages, msg]);
            scrollToBottom();
        });

        socket.on("session", (session) => {
            const { clientId, username } = session;
            if (username === user.id) {
                setSession(clientId);
                setQr("");
            }
        });

        socket.on("qr", (qr) => {
            setQr(qr);
        });

        socket.on("clientId", (cliendId) => {
            console.log("Connecting to whatsapp  . . . ", clientId);
        });

        socket.on("message", (msg) => {
            if (msg.media) {
                const imgSrc = `data:${msg.media.mimetype};base64,${msg.media.data}`;
                setMessages((prevMessages) => [...prevMessages, { ...msg, imgSrc }]);
            } else {
                setMessages((prevMessages) => [...prevMessages, msg]);
            }
        });

        socket.on("connection", (msg) => {
            console.log(msg);
        });

        return () => {
            socketRef.current.disconnect();
        };
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (input && toNumber) {
            const msg = {
                to: toNumber,
                name: myName,
                body: input
            };
            console.log(msg);
            socketRef.current.emit("chat message", msg);
            setInput("");
        }
        if (!toNumber) {
            notification("Opps", "Please choose a number to send to.", "warning");
        }
        if (!input) {
            notification("Opps", "Cannot send empty text", "warning");
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current.scrollTo({
            top: messagesEndRef.current.scrollHeight,
            behavior: "smooth"
        });
    };

    const onChange = (e) => {
        const { value } = e.target;
        setClientId(value);
    };

    const handleSubmitId = () => {
        if (clientId) {
            const confirm = window.confirm("Client Id: " + clientId + "\nConfirm?");
            if (confirm) socketRef.current.emit("clientId", clientId);
        }
    };

    const handleSetToNumber = (msg) => {
        console.log(msg);
        let { from, name, to } = msg;
        setToNumber(from || to);
        setToName(name);
    };

    const handleChangeTo = (e) => {
        const { value } = e.target;
        setToNumber(value);
        setToName("");
    };

    return (
        <Container maxWidth="sm">
            <Grid container display={"flex"} flexDirection={"column"}>
                <Grid item display={"flex"} width={"100%"}>
                    <GridContainer>
                        {qr && (
                            <GridFlexBox>
                                <img src={qr} />
                            </GridFlexBox>
                        )}
                        <GridFlexBox fs>
                            <Name>Session: {session}</Name>
                            {session && (
                                <SignalWifiStatusbar4BarIcon
                                    sx={{ color: green[500], marginLeft: "10px", marginRight: "10px" }}
                                />
                            )}
                            <TextField size="small" label="To number" onChange={handleChangeTo} />
                        </GridFlexBox>

                        {!session && (
                            <>
                                <GridFlexBox w="90%">
                                    <TextField
                                        value={clientId}
                                        name="clientId"
                                        onChange={onChange}
                                        variant="outlined"
                                        size="small"
                                        label="Client Id"
                                        autoComplete="off"
                                        fullWidth
                                        sx={{ paddingRight: "10px" }}
                                    />
                                </GridFlexBox>
                                <GridFlexBox w="10%">
                                    <Button color="primary" variant="contained" onClick={handleSubmitId}>
                                        Submit
                                    </Button>
                                </GridFlexBox>
                            </>
                        )}
                        <GridDivider />
                    </GridContainer>
                </Grid>
                <Grid item display="flex">
                    <GridContainer>
                        <GridFlexBox fs>
                            <List
                                ref={messagesEndRef}
                                sx={{ height: "70vh", overflowY: "scroll", border: "1px grey solid", width: "100%" }}
                            >
                                {messages.map((msg, index) => (
                                    <ListItem key={index} sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                        <Box width={"100%"}>
                                            <Box display="flex">
                                                <Name>{msg.name}</Name>
                                                <IconButton
                                                    size="small"
                                                    sx={{ padding: 0 }}
                                                    onClick={() => handleSetToNumber(msg)}
                                                >
                                                    <Name pl1>{msg.from || msg.to}</Name>
                                                </IconButton>
                                            </Box>

                                            <Name whiteSpace="pre-wrap" wordBreak="break-all">
                                                {msg.body}
                                            </Name>
                                            {msg.imgSrc && <img src={msg.imgSrc} alt="img" width={"200px"} />}
                                            <Divider />
                                        </Box>
                                    </ListItem>
                                ))}
                            </List>
                        </GridFlexBox>
                    </GridContainer>
                </Grid>
                <Grid item display={"flex"}>
                    <GridContainer>
                        <form onSubmit={handleSubmit} style={{ width: "100%", marginTop: "10px" }}>
                            <GridContainer>
                                <GridFlexBox fs>
                                    <Name>To: </Name>
                                    <Name pl1>{toName}</Name>
                                    <Name pl1>{toNumber}</Name>
                                </GridFlexBox>
                                <GridFlexBox fs w="85%">
                                    <TextField
                                        value={input}
                                        onChange={(e) => setInput(e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        autoComplete="off"
                                        fullWidth
                                        sx={{ paddingRight: "10px" }}
                                    />
                                </GridFlexBox>
                                <GridFlexBox w="15%">
                                    <Button type="submit" color="primary" variant="contained">
                                        Send
                                    </Button>
                                </GridFlexBox>
                            </GridContainer>
                        </form>
                    </GridContainer>
                </Grid>
            </Grid>
        </Container>
    );
};

export default WhatsappWebPage;
